import React, { useRef, useState } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/theme35banner.png";
// import logoImage from "../../../../Images/theme-17-logo.png";
// import testimonialImage from "../../../../Images/avatar.png";
// import FacebookIcon from "../../../../Images/theme-17-social-1.png";
// import InstagramIcon from "../../../../Images/theme-17-social-2.png";
// import TweeterIcon from "../../../../Images/theme-17-social-3.png";
// import LinedinIcon from "../../../../Images/theme-17-social-4.png";
// import WhatsappIcon from "../../../../Images/whatsappIcon.png";
import CalanderIconn from "../../../../Images/calendarIcondays-new.png";

// import quoteup from "../../../../Images/theme17-up-quote.png";
// import quotedown from "../../../../Images/theme17-down-quote.png";

import arrow from "../../../../Images/theme-17-arrow.png";

import "./Theme35.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
const Theme35Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "Great experience overall having Barr & Douds as my attorney. Matt understands his client, very insightful and is an expert in his field. Truly a professional and marvelous individual",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "Great experience overall having Barr & Douds as my attorney. ",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "Matt understands his client, very insightful and is an expert in his field. Truly a professional and marvelous individual",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 60,
      business_id: 10,
      image: "1701329881279Rectangle6.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:01.000Z",
      updated_at: "2023-11-30T07:38:01.000Z",
    },
    {
      id: 61,
      business_id: 10,
      image: "1701329883848Rectangle7.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:03.000Z",
      updated_at: "2023-11-30T07:38:03.000Z",
    },
    {
      id: 62,
      business_id: 10,
      image: "1701329887518Rectangle597.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:07.000Z",
      updated_at: "2023-11-30T07:38:07.000Z",
    },
    {
      id: 63,
      business_id: 10,
      image: "1701329891477Rectangle598.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:11.000Z",
      updated_at: "2023-11-30T07:38:11.000Z",
    },
    {
      id: 64,
      business_id: 10,
      image: "1701329899378Rectangle599.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:19.000Z",
      updated_at: "2023-11-30T07:38:19.000Z",
    },
    {
      id: 65,
      business_id: 10,
      image: "1701329902425Rectangle600.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:22.000Z",
      updated_at: "2023-11-30T07:38:22.000Z",
    },
  ];

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp35">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
              <article className="imgsechere sec">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                  style={{ width: "100%" }}
                />
                <div className="imageOverlay"></div>
              </article>

              <section className="mainsection">
                <div className="chevron">
                  {" "}
                  <i class="fa-solid fa-chevron-up"></i>
                </div>
                <article className="personalinfo">
                  {/* <img src={logoImage} className="mx-auto d-block img-fluid logo-profile" /> */}
                  <h3 className="username">
                    Vasken <br /> Mouraden
                  </h3>
                  <p className="titleuser">Journalist</p>
                </article>

                <article className="social-links-sec">
                  <h3 class="sectitle">Social Media</h3>
                  <ul class="socil-link socil-link-inner">
                    <li>
                      <a>
                        <i class={`fa-brands fa-facebook`}></i>
                        {/* <img src={FacebookIcon} /> */}
                      </a>
                    </li>
                    <li>
                      <a>
                        <i class={`fa-brands fa-google`}></i>
                        {/* <img src={InstagramIcon} /> */}
                      </a>
                    </li>

                    <li>
                      <a>
                        <i class={`fa-brands fa-twitter`}></i>
                        {/* <img src={TweeterIcon} /> */}
                      </a>
                    </li>
                    <li>
                      <a>
                        <i class={`fa-brands fa-whatsapp`}></i>
                        {/* <img src={WhatsappIcon} /> */}
                      </a>
                    </li>
                    <li>
                      <a>
                        <i class={`fa-brands fa-linkedin`}></i>
                        {/* <img src={LinedinIcon} /> */}
                      </a>
                    </li>
                  </ul>
                </article>
                <article className="abouttemplate sec">
                  <h3 class="sectitle">About Us</h3>
                  <p className="para">
                    {`Vasken Mouradian is an award-winning journalist with over a decade of experience in investigative reporting. Known for her deep dive into complex issues, she has uncovered stories on corruption, human rights violations, and environmental concerns across the globe. Jane’s relentless pursuit of the truth has earned her accolades from prestigious media organizations and recognition for her unwavering commitment to journalistic integrity.`}
                  </p>
                </article>

                <article className="servicesName sec">
                  <h3 class="sectitle">Services </h3>
                  <div className="mainservicebox">
                    <ul className="servesbox">
                      <li>Investigative Reporting</li>
                      <li>Documentary Filmmaking</li>
                      <li>News Reporting</li>
                      <li>Editorial and Opinion Writing</li>
                      <li>Data Journalism</li>
                      <li>Feature Writing</li>
                      {/* <li>Public Relations</li> */}
                    </ul>
                  </div>
                </article>

                <article class="gallery sec sec-common">
                  <h3 class="sectitle">Gallery </h3>
                  <GalleryCarousel slides={galleryArray} />
                </article>

                <article className="businessHours sec">
                  <h3 class="sectitle">
                    Working Hours{" "}
                    <i class="fa-solid fa-chevron-right rightcorner"></i>
                  </h3>
                  <p>
                    Mon to Sat <span>9AM to 9PM</span>
                    <br />
                    Sunday Closed!
                  </p>

                  {/* <ul class="timelist">
                  <li>
                    <div className="day">Monday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul> */}
                </article>

                <article class="contact sec">
                  <h3 class="sectitle">Contact Info</h3>
                  <ul class="conlink">
                    <li>
                      <a href="">
                        <i class="fa-solid fa-phone"></i>
                        <span>{`8010666660`}</span>
                      </a>
                    </li>

                    <li>
                      <a href="">
                        <i class="fa-solid fa-envelope"></i>
                        <span>{` apsinghadv8560@gmail.com`}</span>
                      </a>
                    </li>

                    <li>
                      <a href="">
                        <i class="fa-solid fa-location-dot"></i>
                        <span>{`First floor, A-250, A Block, Pocket A, Sector 19, Noida, Uttar Pradesh 201301, India`}</span>
                      </a>
                    </li>
                  </ul>
                </article>

                <article className="conatctform sec">
                  <h3 class="sectitle">Contact Form</h3>
                  <div class="contact-form">
                    <form>
                      <div class="col-12 mb-3">
                        {/* <label> Name*</label> */}
                        <input
                          type="text"
                          class="form-control clear_string"
                          placeholder="Full Name"
                          name="last_name"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">
                        {/* <label> Phone*</label> */}
                        <input
                          type="text"
                          class="form-control"
                          placeholder="abc@gmail.com"
                          name="email"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">
                        {/* <label> email*</label> */}
                        <input
                          type="text"
                          class="form-control clear_string"
                          maxlength="10"
                          minlength="10"
                          placeholder="+91 (000) 000-0000 "
                          name="mobile_number"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">
                        {/* <label> Case Description* </label> */}
                        <textarea
                          class="form-control clear_string"
                          rows="3"
                          placeholder="Your message goes here..."
                          required=""
                          name="message"
                        ></textarea>
                      </div>

                      <div class="col-12 mb-3">
                        <button type="submit" class="btn btn-submitform">
                          Submit
                          {/* <img src={arrow} alt='arrow' /> */}
                        </button>
                      </div>
                    </form>
                  </div>
                </article>

                <article class="testimoniyalbox sec">
                  <div className="testimonial-inner">
                    <h3 class="sectitle testimonial">Testimonial </h3>

                    <div>
                      <TestimonialCarousel
                        testimonials={testimonialArray}
                        currentElem={currentIndex}
                      />
                    </div>
                  </div>
                </article>

                {/* <article class="testimoniyalbox sec">
                <img className="quoteup" src={quoteup} alt='quote' />
                <img className="quotedown" src={quotedown} alt='quote' />
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article> */}

                <article className="makeappitmneets sec">
                  <h3 class="sectitle">Make Appointment</h3>
                  <div className="contact-form">
                    <div className="row">
                      <div class="col-6 mb-3" style={{ position: "relative" }}>
                        <label> Select Date </label>
                        <div style={{ position: "relative" }}>
                          <input
                            ref={calendarInputRef}
                            type="date"
                            class="form-control clear_string"
                            placeholder="Date"
                            name="currentDate"
                            // value={selectedDate}
                            // onChange={(e) => setSelectedDate(e.target.value)}
                          />
                          <div className="calanderIcon" onClick={openCalendar}>
                            <img src={CalanderIconn} alt="Calendar Icon" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6 mb-3">
                        <label> Select Time </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          // onChange={(e) => setSelectedTime(e.target.value)}
                        >
                          <option value="Select time">Select time</option>
                          <option>{`2:00 PM to 5:00 PM`}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <button
                        className="btn btnmakeappitmnets"
                        data-bs-toggle="modal"
                        data-bs-target="#appotimnets"
                      >
                        Create Appointment <img src={arrow} alt="arrow" />
                      </button>
                    </div>
                  </div>
                </article>

                {/* <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3">
                    <input
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme35Template;
