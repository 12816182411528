import React, { useContext, useEffect, useRef, useState } from "react";
import { businessObjContext } from "../../../context/businessObjContext";
import { isThursday } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
function NewBusinessHour({
  setBusinessHourObject,
  appointmentSlotDuration,
  setAppointmentSlotDuration,
  setAppointmentArray,
}) {
  const [isMonday, setIsMonday] = useState(false);
  const [modayStartDate, setModayStartDate] = useState("");
  const [modayEndDate, setModayEndDate] = useState("");
  const [mondaySlots, setMondaySlots] = useState([]);
  console.log(mondaySlots,"ed mondaySlots");


  const [isTuesday, setIsTuesday] = useState(false);
  const [tuesdayStartDate, setTuesdayStartDate] = useState("");
  const [tuesdayEndDate, setTuesdayEndDate] = useState("");
  const [tuesdaySlots, setTuesdaySlots] = useState([]);

  const [isWednesDay, setIsWednesDay] = useState(false);
  const [wednesdayStartDate, setWednesdayStartDate] = useState("");
  const [wednesdayEndDate, setWednesdayEndDate] = useState("");
  const [wednesdaySlots, setWednesdaySlots] = useState([]);

  const [isthrusdayDay, setIsthrusdayDay] = useState(false);
  const [thrusdayStartDate, setThrusdayStartDate] = useState("");
  const [thrusdayEndDate, setThrusdayEndDate] = useState("");
  const [thursdaySlots, setThursdaySlots] = useState([]);

  const [isfridayDay, setIsfridayDay] = useState(false);
  const [fridayStartDate, setFridayStartDate] = useState("");
  const [fridayEndDate, setFridayEndDate] = useState("");
  const [fridaySlots, setFridaySlots] = useState([]);

  const [issaturdayDay, setIsSaturdayDay] = useState(false);
  const [SaturdayStartDate, setSaturdayStartDate] = useState("");
  const [SaturdayEndDate, setSaturdayEndDate] = useState("");
  const [saturdaySlots, setSaturdaySlots] = useState([]);

  const [issundayDay, setIsSundayDay] = useState(false);
  const [SundayStartDate, setSundayStartDate] = useState("");
  const [SundayEndDate, setSundayEndDate] = useState("");
  const [sundaySlots, setSundaySlots] = useState([]);

  const [localData, setLocalData] = useState({});
  const inputStartTimeRef = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const inputEndTimeRef = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const handleStartInputRef = (index) => {
    inputStartTimeRef[index].current.showPicker();
  };
  const handleEndInputRef = (index) => {
    inputEndTimeRef[index].current.showPicker();
  };
  const { businessObjData } = useContext(businessObjContext);
  useEffect(() => {
    setAppointmentArray({
      monday: mondaySlots,
      tuesday: tuesdaySlots,
      wednesday: wednesdaySlots,
      thursday: thursdaySlots,
      friday: fridaySlots,
      saturday: saturdaySlots,
      sunday: sundaySlots,
    });
    return () => {
      setAppointmentArray([]);
    };
  }, [
    mondaySlots,
    tuesdaySlots,
    wednesdaySlots,
    thursdaySlots,
    fridaySlots,
    saturdaySlots,
    sundaySlots,
  ]);
  // console.log(businessObjData, "business hour object");
  function removeForm(day, formKey) {
    if (day == "monday") {
      const newAppointments = mondaySlots.filter(
        (val, index) => index !== formKey
      );
      setMondaySlots([...newAppointments]);
    } else if (day == "tuesday") {
      const newAppointments = tuesdaySlots.filter(
        (val, index) => index !== formKey
      );
      setTuesdaySlots([...newAppointments]);
    } else if (day == "wednesday") {
      const newAppointments = wednesdaySlots.filter(
        (val, index) => index !== formKey
      );
      setWednesdaySlots([...newAppointments]);
    } else if (day == "thursday") {
      const newAppointments = thursdaySlots.filter(
        (val, index) => index !== formKey
      );
      setThursdaySlots([...newAppointments]);
    } else if (day == "friday") {
      const newAppointments = fridaySlots.filter(
        (val, index) => index !== formKey
      );
      setFridaySlots([...newAppointments]);
    } else if (day == "saturday") {
      const newAppointments = saturdaySlots.filter(
        (val, index) => index !== formKey
      );
      setSaturdaySlots([...newAppointments]);
    } else if (day == "sunday") {
      const newAppointments = sundaySlots.filter(
        (val, index) => index !== formKey
      );
      setSundaySlots([...newAppointments]);
    }
  }

  function generateTimeSlots(startTime, endTime, slotDuration) {
    if (
      startTime == null ||
      endTime == null ||
      slotDuration == null ||
      startTime == undefined ||
      endTime == undefined ||
      startTime == "" ||
      endTime == "" ||
      slotDuration == ""
    ) {
      return; // Return an empty array or handle the error as needed
    }

    const slots = [];

    // Convert input strings to Date objects
    const startDate = new Date(`2000-01-01T${startTime}`);
    const endDate = new Date(`2000-01-01T${endTime}`);

    // Calculate slot duration in milliseconds
    const slotDurationMs = slotDuration * 60 * 1000;

    // Check if slot duration is greater than the time range
    if (endDate.getTime() - startDate.getTime() < slotDurationMs) {
      toast.error("The appointment duration must be at least 30 min", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.error(
        "Error: Slot duration is greater than the specified time range."
      );
      return slots; // Return an empty array or handle the error as needed
    }

    // Generate time slots
    let currentSlotStart = startDate.getTime();
    let formCount = 0;
    while (currentSlotStart + slotDurationMs <= endDate.getTime()) {
      const currentSlotEnd = currentSlotStart + slotDurationMs;

      // Format the slot start and end times
      const formattedStart = new Date(currentSlotStart).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const formattedEnd = new Date(currentSlotEnd).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      // Add the slot to the array
      slots.push({
        formCount: formCount,
        startDate: formattedStart,
        endDate: formattedEnd,
      });

      // Move to the next slot
      formCount++;
      currentSlotStart += slotDurationMs;
    }
    return slots;
  }

  const handleSelectSlotDuration = (day, duration, start, end) => {
    handleTimeChange(day, duration);
    
    if (day == "monday") {
      const slots = generateTimeSlots(start, end, duration);
      setMondaySlots(slots);
      return;
    } else if (day == "tuesday") {
      // console.log(day, duration, start, end, "tuesday!!!!");
      const slots = generateTimeSlots(start, end, duration);
      setTuesdaySlots(slots);
      return;
    } else if (day == "wednesday") {
      const slots = generateTimeSlots(start, end, duration);
      setWednesdaySlots(slots);
      return;
    } else if (day == "thursday") {
      // console.log(start, end, duration, "thur");
      const slots = generateTimeSlots(start, end, duration);
      setThursdaySlots(slots);
      return;
    } else if (day == "friday") {
      const slots = generateTimeSlots(start, end, duration);
      setFridaySlots(slots);
      return;
    } else if (day == "saturday") {
      const slots = generateTimeSlots(start, end, duration);
      setSaturdaySlots(slots);
      return;
    } else if (day == "sunday") {
      const slots = generateTimeSlots(start, end, duration);
      setSundaySlots(slots);
      return;
    }
    return;
  };

  const handleTimeChange = (day, value) => {
    setAppointmentSlotDuration((prevDuration) => ({
      ...prevDuration,
      [day]: value,
    }));
  };

  const handleResetData = () => {
    try {
      setIsMonday(false);
      setModayStartDate("");
      setModayEndDate("");

      setIsTuesday(false);
      setTuesdayStartDate("");
      setTuesdayEndDate("");

      setIsWednesDay(false);
      setWednesdayStartDate("");
      setWednesdayEndDate("");

      setIsthrusdayDay(false);
      setThrusdayStartDate("");
      setThrusdayEndDate("");

      setIsfridayDay(false);
      setFridayStartDate("");
      setFridayEndDate("");

      setIsSaturdayDay(false);
      setSaturdayStartDate("");
      setSaturdayEndDate("");

      setIsSundayDay(false);
      setSundayStartDate("");
      setSundayEndDate("");
    } catch (err) {
      console.log(err, "errin  busines hr");
    }
  };
  // console.log(businessObjData, "businessobjdatainnewbusinesshour");
  useEffect(() => {
    let getLocalStorageData;
    getLocalStorageData = businessObjData;
    let getUrlData = window.location.href;
    let urlArray = getUrlData?.split('=');
    let cBID = (urlArray.length>0)?urlArray[1]:0;
    
    if (getLocalStorageData) {
      try {
        if(getLocalStorageData?.appointmentObj != null){
          const parsedContent = JSON.parse(
            JSON.parse(getLocalStorageData?.appointmentObj?.content)
          );
          setMondaySlots(parsedContent?.monday);
          setTuesdaySlots(parsedContent?.tuesday);
          setWednesdaySlots(parsedContent?.wednesday);
          setThursdaySlots(parsedContent?.thursday);
          setFridaySlots(parsedContent?.friday);
          setSaturdaySlots(parsedContent?.saturday);
          setSundaySlots(parsedContent?.sunday);
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (getLocalStorageData && getLocalStorageData?.businessHourObj) {
      getLocalStorageData = getLocalStorageData?.businessHourObj?.content;
    } else {
      // handleResetData();
      return;
    }
    if (getLocalStorageData.length) {
      getLocalStorageData = JSON.parse(getLocalStorageData);
    }
    // console.log(getLocalStorageData,"getLo22 businessObj")
    if (getLocalStorageData && cBID == businessObjData?.id) {
      
      setIsMonday(getLocalStorageData?.isMonday);
      setModayStartDate(getLocalStorageData?.modayStartDate || "10:00");
      setModayEndDate(getLocalStorageData?.modayEndDate || "18:00");
      handleTimeChange("monday", getLocalStorageData?.slotDurationMonday || "45");

      setIsTuesday(getLocalStorageData?.isTuesday);
      setTuesdayStartDate(getLocalStorageData?.tuesdayStartDate || "10:00");
      setTuesdayEndDate(getLocalStorageData?.tuesdayEndDate || "18:00");
      handleTimeChange("tuesday", getLocalStorageData?.slotDurationTuesday || "45");

      setIsWednesDay(getLocalStorageData?.isWednesDay);
      setWednesdayStartDate(getLocalStorageData?.wednesdayStartDate || "10:00");
      setWednesdayEndDate(getLocalStorageData?.wednesdayEndDate || "18:00");
      handleTimeChange("wednesday", getLocalStorageData?.slotDurationWednesday || "45");

      setIsthrusdayDay(getLocalStorageData?.isthrusdayDay);
      setThrusdayStartDate(getLocalStorageData?.thrusdayStartDate || "10:00");
      setThrusdayEndDate(getLocalStorageData?.thrusdayEndDate || "18:00");
      handleTimeChange("thursday", getLocalStorageData?.slotDurationThursday || "45");

      setIsfridayDay(getLocalStorageData?.isfridayDay);
      setFridayStartDate(getLocalStorageData?.fridayStartDate || "10:00");
      setFridayEndDate(getLocalStorageData?.fridayEndDate || "18:00");
      handleTimeChange("friday", getLocalStorageData?.slotDurationFriday || "45");

      setIsSaturdayDay(getLocalStorageData?.issaturdayDay);
      setSaturdayStartDate(getLocalStorageData?.SaturdayStartDate || "10:00");
      setSaturdayEndDate(getLocalStorageData?.SaturdayEndDate || "18:00");
      handleTimeChange("saturday", getLocalStorageData?.slotDurationSaturday);

      setIsSundayDay(getLocalStorageData?.issundayDay);
      setSundayStartDate(getLocalStorageData?.SundayStartDate || "10:00");
      setSundayEndDate(getLocalStorageData?.SundayEndDate || "18:00");
      handleTimeChange("sunday", getLocalStorageData?.slotDurationSunday);
    } else {
      // handleResetData();
    }
    // return () => {
    //   setMondaySlots([]);
    //   setTuesdaySlots([]);
    //   setWednesdaySlots([]);
    //   setThursdaySlots([]);
    //   setFridaySlots([]);
    //   setSaturdaySlots([]);
    //   setSundaySlots([]);
    // };
  }, [businessObjData]);

  useEffect(() => {

    if (isMonday === false) {
      setMondaySlots([]);
    }
    if (isTuesday == false) {
      setTuesdaySlots([]);
    }
    if (isWednesDay == false) {
      setWednesdaySlots([]);
    }
    if (isthrusdayDay == false) {
      setThursdaySlots([]);
    }
    if (isfridayDay == false) {
      setFridaySlots([]);
    }
    if (issaturdayDay == false) {
      setSaturdaySlots([]);
    }
    if (issundayDay === false) {
      setSundaySlots([]);
    }
    let obj = {
      isMonday,
      modayStartDate,
      modayEndDate,
      slotDurationMonday: appointmentSlotDuration?.monday,

      isTuesday,
      tuesdayStartDate,
      tuesdayEndDate,
      slotDurationTuesday: appointmentSlotDuration?.tuesday,

      isWednesDay,
      wednesdayStartDate,
      wednesdayEndDate,
      slotDurationWednesday: appointmentSlotDuration?.wednesday,

      isthrusdayDay,
      thrusdayStartDate,
      thrusdayEndDate,
      slotDurationThursday: appointmentSlotDuration?.thursday,

      isfridayDay,
      fridayStartDate,
      fridayEndDate,
      slotDurationFriday: appointmentSlotDuration?.friday,

      issaturdayDay,
      SaturdayStartDate,
      SaturdayEndDate,
      slotDurationSaturday: appointmentSlotDuration?.saturday,

      issundayDay,
      SundayStartDate,
      SundayEndDate,
      slotDurationSunday: appointmentSlotDuration?.sunday,
    };
    setBusinessHourObject(obj);

    return () => {
      setBusinessHourObject({});
      // setAppointmentSlotDuration({
      //   monday: "",
      //   tuesday: "",
      //   wednesday: "",
      //   thursday: "",
      //   friday: "",
      //   saturday: "",
      //   sunday: "",
      // });
    };
  }, [
    isMonday,
    modayStartDate,
    modayEndDate,

    isTuesday,
    tuesdayStartDate,
    tuesdayEndDate,

    isWednesDay,
    wednesdayStartDate,
    wednesdayEndDate,

    isthrusdayDay,
    thrusdayStartDate,
    thrusdayEndDate,

    isfridayDay,
    fridayStartDate,
    fridayEndDate,

    issaturdayDay,
    SaturdayStartDate,
    SaturdayEndDate,

    issundayDay,
    SundayStartDate,
    SundayEndDate,

    appointmentSlotDuration,
  ]);

  useEffect(() => {
    setIsMonday(true);
    setModayStartDate("10:00");
    setModayEndDate("18:00");

    setIsTuesday(true);
    setTuesdayStartDate("10:00");
    setTuesdayEndDate("18:00");

    setIsWednesDay(true);
    setWednesdayStartDate("10:00");
    setWednesdayEndDate("18:00");

    setIsthrusdayDay(true);
    setThrusdayStartDate("10:00");
    setThrusdayEndDate("18:00");

    setIsfridayDay(true);
    setFridayStartDate("10:00");
    setFridayEndDate("18:00");

    setAppointmentSlotDuration((prevDuration) => ({
      ...prevDuration,
      monday: "45",
      tuesday: "45",
      wednesday: "45",
      thursday: "45",
      friday: "45",
    }));

    // Generate slots for Monday based on default start and end times
    const defaulSlots = generateTimeSlots("10:00", "18:00", "45");
    console.log(defaulSlots,"ed defaulSlots");
    
    setMondaySlots(defaulSlots);
    setTuesdaySlots(defaulSlots);
    setWednesdaySlots(defaulSlots);
    setThursdaySlots(defaulSlots);
    setFridaySlots(defaulSlots);

    // Set appointment array
    setAppointmentArray({
      monday: defaulSlots,
      tuesday: defaulSlots,
      wednesday: defaulSlots,
      thursday: defaulSlots,
      friday: defaulSlots,
    });
  }, []);
  
  return (
    <>
      <div className="col-12 col-lg-12" style={{ padding: "10px 0" }}>
        {/* <div
          className="row bg-headeing mb-4 BusinessDayTimingSlotWrapper"
          id="DayTimSlot"
        >
          <div className="bold BusinessDayTimingSlot">Day</div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div className="bold BusinessDayTimingSlot">End Time</div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
        </div> */}

        <div className="row mb-4 d-flex align-items-center BusinessDayTimingDurationWrapper">
          <div className="BusinessDayTimingSlot">
            <div className="bold BusinessDayTimingSlot">Day</div>
            <div class="form-check WeekDayName">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={isMonday}
                onClick={(e) => setIsMonday(!isMonday)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                monday
              </label>
            </div>
          </div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div
            onClick={() => handleStartInputRef(0)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              ref={inputStartTimeRef[0]}
              placeholder="Enter your name"
              disabled={!isMonday}
              value={modayStartDate}
              onChange={(e) => {
                setModayStartDate(e.target.value);
                handleSelectSlotDuration(
                  "monday",
                  appointmentSlotDuration?.monday,
                  e.target.value,
                  modayEndDate
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">End Time</div>

          <div
            onClick={() => handleEndInputRef(0)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              ref={inputEndTimeRef[0]}
              placeholder="Enter your name"
              disabled={!isMonday}
              value={modayEndDate}
              onChange={(e) => {
                setModayEndDate(e.target.value);
                handleSelectSlotDuration(
                  "monday",
                  appointmentSlotDuration?.monday,
                  modayStartDate,
                  e.target.value
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
          <div
            className="BusinessDayTimingSlot"
            style={{ position: "relative" }}
          >
            <select
              className="form-control"
              disabled={!isMonday}
              value={appointmentSlotDuration?.monday}
              onChange={(e) =>
                handleSelectSlotDuration(
                  "monday",
                  e.target.value,
                  modayStartDate,
                  modayEndDate
                )
              }
            >
              <option value="">Slot Duration</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>
        
        <div className="row bottomline Businessslotedit ">
          {mondaySlots &&
            mondaySlots.length != 0 &&
            mondaySlots.map((data, index) => (
              <div key={`form-${data["formCount"]}`}>
                <div className="serviceboxedit">
                  <i
                    class="fa-solid fa-circle-xmark crosseditservice"
                    onClick={() => removeForm("monday", index)}
                  ></i>

                  <div className="hournameedit" id="Businesshoursformstyle">
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">Start Time</label> */}

                      <div>{data["startDate"]} </div>
                    </div>
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">End Time</label> */}

                      <div> - {data["endDate"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="row mb-4 d-flex align-items-center BusinessDayTimingDurationWrapper">
          <div className="BusinessDayTimingSlot">
            <div className="bold BusinessDayTimingSlot">Day</div>
            <div class="form-check WeekDayName">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                checked={isTuesday}
                id="flexCheckDefault"
                onClick={(e) => setIsTuesday(!isTuesday)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Tuesday
              </label>
            </div>
          </div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div
            onClick={() => handleStartInputRef(1)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              ref={inputStartTimeRef[1]}
              placeholder="Enter your name"
              disabled={!isTuesday}
              value={tuesdayStartDate}
              onChange={(e) => {
                setTuesdayStartDate(e.target.value);
                handleSelectSlotDuration(
                  "tuesday",
                  appointmentSlotDuration?.tuesday,
                  e.target.value,
                  tuesdayEndDate
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">End Time</div>
          <div
            onClick={() => handleEndInputRef(1)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              ref={inputEndTimeRef[1]}
              placeholder="Enter your name"
              disabled={!isTuesday}
              value={tuesdayEndDate}
              onChange={(e) => {
                setTuesdayEndDate(e.target.value);
                handleSelectSlotDuration(
                  "tuesday",
                  appointmentSlotDuration?.tuesday,
                  tuesdayStartDate,
                  e.target.value
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
          <div
            className="BusinessDayTimingSlot"
            style={{ position: "relative" }}
          >
            <select
              className="form-control"
              disabled={!isTuesday}
              value={appointmentSlotDuration?.tuesday}
              onChange={(e) =>
                handleSelectSlotDuration(
                  "tuesday",
                  e.target.value,
                  tuesdayStartDate,
                  tuesdayEndDate
                )
              }
            >
              <option value="">Slot Duration</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>
        <div className="row bottomline Businessslotedit">
          {tuesdaySlots &&
            tuesdaySlots.length != 0 &&
            tuesdaySlots.map((data, index) => (
              <div key={`form-${data["formCount"]}`}>
                <div className="serviceboxedit">
                  <i
                    class="fa-solid fa-circle-xmark crosseditservice"
                    // onClick={() => removeForm(index)}
                    onClick={() => removeForm("tuesday", index)}
                  ></i>

                  <div className="hournameedit" id="Businesshoursformstyle">
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">Start Time</label> */}

                      <div>{data["startDate"]}</div>
                    </div>
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">End Time</label> */}

                      <div> - {data["endDate"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="row mb-4 d-flex align-items-center BusinessDayTimingDurationWrapper">
          <div className="BusinessDayTimingSlot">
            <div className="bold BusinessDayTimingSlot">Day</div>
            <div class="form-check WeekDayName">
              <input
                class="form-check-input"
                type="checkbox"
                checked={isWednesDay}
                id="flexCheckDefault"
                onClick={(e) => setIsWednesDay(!isWednesDay)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Wednesday
              </label>
            </div>
          </div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div
            onClick={() => handleStartInputRef(2)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              ref={inputStartTimeRef[2]}
              placeholder="Enter your name"
              disabled={!isWednesDay}
              value={wednesdayStartDate}
              onChange={(e) => {
                setWednesdayStartDate(e.target.value);
                handleSelectSlotDuration(
                  "wednesday",
                  appointmentSlotDuration?.wednesday,
                  e.target.value,
                  wednesdayEndDate
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">End Time</div>
          <div
            onClick={() => handleEndInputRef(2)}
            className="BusinessDayTimingSlot"
          >
            <input
              ref={inputEndTimeRef[2]}
              class="form-control"
              type="time"
              disabled={!isWednesDay}
              value={wednesdayEndDate}
              onChange={(e) => {
                setWednesdayEndDate(e.target.value);
                handleSelectSlotDuration(
                  "wednesday",
                  appointmentSlotDuration?.wednesday,
                  wednesdayStartDate,
                  e.target.value
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
          <div
            className="BusinessDayTimingSlot"
            style={{ position: "relative" }}
          >
            <select
              className="form-control"
              disabled={!isWednesDay}
              value={appointmentSlotDuration?.wednesday}
              onChange={(e) =>
                handleSelectSlotDuration(
                  "wednesday",
                  e.target.value,
                  wednesdayStartDate,
                  wednesdayEndDate
                )
              }
            >
              <option value="">Slot Duration</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>

        <div className="row bottomline Businessslotedit">
          {wednesdaySlots &&
            wednesdaySlots.length != 0 &&
            wednesdaySlots.map((data, index) => (
              <div key={`form-${data["formCount"]}`}>
                <div className="serviceboxedit">
                  <i
                    class="fa-solid fa-circle-xmark crosseditservice"
                    // onClick={() => removeForm(index)}
                    onClick={() => removeForm("wednesday", index)}
                  ></i>

                  <div className="hournameedit" id="Businesshoursformstyle">
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">Start Time</label> */}

                      <div>{data["startDate"]}</div>
                    </div>
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">End Time</label> */}

                      <div> - {data["endDate"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="row mb-4 d-flex align-items-center BusinessDayTimingDurationWrapper">
          <div className="BusinessDayTimingSlot">
            <div className="bold BusinessDayTimingSlot">Day</div>
            <div class="form-check WeekDayName">
              <input
                class="form-check-input"
                type="checkbox"
                checked={isthrusdayDay}
                id="flexCheckDefault"
                onClick={(e) => setIsthrusdayDay(!isthrusdayDay)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Thursday
              </label>
            </div>
          </div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div
            onClick={() => handleStartInputRef(3)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              placeholder="Enter your name"
              ref={inputStartTimeRef[3]}
              disabled={!isthrusdayDay}
              value={thrusdayStartDate}
              onChange={(e) => {
                setThrusdayStartDate(e.target.value);
                handleSelectSlotDuration(
                  "thursday",
                  appointmentSlotDuration?.thursday,
                  e.target.value,
                  thrusdayEndDate
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">End Time</div>
          <div
            onClick={() => handleEndInputRef(3)}
            className="BusinessDayTimingSlot"
          >
            <input
              ref={inputEndTimeRef[3]}
              class="form-control"
              type="time"
              placeholder="Enter your name"
              disabled={!isthrusdayDay}
              value={thrusdayEndDate}
              onChange={(e) => {
                setThrusdayEndDate(e.target.value);
                handleSelectSlotDuration(
                  "thursday",
                  appointmentSlotDuration?.thursday,
                  thrusdayStartDate,
                  e.target.value
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
          <div
            className="BusinessDayTimingSlot"
            style={{ position: "relative" }}
          >
            <select
              className="form-control"
              disabled={!isthrusdayDay}
              value={appointmentSlotDuration?.thursday}
              onChange={(e) =>
                handleSelectSlotDuration(
                  "thursday",
                  e.target.value,
                  thrusdayStartDate,
                  thrusdayEndDate
                )
              }
            >
              <option value="">Slot Duration</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>

        <div className="row bottomline Businessslotedit">
          {thursdaySlots &&
            thursdaySlots.length != 0 &&
            thursdaySlots.map((data, index) => (
              <div key={`form-${data["formCount"]}`}>
                <div className="serviceboxedit">
                  <i
                    class="fa-solid fa-circle-xmark crosseditservice"
                    // onClick={() => removeForm(index)}
                    onClick={() => removeForm("thursday", index)}
                  ></i>

                  <div className="hournameedit" id="Businesshoursformstyle">
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">Start Time</label> */}

                      <div>{data["startDate"]}</div>
                    </div>
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">End Time</label> */}

                      <div> - {data["endDate"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="row mb-4 d-flex align-items-center BusinessDayTimingDurationWrapper">
          <div className="BusinessDayTimingSlot">
            <div className="bold BusinessDayTimingSlot">Day</div>
            <div class="form-check WeekDayName">
              <input
                class="form-check-input"
                type="checkbox"
                // value=""
                checked={isfridayDay}
                id="flexCheckDefault"
                onClick={(e) => setIsfridayDay(!isfridayDay)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Friday
              </label>
            </div>
          </div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div
            onClick={() => handleStartInputRef(4)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              disabled={!isfridayDay}
              value={fridayStartDate}
              ref={inputStartTimeRef[4]}
              onChange={(e) => {
                setFridayStartDate(e.target.value);
                handleSelectSlotDuration(
                  "friday",
                  appointmentSlotDuration?.friday,
                  e.target.value,
                  fridayEndDate
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">End Time</div>
          <div
            onClick={() => handleEndInputRef(4)}
            className="BusinessDayTimingSlot"
          >
            <input
              ref={inputEndTimeRef[4]}
              class="form-control"
              type="time"
              value={fridayEndDate}
              disabled={!isfridayDay}
              onChange={(e) => {
                setFridayEndDate(e.target.value);
                handleSelectSlotDuration(
                  "friday",
                  appointmentSlotDuration?.friday,
                  fridayStartDate,
                  e.target.value
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
          <div
            className="BusinessDayTimingSlot"
            style={{ position: "relative" }}
          >
            <select
              className="form-control"
              disabled={!isfridayDay}
              value={appointmentSlotDuration?.friday}
              onChange={(e) =>
                handleSelectSlotDuration(
                  "friday",
                  e.target.value,
                  fridayStartDate,
                  fridayEndDate
                )
              }
            >
              <option value="">Slot Duration</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>

        <div className="row bottomline Businessslotedit">
          {fridaySlots &&
            fridaySlots.length != 0 &&
            fridaySlots.map((data, index) => (
              <div key={`form-${data["formCount"]}`}>
                <div className="serviceboxedit">
                  <i
                    class="fa-solid fa-circle-xmark crosseditservice"
                    // onClick={() => removeForm(index)}
                    onClick={() => removeForm("friday", index)}
                  ></i>

                  <div className="hournameedit" id="Businesshoursformstyle">
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">Start Time</label> */}

                      <div>{data["startDate"]}</div>
                    </div>
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">End Time</label> */}

                      <div> - {data["endDate"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="row mb-4 d-flex align-items-center BusinessDayTimingDurationWrapper">
          <div className="BusinessDayTimingSlot">
            <div className="bold BusinessDayTimingSlot">Day</div>
            <div class="form-check WeekDayName">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked={issaturdayDay}
                onClick={(e) => setIsSaturdayDay(!issaturdayDay)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Saturday
              </label>
            </div>
          </div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div
            onClick={() => handleStartInputRef(5)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              ref={inputStartTimeRef[5]}
              disabled={!issaturdayDay}
              value={SaturdayStartDate}
              onChange={(e) => {
                setSaturdayStartDate(e.target.value);
                handleSelectSlotDuration(
                  "saturday",
                  appointmentSlotDuration?.saturday,
                  e.target.value,
                  SaturdayEndDate
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">End Time</div>
          <div
            onClick={() => handleEndInputRef(5)}
            className="BusinessDayTimingSlot"
          >
            <input
              ref={inputEndTimeRef[5]}
              class="form-control"
              type="time"
              disabled={!issaturdayDay}
              value={SaturdayEndDate}
              onChange={(e) => {
                setSaturdayEndDate(e.target.value);
                handleSelectSlotDuration(
                  "saturday",
                  appointmentSlotDuration?.saturday,
                  SaturdayStartDate,
                  e.target.value
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
          <div
            className="BusinessDayTimingSlot"
            style={{ position: "relative" }}
          >
            <select
              className="form-control"
              disabled={!issaturdayDay}
              value={appointmentSlotDuration?.saturday}
              onChange={(e) =>
                handleSelectSlotDuration(
                  "saturday",
                  e.target.value,
                  SaturdayStartDate,
                  SaturdayEndDate
                )
              }
            >
              <option value="">Slot Duration</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>

        <div className="row bottomline Businessslotedit">
          {saturdaySlots &&
            saturdaySlots.length != 0 &&
            saturdaySlots.map((data, index) => (
              <div key={`form-${data["formCount"]}`}>
                <div className="serviceboxedit">
                  <i
                    class="fa-solid fa-circle-xmark crosseditservice"
                    // onClick={() => removeForm(index)}
                    onClick={() => removeForm("saturday", index)}
                  ></i>

                  <div className="hournameedit" id="Businesshoursformstyle">
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">Start Time</label> */}

                      <div>{data["startDate"]}</div>
                    </div>
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">End Time</label> */}

                      <div> - {data["endDate"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="row mb-4 d-flex align-items-center BusinessDayTimingDurationWrapper">
          <div className="BusinessDayTimingSlot">
            <div className="bold BusinessDayTimingSlot">Day</div>
            <div class="form-check WeekDayName">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked={issundayDay}
                onClick={(e) => setIsSundayDay(!issundayDay)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Sunday
              </label>
            </div>
          </div>
          <div className="bold BusinessDayTimingSlot">Start Time</div>
          <div
            onClick={() => handleStartInputRef(6)}
            className="BusinessDayTimingSlot"
          >
            <input
              class="form-control"
              type="time"
              ref={inputStartTimeRef[6]}
              disabled={!issundayDay}
              value={SundayStartDate}
              onChange={(e) => {
                setSundayStartDate(e.target.value);
                handleSelectSlotDuration(
                  "sunday",
                  appointmentSlotDuration?.sunday,
                  e.target.value,
                  SundayEndDate
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">End Time</div>
          <div
            onClick={() => handleEndInputRef(6)}
            className="BusinessDayTimingSlot"
          >
            <input
              ref={inputEndTimeRef[6]}
              class="form-control"
              type="time"
              disabled={!issundayDay}
              value={SundayEndDate}
              onChange={(e) => {
                setSundayEndDate(e.target.value);
                handleSelectSlotDuration(
                  "sunday",
                  appointmentSlotDuration?.sunday,
                  SundayStartDate,
                  e.target.value
                );
              }}
            />
          </div>
          <div className="bold BusinessDayTimingSlot">Slot Duration</div>
          <div
            className="BusinessDayTimingSlot"
            style={{ position: "relative" }}
          >
            <select
              className="form-control"
              disabled={!issundayDay}
              value={appointmentSlotDuration?.sunday}
              onChange={(e) =>
                handleSelectSlotDuration(
                  "sunday",
                  e.target.value,
                  SundayStartDate,
                  SundayEndDate
                )
              }
            >
              <option value="">Slot Duration</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>

        <div className="row bottomline Businessslotedit">
          {sundaySlots &&
            sundaySlots.length != 0 &&
            sundaySlots.map((data, index) => (
              <div key={`form-${data["formCount"]}`}>
                <div className="serviceboxedit">
                  <i
                    class="fa-solid fa-circle-xmark crosseditservice"
                    // onClick={() => removeForm(index)}
                    onClick={() => removeForm("sunday", index)}
                  ></i>

                  <div className="hournameedit" id="Businesshoursformstyle">
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">Start Time</label> */}

                      <div>{data["startDate"]}</div>
                    </div>
                    <div className="lableinput" style={{ width: "auto" }}>
                      {/* <label class="form-label">End Time</label> */}

                      <div> - {data["endDate"]}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default NewBusinessHour;
