import React, { useContext, useEffect, useState } from "react";
import "./Infpopupdesign.css";
import DropdownInf from "./DropdownInf";
import bannerimage from "../../../Images/bannerimage.jpg";
import axios from "axios";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";

const Producttableinf = ({
  selectedBusiness,
  searchQuery,
  options,
  optionsId,
  allmktbusiness,
  onSelectedProductsChange,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  // const [selectedBusiness, setSelectedBusiness] = useState(null);

  const [products, setProducts] = useState([]);
  const { userData } = useContext(userContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if(!!selectedBusiness){
          const response = await axios.post(
              `${environmentVariables?.apiUrl}api/v1/influencer/getAllProductsByBusinessId`,
              {
                business_id: selectedBusiness,
                // page: 1,
                // pageSize: 3,
              },
            {
              headers: {
                // '_token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcl90eXBlIjoic3VwZXJfYWRtaW4iLCJlbWFpbCI6InN1cGVyYWRtaW5Adm15Y2FyZHMuY29tIiwiaWF0IjoxNzMwODcyMzgwLCJleHAiOjE3MzA5NTg3ODB9.KdMBfYU7_RWRtXQeoItRHYiHS_OyzDYmYBMJld9r05s',
                _token: userData?.token,
                "Content-Type": "application/json",
              },
            }
          );
          setProducts(response.data.data);
        }else{
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]);
      }
    };

    fetchProducts();
  }, [selectedBusiness]);

  // const handleCheckboxChange = (id) => {
  //   setSelectedProducts((prevSelected) =>
  //     prevSelected.includes(id)
  //       ? prevSelected?.filter((productId) => productId !== id)
  //       : [...prevSelected, id]
  //   );
  // };

  useEffect(() => {
    onSelectedProductsChange(selectedProducts);
  }, [selectedProducts, onSelectedProductsChange]);

  const handleCheckboxChange = (id) => {
    setSelectedProducts((prevSelected) => {
      const updatedSelectedProducts = prevSelected.includes(id)
        ? prevSelected.filter((productId) => productId !== id)
        : [...prevSelected, id];
      console.log("Selected Products:", updatedSelectedProducts);
      return updatedSelectedProducts;
    });
  };

  // const handleSelectAll = () => {
  //   if (selectedProducts?.length === products?.length) {
  //     setSelectedProducts([]);
  //   } else {
  //     setSelectedProducts(products?.map((product) => product.id));
  //   }
  // };

  const handleSelectAll = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
    } else {
      const allProductIds = products.map((product) => product.id);
      setSelectedProducts(allProductIds);
    }
  };

  // const filteredProducts = products?.filter((product) =>
  //   product.name.toLowerCase().includes(searchQuery?.toLowerCase()) ||  product.stock?.toLowerCase().includes(searchQuery?.toLowerCase())
  // );

  // const filteredProducts = products?.filter((product) =>
  //   (product.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||   product.stock?.toLowerCase().includes(searchQuery?.toLowerCase()))
  // );

  // const filteredProducts = searchQuery
  //   ? products?.filter(
  //       (product) =>
  //         product.product_name
  //           ?.toLowerCase()
  //           .includes(searchQuery?.toLowerCase()) ||
  //         product.stock?.toLowerCase().includes(searchQuery?.toLowerCase())
  //     )
  //   : products;
  const filteredProducts = searchQuery
  ? products?.filter(
      (product) =>
        product.product_price?.toString().match(/^\d+(\.\d+)?$/) && // Allow integers and decimals
        (product.product_name
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
          product.stock?.toLowerCase().includes(searchQuery?.toLowerCase()))
    )
  : products?.filter(
      (product) => product.product_price?.toString().match(/^\d+(\.\d+)?$/) // Allow integers and decimals
    );




  return (
    <div className="table-container">
      <div className="table">
        <div className="table-row table-head">
          <div className="table-cell">
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={selectedProducts?.length === filteredProducts?.length}
            />
          </div>
          <div className="table-cell prodname">Product Name</div>
          <div className="table-cell">Product Img</div>
          <div className="table-cell">Product Category</div>
          <div className="table-cell">Product Price</div>
          <div className="table-cell">Product Quantity</div>
        </div>
        {/* {console.log(filteredProducts,"productproduct")} */}
        {!!filteredProducts && filteredProducts.length>0?
        filteredProducts?.map((product) => (
          <div className="table-row" key={product.id}>
            <div className="table-cell">
              <input
                type="checkbox"
                checked={selectedProducts.includes(product.id)}
                onChange={() => handleCheckboxChange(product.id)}
              />
            </div>
            <div className="table-cell prodname">
              {/* <img src={product.image} alt={product.name} className="product-image" /> */}
              {product?.product_name}
            </div>
            <div className="table-cell">
              {/* <span className={`status-badge ${product.status?.toLowerCase()?.replace(" ", "-")}`}>
                {product.status}
              </span> */}
              <img
                src={`${environmentVariables?.apiUrl}uploads/${product?.image}`}
                alt={product.name}
                className="product-image"
              />
            </div>

            <div className="table-cell">{product?.productCat?.category_Name || ""}</div>
            <div className="table-cell">{product.product_price}</div>
            <div className="table-cell">{product.product_quantity}</div>
          </div>
        )):
          <div>
            {!!selectedBusiness ? 
            <div className="selectbusinessoncondition">Product<span>Not Available</span>in this Business</div>
            : 
            <div className="selectbusinessoncondition">Please<span>Select a Business</span>from dropdown to choose products</div>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default Producttableinf;
