import React, { useContext, useEffect, useState } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from "sweetalert2";
import styled from "styled-components";
// const StyledDiv = styled.div`
//       .data-table-extensions{
//         display: none;
//       }
// `;

const StyledDiv = styled.div`
  .data-table-extensions {
    // display: block;
    display:none;
  }

  @media (max-width: 768px) {
    .data-table-extensions {
      display: none;
    }
  }
`;

function Charttf(props) {
  const [data, setData] = useState([]);
  const [businesslistData, setBusinesslistData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const { businessData, setBusinessData } = useContext(businessContext);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isLoader, setIsloader] = useState(true);
  const [userId, setUserId] = useState(null);
  const storedUserData = localStorage.getItem("user");
  const userDataObject = JSON.parse(storedUserData);
  const [isLoading, setIsLoading] = useState(true);
  const [businessLink, setBusinessLink] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const httpProtocol = environmentVariables?.httpProtocol;
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props?.data?.totalBusiness?.rs) {
      setIsloader(false);
      let temp = props?.data?.totalBusiness?.rs;
      temp = temp.sort((x, y) => y.id - x.id);
      setBusinesslistData(temp);
    }
  }, []);

  const customStyles1 = {
    ".data-table-extensions": {
      display: "none",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
     
     
    },
    searchBar: {
      display: "none",

    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        // paddingLeft:"25px",
        border: "none",
        marginTop:"25px"
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        padding:"10px 0px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
  };

  const fetchBusinessData = async (userId) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/business/fetchBusiness?id=${userId}`,
        headers: {
          _token: userData?.token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setIsloader(false);
          // console.log(response?.data?.data, "fetchssData123");
          const newData1 = response?.data?.data.map((val) => {
            val.created_at = val.created_at.split("T")[0];
            return val;
          });
          const newData = newData1?.slice(0, 5);
          setBusinesslistData(newData);
        })
        .catch((error) => {
          setIsloader(false);
        });
      setIsLoading(false);
    } catch (err) {
      console.log(err, "error");
    }
  };
  const navigate = useNavigate();
  const handleTabClick = (route) => {
    navigate(route);
  };

  const handleviewClick = (slug, slug_subdomain) => {
    if (window != undefined) {
      let redirectUrl = (slug_subdomain != null)? httpProtocol +slug_subdomain:(slug !=null)? "/"+ slug:"/";
      window.open(`${redirectUrl}`, "_blank");
    }
  };

  const handleToggleStatus = (businessId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    axios
      .put(
        `${baseUrl}api/v1/business/updateStatus/${businessId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        const updatedBusinessListData = businesslistData.map((business) => {
          if (business.id === businessId) {
            return { ...business, status: newStatus };
          }
          return business;
        });
        setBusinesslistData(updatedBusinessListData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating business status:", error);
      });
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/business/deleteBusiness?id=${id}`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
        };
        axios
          .request(config)
          .then((response) => {
            Swal.fire(
              "Deleted!",
              "Selected Business has been deleted.",
              "success"
            );
            fetchBusinessData();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };

  const handleCopuClick = (slugb) => {
    const originUrl = window.location.origin;
    setBusinessLink(`${originUrl}/${slugb}`);
    navigator.clipboard
      .writeText(businessLink)
      .then((res) => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info("Link Copy on Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  // const columns = [
  //   {
  //     name: "User Name",
  //     selector: (row) => (<></>),
  //     sortable: true,
  //     cell:(d) =>{
  //       return (<><div>{d?.userTitle}</div></>)
  //     },
  //   },
  //   {
  //     name: "Business Name",
  //     selector: (row) => (<></>),
  //     sortable: true,
  //     cell: (d) => {
  //       const capitalizedName =
  //         d?.title?.charAt(0).toUpperCase() + d?.title?.slice(1);
  //         let merge=`${baseUrl}uploads/${d?.logo}`;
  //         if(d?.logo===null || d?.logo === undefined){
  //           merge=`${baseUrl}uploads/business.png`;
  //         }
  //       return (<><div  style={{
  //         width: '50px',
  //         height: '50px',
  //         borderRadius: '50%',
  //         overflow: 'hidden',
  //         marginRight: '10px',
  //       }}>
  //         <img src={merge} alt="Logo1"  style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
  //         </div>
  //         <div>{capitalizedName}</div>
  //         </>
  //         );
  //     },
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) => row.status,
  //     sortable: true,
  //     cell: (d) => {
  //       const capitalizedName =
  //         d?.status?.charAt(0).toUpperCase() + d?.status?.slice(1);
  //       return capitalizedName;
  //     },
  //   },
  //   {
  //     name: "Date",
  //     selector: (row) => new Date(row?.created_at).toLocaleDateString(),
  //     sortable: true,
  //   },
  //   {
  //     name: "Action",
  //     sortable: false,
  //     cell: (d) => (
  //       <div>
  //         <i key={`view_${d.id}`} onClick={() => handleviewClick(d.slug)} className="first fas fa-eye"></i>
  //       </div>
  //     ),
  //   },
  // ];

  const columns = isSmallScreen
    ? [
        {
          name: "Business Name",
          selector: (row) => <></>,
          sortable: false,
          cell: (d) => {
            const capitalizedName =
              d?.title?.charAt(0).toUpperCase() + d?.title?.slice(1);
            let merge = `${baseUrl}uploads/${d?.logo}`;
            if (d?.logo === null || d?.logo === undefined) {
              merge = `${baseUrl}uploads/business.png`;
            }
            return (
              <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div>{capitalizedName}</div>
              </div>
            );
          },
          width:"50%"
        },
        {
          name: "Date",
          selector: (row) => new Date(row?.created_at).toLocaleDateString(),
          sortable: false,
        },
        {
          name: "Action",
          sortable: false,
          cell: (d) => (
            <div>
              <i
                key={`view_${d.id}`}
                onClick={() => handleviewClick(d.slug, d.slug_subdomain)}
                className="first fas fa-eye"
              ></i>
            </div>
          ),
        },
      ]
    : [
        {
          name: "User Name",
          selector: (row) => <></>,
          sortable: false,
          cell: (d) => {
            return (
              <>
                <div>{d?.userTitle}</div>
              </>
            );
          },
       
        },
        {
          name: "Business Name",
          selector: (row) => <></>,
          sortable: false,
          cell: (d) => {
            const capitalizedName =
              d?.title?.charAt(0).toUpperCase() + d?.title?.slice(1);
            let merge = `${baseUrl}uploads/${d?.logo}`;
            if (d?.logo === null || d?.logo === undefined) {
              merge = `${baseUrl}uploads/business.png`;
            }
            return (
              <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div>{capitalizedName}</div>
              </div>
            );
          },
          width: "35%",
        },
        {
          name: "Status",
          selector: (row) => row.status,
          sortable: false,
          cell: (d) => {
            const capitalizedName =
              d?.status?.charAt(0).toUpperCase() + d?.status?.slice(1);
            return capitalizedName;
          },
        },
        {
          name: "Date",
          selector: (row) => new Date(row?.created_at).toLocaleDateString(),
          sortable: false,
        },
        {
          name: "Action",
          sortable: false,
          cell: (d) => (
            <div>
              <i
                key={`view_${d.id}`}
                onClick={() => handleviewClick(d.slug, d.slug_subdomain)}
                className="first fas fa-eye"
              ></i>
            </div>
          ),
        },
      ];

  const tableData = {
    columns,
    data: businesslistData.slice(0, 5),
  };
  return (
    <StyledDiv>
      <div>
        <article>
          <div className="row d-none" style={{ position: "relative" }}>
            <div className="col-md-12">
              <div className="tile">
                <div className="tile-body">
                  <form className="row">
                    <div className="mb-2 col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search....."
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <select className="form-select">
                        <option value="">Select an option</option>
                        <option value="option1">xxx </option>
                        <option value="option2">xxxx</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-4 align-self-end">
                      <button className="btn btn-primary me-3" type="button">
                        <i className="bi bi-check-circle-fill ms-2 "></i>Serarch
                      </button>
                      <button className="btn btn-danger" type="button">
                        <i className="bi bi-check-circle-fill me-2"></i>Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {isLoader ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (
            <div className="main dd422" style={{ marginTop: "10px" }}>
              {businesslistData.length > 0 ? (
                <DataTableExtensions
                  {...tableData}
                  style={customStyles1[".data-table-extensions"]}
                  customStyles={customStyles}
                  filterPlaceholder="Search Business"
                >
                  <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    noSearch
                    defaultSortField="id"
                    sortIcon={<SortIcon />}
                    defaultSortAsc={true}
                    paginationPerPage={5}
                    highlightOnHover
                    customStyles={customStyles}
                    noTableOptions
                  />
                </DataTableExtensions>
              ) : (
                <>
                  {" "}
                  <div>
                    <br /> <br />
                    <br />
                    <h6>There are no records to display</h6>
                  </div>
                </>
              )}
            </div>
          )}
        </article>
      </div>
    </StyledDiv>
  );
}
//312 20:57
export default Charttf;
