import React, { useState } from 'react';
import Select from 'react-select';

const DropdownInf = ({ options, selected, selectedTitle, handleSetSelected }) => {
    // const [isOpen, setIsOpen] = useState(false);

    // const handleSelect = (bid, title) => {
    //     handleSetSelected(bid, title);
    //     setIsOpen(false);
    // };
    const selectOptions = options?.map(option => ({
        value: option?.id,
        label: option?.title,
    }));

    // Handle selection changes
    const handleChange = (selectedOption) => {
        handleSetSelected(selectedOption?.value, selectedOption?.label);
    };

    // Set the default selected option
    const defaultValue = selectOptions?.find(option => option.label === selectedTitle);
    
// console.log(selected,"selectedselected")
    
    return (
        // <div className="dropdown" style={{minWidth:"200px"}}>
        //     <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)} >
        //         {selectedTitle || "Select a business"}
        //         <span className={`dropdown-icon ${isOpen ? 'open' : ''}`}> ▼ </span>
        //     </div>

        //     {isOpen && (
        //         <div className="dropdown-list">
        //             {options?.map((option, index) => {
        //                 if(!!option?.title){
        //                     return (
        //                         <div key={index} className="dropdown-item" onClick={() => handleSelect(option?.id, option?.title)} >
        //                             {option?.title}
        //                         </div>
        //                     )
        //                 }
        //             }
        //             )}
        //         </div>
        //     )}
        // </div>
        <div style={{ minWidth: "200px" }}>
        <Select
            options={selectOptions}
            onChange={handleChange}
            value={defaultValue}
            placeholder="Select a business"
            isSearchable={true} // Enable search functionality
            isClearable={true} 
        />
    </div>
    );
};

export default DropdownInf;