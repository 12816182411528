import React, { useState } from "react";
import { environmentVariables } from "../../../../config/env.config";

const GalleryCarousel = ({ slides, active, showGallery, toggleGallery }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [closing, setClosing] = useState(false);

  const openPreview = (link) => {
    showGallery ? setSelectedImage(link): "";
  };

  const closePreview = () => {
    setSelectedImage(null);
  };

  const handleClose = () =>{
    setClosing(true); 
    setTimeout(() => {
      toggleGallery(); 
      setClosing(false);
    }, 500); 
  }

  return (
    <div
      style={{bottom:closing?"-100%":showGallery?"50px":"-100%"}}
      className={showGallery ? "gallery-wrapper gallery-popup" : "gallery-wrapper"}
    >
      <h3 className="sectitle">Gallery</h3>
      <p className="cross">
        {showGallery && <i onClick={handleClose} className="fa-solid fa-x"></i>}
      </p>
      {slides.length > 0 && (
        <div
          style={active ? { maxHeight: "480px", overflow: "scroll" } : {}}
          className="inner-div"
        >
          {slides.map((item, index) => {
            const link = `${environmentVariables.apiUrl}uploads/gallery/${item.image}`;
            return (
              <div onClick={() => openPreview(link)} className="item" key={index}>
                <img src={link} alt="" />
              </div>
            );
          })}
        </div>
      )}
      {selectedImage && (
        <div className="preview-box" onClick={closePreview}>
          <img src={selectedImage} alt="Preview" className="preview-image" />
        </div>
      )}
    </div>
  );
};

export default GalleryCarousel;
