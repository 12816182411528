import React, { useRef, useState } from "react";
// import "./TemplatComman.css";

import "./Theme22.css";
import styled from "styled-components";
import CabBanner from "./../../../../Images/Cabdefaultbanner.png";
import Cablogo from "./../../../../Images/Cabdefaultlogo.png";
import CabIcon from "./../../../../Images/Defaultcabtab.png";

import  AvilableCab from "./../../../../Images/Defaultavilablecabtab.png";
import  AboutIcon from "./../../../../Images/Defaultabouttab.png";
import  TermCondIcon from "./../../../../Images/Defaultterm&contab.png";
import  COntactIcon from "./../../../../Images/DefaultContacttab.png";
import AvilableCabTab from "./AvilableCabTab";
import AvilableCabTab1 from "./AvilableCabTab1";



const Theme22Template = (props) => {
  const [activeTab, setActiveTab] = useState("avilablecab");

  const renderContent = () => {
    switch (activeTab) {
      case "cab":
        return <div> abc </div>;
      case "avilablecab":
        return <div><AvilableCabTab1 /> </div>;
      case "about":
        return <div>efg</div>;
      case "termcondition":
          return <div>ghi</div>;
      case "contact":
            return <div>jkl</div>;
      default:
        return null;
    }
  };


  const getTabClass = (tabName) => {
    return activeTab === tabName
      ? "flex bg-Cabdefaultcolor p-2 border-2 border-none items-center rounded mx-2 cursor-pointer"
      : "flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer";
  };

  return (
    <div>
      <div class="main-temaplt" id="temp22">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
             {/* ///////////////////////////   cab Template     /////////////////////// */}

            <div>
              <div className="relative">
                <img className="w-full" src={CabBanner}/>
                <div className="flex  absolute items-center text-center" style={{top:"50px", flexDirection:"column", lineHeight:"10px"}}>
                  <img className="w-fiftypx pb-2" style={{maxWidth:"80px", borderRadius:"100px"}} src={Cablogo} />
                  <div className="font-DM text-2xl text-white uppercase break-all">Floyd Miles</div>
                </div>
              </div>
          <div className="display-flex overflow-scroll" >
            <div className="flex bg-black pb-4" style={{width:"max-content"}}>
                <div 
                // className="flex bg-Cabdefaultcolor p-2 border-2 border-none items-center rounded mx-2 cursor-pointer"
                className={getTabClass("cab")}
                // onClick={()=>setActiveTab("cab")}
                >
                  <img src={CabIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Cab</div>
                </div>
            
                 <div 
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                   className={getTabClass("avilablecab")}
                   onClick={()=>setActiveTab("avilablecab")}
                   >
                  <img src={AvilableCab} className=" h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Available Cabs</div>
                </div>

                <div
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("about")}
                    // onClick={()=>setActiveTab("about")}
                    >
                  <img src={AboutIcon} className=" h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">About</div>
                </div>

                <div 
                // className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("termcondition")}
                    // onClick={()=>setActiveTab("termcondition")}
                    >
                  <img src={COntactIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Term & Conditions</div>
                </div> 

                <div
                //  className="flex bg-transparent p-2 border-1 border-Bordergray items-center rounded mx-2 cursor-pointer"
                className={getTabClass("contact")}
                    // onClick={()=>setActiveTab("contact")}
                    >
                  <img src={COntactIcon} className="h-twelve"/>
                  <div className="px-1 font-Poppins font-medium text-white">Contact</div>
                </div> 
            
            </div>
          </div>
          <div className="px-2 bg-black" style={{height:"100vh"}}>{renderContent()}</div>
            </div>
             
       



             {/* ///////////////////////////   cab Template     /////////////////////// */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme22Template;
