import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  console.log(ff, "adfff");
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Contact Info</h3>
        <ul class="conlink">
          {ff?.contactInfoPhone && (
            <li>
              <a
                href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}
              >
                <i class="fa-solid fa-phone"></i>
                <span>{ff?.contactInfoPhone}</span>
              </a>

              {ff.optionalContactInfoPhone?.split("-")?.[1]?.length > 0 && (
                <a
                  href={
                    ff?.optionalContactInfoPhone
                      ? `tel:${ff?.optionalContactInfoPhone}`
                      : ""
                  }
                >
                  <span style={{ paddingLeft: "50px" }}>
                    {" "}
                    {ff?.optionalContactInfoPhone || ``}{" "}
                  </span>
                </a>
              )}
            </li>
          )}
          {/* {ff.optionalContactInfoPhone && (
            <li>
              <a
                href={
                  ff?.optionalContactInfoPhone
                    ? `tel:${ff?.optionalContactInfoPhone}`
                    : ""
                }
              >
                <i class="fa-solid fa-phone"></i>
                <span> {ff?.optionalContactInfoPhone || ``} </span>
              </a>
            </li>
          )} */}
          {ff?.contactInfoEmail && (
            <li>
              <a
                href={
                  ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
                }
              >
                <i class="fa-solid fa-envelope"></i>
                <span className="mailurllong">{ff?.contactInfoEmail}</span>
              </a>
            </li>
          )}
          {/* <li>
                      <a href="">
                        <span>
                         
                          {ff?.contactInfoWebUrl 
                            "Website Url"}
                        </span>
                      </a>
                    </li> */}
          {ff?.contactInfoAddress && (
            <li>
              <a>
                <i class="fa-solid fa-location-dot"></i>
                <div className="multiaddtheme3">
                  <span>
                    {ff?.contactInfoAddress?.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                  {ff?.contactInfoOptAddress && (
                    <>
                      {" "}
                      <p
                        style={{
                          color: "grey",
                          fontSize: "16px",
                          paddingLeft: "10px",
                          paddingTop: "5px",
                          fontWeight: 700,
                        }}
                      >
                        Alternative Address
                      </p>
                      <span>
                        {ff?.contactInfoOptAddress
                          ?.split("\n")
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                      </span>
                    </>
                  )}
                </div>
              </a>
            </li>
          )}
          {/* {ff?.contactInfoOptAddress &&
            <li>
              <a>
                <i class="fa-solid fa-location-dot"></i>
                <span>
                  {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              </a>
            </li>
          } */}
          {ff?.contactInfoWebUrl && (
            <li>
              {/* <a href={(ff?.contactInfoWebUrl)?`http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}`:''} target="_blank"> */}
              <a
                href={
                  ff?.contactInfoWebUrl
                    ? `http://${ff?.contactInfoWebUrl.replace(
                        /^(https?:\/\/)?(www\.)?/,
                        ""
                      )}`
                    : "#"
                }
                onClick={(e) => {
                  if (!ff?.contactInfoWebUrl) {
                    e.preventDefault(); // Prevents the default link behavior
                  }
                }}
                target={ff?.contactInfoWebUrl ? "_blank" : undefined}
                className="contactinfolink content"
              >
                {" "}
                <i class="fa-solid fa-link"></i>
                <span className="mailurllong">{ff?.contactInfoWebUrl}</span>
              </a>
            </li>
          )}
        </ul>
      </article>
    </>
  );
}
export default SubContactInfo;
