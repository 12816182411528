import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Contact Info</h3>
        <ul class="conlink">
          <li>
            <a href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}>
              <i class="fa-solid fa-phone"></i>
              <span>{ff?.contactInfoPhone}</span>{ff.optionalContactInfoPhone &&
           
                <span>/ {ff?.optionalContactInfoPhone || ``} </span>
           
          }
            </a>
          </li>

          {ff?.contactInfoWebUrl && 
          <li>
            {/* <a href={(ff?.contactInfoWebUrl)?`http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}`:''} target="_blank" className="contactinfolink content"> */}
            <a
              href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!ff?.contactInfoWebUrl) {
                  e.preventDefault(); // Prevents the default link behavior
                }
              }}
              target={ff?.contactInfoWebUrl ? '_blank' : undefined}
              className="contactinfolink content"
            >
              <i class="fa fa-globe"></i>
              <span className="mailurllong"> {ff?.contactInfoWebUrl} </span>
            </a>
          </li>
        }

{ff?.contactInfoEmail && 
             <li>
                 
               
                {/* <p className="contact-info-label">Email</p> */}
                <a
                  href={
                    ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
                  }
                > <i className="fa fa-solid fa-envelope"></i>
                  <span className="contactinfotext mailurllong">
                    {ff?.contactInfoEmail}
                  </span>
                </a>
             </li>
            } 



{ff?.contactInfoAddress && 
          <li>
            <a >
              <i className="fa-solid fa-location-dot"></i>
              <span>
                {ff?.contactInfoAddress?.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </a>
          </li>
}
{ff?.contactInfoOptAddress && (
                <div >
                  <p style={{ color: "#fff06", fontSize: "16px" }}>Alternative Address</p>
                  <span>
                    {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              )}
         
        </ul>
      </article>
    </>
  );
}
export default SubContactInfo;