import React, { useContext, useState } from "react";
import "./TemplatComman.css";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import { useEffect } from "react";
import "./Template11.css";
import axios from "axios";
// import "./Template10.css";
const Template1 = (props) => {
  // console.log("Template1Template1Template1Template1Template1Template1Template1Template1Template1Template1Template1Template1");
  const [theme, setTheme] = useState();
  const { userData } = useContext(userContext);
  const [order, setOrder] = useState(null);
  const [backColor, setBackColor] = useState();
  const [businessId, setBusinessId] = useState(
    localStorage.getItem("business_id")
  );

  const countViewerBySlug = async () => {
    // console.log("wwwasd")
    const data = {
      business_id: businessId,
    };
    try {
      const response = await axios.put(
        `${environmentVariables?.apiUrl}api/v1/business/countViewerBySlug`,
        data
      );
      if (response.status === 200) {
        const get = response.data; 
        // console.log(get);
      } else {
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  }

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };
  useEffect(() => {
    setTheme(props.themeNumber);
  }, [props.themeNumber]);
  useEffect(() => {
    setTheme(props?.businessObj?.theme_color.split("-").pop());
    if (props.themeColor == "color1") {
      setBackColor("#F9D254");
    } else if (props.themeColor == "color2") {
      setBackColor("#8ECAE6");
    } else if (props.themeColor == "color3") {
      setBackColor("#F9D254");
    } else if (props.themeColor == "color4") {
      setBackColor("#FDF0D5");
    }
  }, [props.themeColor]);
  useEffect(() => {
    getBlockOrder();
    countViewerBySlug();
  }, [props?.businessObj, businessId, props.orderOfBlocks]);

  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "More") {
          orders["more"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Custom Html") {
          orders["custom_html"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        }
      });
      setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };
  const getJSXElement = (elemName) => {
    // console.log(props?.appointmentArray, "elemNameelemName");
    if (elemName == "appointment") {
      return (
        props?.onState?.appointment && (
          // <article class="contact sec">
          //   <h3 class="sectitle">Appoinment</h3>
          //   <select class="form-select" aria-label="Default select example">
          //     <option selected>Select time</option>
          //     {props?.appointmentArray?.map((val) => (
          //       <>
          //         <option value="1">
          //           {changeTimeFormat(val?.startDate)} -{" "}
          //           {changeTimeFormat(val?.endDate)}{" "}
          //         </option>
          //       </>
          //     ))}
          //   </select>
          // </article>
          <article className="sec makeappitmneets">
            <h3 class="sectitle">Make Appointment</h3>
            <div className="contact-form">
              <div class="col-12 mb-3">
                <input
                  type="date"
                  class="form-control clear_string"
                  placeholder="Date"
                  name="currentDate"
                  // value={selectedDate}
                  // onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
              <div class="col-12 mb-3">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  // onChange={(e) => setSelectedTime(e.target.value)}
                >
                  <option value="Select time">Select time</option>
                  {props?.appointmentArray?.map((val) => (
                    <option
                      key={val?.startDate}
                      value={`${changeTimeFormat(
                        val?.startDate
                      )} - ${changeTimeFormat(val?.endDate)}`}
                    >
                      {`${changeTimeFormat(
                        val?.startDate
                      )} - ${changeTimeFormat(val?.endDate)}`}
                    </option>
                  ))}
                </select>
              </div>
              <div class="col-12 mb-3">
                <button
                  className="btn btnmakeappitmnets"
                  data-bs-toggle="modal"
                  data-bs-target="#appotimnets"
                >
                  Make An Appointment
                </button>
              </div>
            </div>
          </article>
        )
      );
    } else if (elemName == "business_hour") {
      return (
        props?.onState?.businessHours && (
          <article className="businessHours sec">
            <h3 class="sectitle">Business Hours</h3>

            <ul className="businessTime">
              {props?.businessHourObject?.isMonday &&
                props?.businessHourObject?.modayStartDate &&
                props?.businessHourObject?.modayEndDate && (
                  <li>
                    <div className="day">Monday</div>
                    <div className="time">
                      {" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.modayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.modayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isTuesday &&
                props?.businessHourObject?.tuesdayStartDate &&
                props?.businessHourObject?.tuesdayEndDate && (
                  <li>
                    <div className="day">Tuesday</div>
                    <div className="time">
                      {" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.tuesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.tuesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isWednesDay &&
                props?.businessHourObject?.wednesdayStartDate &&
                props?.businessHourObject?.wednesdayEndDate && (
                  <li>
                    <div className="day">Wednesday</div>
                    <div className="time">
                      {" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.wednesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.wednesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isthrusdayDay &&
                props?.businessHourObject?.thrusdayStartDate &&
                props?.businessHourObject?.thrusdayEndDate && (
                  <li>
                    <div className="day">Thursday</div>
                    <div className="time">
                      {" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.thrusdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.thrusdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isfridayDay &&
                props?.businessHourObject?.fridayStartDate &&
                props?.businessHourObject?.fridayEndDate && (
                  <li>
                    <div className="day">Friday</div>
                    <div className="time">
                      {" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.fridayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.fridayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issaturdayDay &&
                props?.businessHourObject?.SaturdayStartDate &&
                props?.businessHourObject?.SaturdayEndDate && (
                  <li>
                    <div className="day">Saturday</div>
                    <div className="time">
                      {" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.SaturdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.SaturdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issundayDay &&
                props?.businessHourObject?.SundayStartDate &&
                props?.businessHourObject?.SundayEndDate && (
                  <li>
                    <div className="day">Sunday</div>
                    <div className="time">
                      {" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.SundayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {changeTimeFormat(
                          props?.businessHourObject?.SundayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
            </ul>
          </article>
        )
      );
    } else if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec">
            <h3 class="sectitle">Contact Info</h3>
            <ul class="conlink">
              <li>
                <a href="">
                  <i class="fa-solid fa-phone"></i>
                  <span>
                    {props?.contactInfo?.contactInfoPhone}
                  </span>
                </a>
              </li>

              <li>
                <a href="">
                  <i class="fa-solid fa-envelope"></i>
                  <span>
                    {props?.contactInfo?.contactInfoEmail}
                  </span>
                </a>
              </li>
              {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope"></i>
                  <span>

                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
              <li>
                <a href="">
                  <i class="fa-solid fa-map"></i>
                  <span>
                    {props?.contactInfo?.contactInfoAddress}
                  </span>
                </a>
              </li>
            </ul>
          </article>
        )
      );
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "description") {
      return (
        <article className="abouttemplate sec">
          <h3 class="sectitle">About</h3>
          <p>
            {props.description ||
              ` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`}
          </p>
        </article>
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && (
          <article className="servicesName sec">
            <h3 class="sectitle">Gallery </h3>
            <div className="servesbox">
              {props.existingGalleryImages
                ? props?.existingGalleryImages.map((val) => (
                    <img
                      src={
                        `${environmentVariables?.apiUrl}uploads/${val}`
                        // props?.gallaryObject?.gallaryStr &&
                        // `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallaryStr}`
                      }
                      className="mx-auto d-block img-fluid"
                    />
                  ))
                : props?.gallaryObject?.gallaryArray &&
                  props?.gallaryObject?.gallaryArray.map((val) => (
                    <img
                      src={
                        `${environmentVariables?.apiUrl}uploads/${val}`
                        // props?.gallaryObject?.gallaryStr &&
                        // `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallaryStr}`
                      }
                      className="mx-auto d-block img-fluid"
                    />
                  ))}
              {props.galleryView &&
                props.galleryView.map((val) => (
                  <img
                    src={
                      val
                      // props?.gallaryObject?.gallaryStr &&
                      // `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallaryStr}`
                    }
                    className="mx-auto d-block img-fluid"
                  />
                ))}
              {props?.gallaryObject?.gallary_video && (
                <video className="mx-auto d-block img-fluid" controls>
                  <source
                    src={
                      props?.gallaryObject?.gallary_video &&
                      `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallary_video}`
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </article>
        )
      );
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName == "service") {
      return (
        props?.onState?.services && (
          <article className="servicesName sec">
            <h3 class="sectitle">Services </h3>
            <div className="mainservicebox">
              <ul className="servesbox">
                {props?.servicesArray?.map((val) => (
                  <li> {val?.name}</li>
                ))}
              </ul>
            </div>
          </article>
        )
      );
    } else if (elemName == "social") {
      return (
        props?.onState?.socials && (
          <article className="social-links-sec sec">
            <h3 class="sectitle">Social Links</h3>
            <ul class="socil-link">
              {props?.socialMediaData?.map((val) => (
                <li>
                  <a href={val?.link}>
                    <i
                      class={`fa-brands fa-${
                        val?.nameOfSocial.toLowerCase() == "gmail"
                          ? "google"
                          : val?.nameOfSocial.toLowerCase()
                      }`}
                    ></i>
                  </a>
                </li>
              ))}
            </ul>
          </article>
        )
      );
    } else if (elemName == "testimonials") {
      return (
        props?.onState?.testimonials && (
          <article className="testimonial sec">
            <h3 class="sectitle">Testimonial </h3>

            <div className="mainboxclint">
              {props?.testimonialArray?.map((val) => (
                <div className="testimoniyalbox">
                  <img
                    src={
                      val?.imageView
                        ? val?.imageView
                        : `${environmentVariables?.apiUrl}uploads/${val?.image}`
                    }
                    className="mx-auto d-block img-fluid clint-img"
                  />
                  <p>
                    {val?.description != "undefined" ? val?.description : ""}
                  </p>
                  <div className="clintname">
                    <b>{val?.name != "undefined" ? val?.name : ""}</b>
                  </div>
                </div>
              ))}
            </div>
          </article>
        )
      );
    }
  };
  const getTemplateId = () => {
    if (theme == "theme1") {
      return "template3";
    } else if (theme == "theme2") {
      return "template4";
    } else if (theme == "theme3") {
      return "template5";
    } else if (theme == "theme4") {
      return "template6";
    } else if (theme == "theme5") {
      return "template7";
    } else if (theme == "theme6") {
      return "template8";
    }
  };
  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in order) {
      BlocksArray[order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };
  const changeTimeFormat = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };
  // console.log(props.gallaryObject, "gallary object inside template1");
  return (
    <div>
      <div className="templat1" id="template11">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 wtemplate L575">
              <article className="imgsechere sec">
                <img
                  src={
                    props?.bannerImageUrl
                      ? getImage(props.bannerImageUrl)
                      : null
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                />
                <img
                  src={
                    props?.logoImageUrl ? getImage(props?.logoImageUrl) : null
                  }
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>

              <article className="personalinfo sec">
                <h3 className="username">{props.title || "Name"}</h3>
                <p className="titleuser">
                  {props.designation || "Designation"}
                </p>
                <p className="companeyname">
                  {props.subTitle}
                </p>
              </article>

              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}

              <article className="conatctform sec">
                <h3 class="sectitle">Contact Us</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>

              <div className="fixeds">
                <article className="fixedfooter">
                  <div className="footerleft">
                    <div className="qrcodeshaere">
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                    {/* <div className="share">
                      <i class="fa-solid fa-arrow-up"></i>
                    </div> */}
                  </div>
                  <div className="addtocinatct">+Add To Conatct</div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template1;
