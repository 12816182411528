import React from "react";
import "./LandingFoolterStyle.css";

function Faqs() {
  return (
    <div>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <h1>Businessbay (FAQ's)</h1>
            </div>
          </div>
        </div>
      </section>

      <section class="tandcpage" id="faq_container">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12">
              <ol class="faqui" id="faqUi">
                <li>
                  {/* <h4>What Is A Digital Business Card?</h4> */}
                  <div className="boldfont">What is a Digital Business Card?</div>
                  <p>
                  It is a digital card for sending contact details electronically, replacing outdated visiting cards. It is a personalized, interactive digital card that simplifies networking for you.                  
                  </p>
                </li>

                <li>
                  {/* <h4>What Is A Businessbay Business Card?</h4> */}
                  <div className="boldfont">Who can use the BusinessBay card? </div>
                  <p>
                  It is designed for professionals who attend meetings, tradeshows, or seminars to quickly share their business details without the hassle of manual sharing.
                  </p>
                </li>
                <li>
                  {/* <h4>Tell Us How Businessbay Works?</h4> */}
                  <div className="boldfont">How Businessbay Works?</div>
                  <p>
                  You can start by taking a 15-day free trial that lets you create, design, and share the business information you want others to see with the help of powerful technologies like NFC.
                  </p>
                </li>
                <li>
                  {/* <h4>How Do I Share My Digital Business Card?</h4> */}
                  <div className="boldfont">How do you share your information through Digital Business Card?</div>
                  <p>
                  Sharing your information through digital business card with BusinessBay is simple; just tap your card on the back of a phone or access it through a QR code. However first, you need to sign up and create an account with us.
                  </p>
                </li>
                <li>
                  {/* <h4> How Many Digital Business Cards Can I Create?</h4> */}
                  <div className="boldfont">How many digital business cards can be created?</div>
                  <p>
                  With BusinessBay, you can create as many digital business cards as we need to suit various networking and business situations.
                  </p>
                </li>
                <li>
                  {/* <h4>Will My Digital Business Card Be Mobile Friendly?</h4> */}
                  <div className="boldfont">Will your digital business card be mobile friendly?</div>
                  <p>
                  Yes, BusinessBay ensures your digital business card be mobile-friendly, accessible and user-friendly on smartphones.
                  </p>
                </li>
                <li>
                  {/* <h4> Does Businessbay Require A Subscription?</h4> */}
                  <div className="boldfont">Does BusinessBay require a subscription?</div>
                  <p>
                  BusinessBay offers unparalleled deals on pricing at a mere 30 Rupees per month! If this isn't convincing enough, you get the card for free.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faqs;
