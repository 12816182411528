import React, { useState, useEffect, useCallback } from "react";
import closeImg from "./../../../../../src/Images/close.png";
import EmptyCart from "./../../../../../src/Images/emptycart.png";
import LoginSignup from "./LoginSignup";
import OtpPopup from "./OTPPopup";

const CartSummary = ({
  cartItems,
  closeDrawer,
  removeItem,
  openDrawer,
  closeCartSummaryDrawer,
  updatedState,
  setUpdatedState
}) => {
  const [itemQuantities, setItemQuantities] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [forwardedemail, setFormwadedEmail] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRegister, setIsModalOpenRegister] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);

  useEffect(() => {
    const initialQuantities = {};
    cartItems.forEach((item, index) => {
      initialQuantities[index] = item.quantity || 1;
    });
    setItemQuantities(initialQuantities);
  }, [cartItems]);

  const increaseQuantity = (index, data) => {
    setItemQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[index] || 1;
      
      // Check if the quantity is already at the maximum limit
      if (currentQuantity >= 50) {
        return prevQuantities; // Return previous state without changes
      }
  
      // Otherwise, increment the quantity
      return {
        ...prevQuantities,
        [index]: currentQuantity + 1,
      };
    });
  };
  

  const decreaseQuantity = (index, data) => {
    if (itemQuantities[index] > 0) {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [index]: prevQuantities[index] - 1,
      }));
    }
  };

  useEffect(() => {
    for (let el in itemQuantities) {
      let get = cartItems.map((elem, index) => {
        if (index == el) {
          elem.quantity = itemQuantities[el];
        }
        return el;
      });
    }
  }, [itemQuantities]);
  const handleRemoveItem = (index) => {
    removeItem(index);
    setItemQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[index];
      return updatedQuantities;
    });
  };

  const calculateTotalPrice = (price, quantity) => {
    return price * quantity;
  };

  const calculateCartTotal = () => {
    let total = 0;
    cartItems.forEach((item, index) => {
      total += calculateTotalPrice(item.price, itemQuantities[index] || 1);
    });
    return total;
  };

  const updateTotalAmount = (amount) => {
    setTotalAmount(amount);
  };

  useEffect(() => {
    updateTotalAmount(calculateCartTotal());
  }, [cartItems, itemQuantities]);
  const handleCheckout = () => {
    let getLocalStorageData = localStorage.getItem("Customer");
    if (getLocalStorageData) {
      closeCartSummaryDrawer();
      openDrawer();
    } else {
      setIsModalOpen(true);
      setIsModalOpenRegister(false);
    }
  };

  return (
    <div className="cart-summary">
      <div
        onClick={closeDrawer}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "15px",
          top: "15px",
        }}
      >
        {/* <img src={closeImg} /> */}
        <span style={{ fontSize: "24px", fontWeight: "500" }}> x</span>
      </div>

      <h3 className="theme53heading">Cart Summary</h3>
      {cartItems?.length <= 0 ? (
        <ul className="emptycartsummaryUl">
          <li className="emptycartsummary">
            <img src={EmptyCart}/>
            Your cart is currently empty! <span>Looks like you haven't made your choice yet...</span>{" "}
          </li>
        </ul>
      ) : (
        <ul className="cartitemsul">
          {cartItems.map((item, index) => (
            <li key={item.id} className="cart-item">
              <div className="theme53productimgcart">
                <img
                  src={item.image}
                  alt={item.name}
                  className="item-image"
                  width="100%"
                />
              </div>
              <div className="item-details">
                <p className="theme53name">{item.name}</p>
                <p className="theme53price">
                  {item?.currencySymbol}{calculateTotalPrice(item.price, itemQuantities[index] || 1).toFixed(2)}
                </p>
                <p className="theme53quantity">
                  <button
                    className="plusminus"
                    onClick={() => decreaseQuantity(index, item)}
                  >
                    -
                  </button>
                  {itemQuantities[index] || 1}
                  <button
                    className="plusminus"
                    onClick={() => increaseQuantity(index, item)}
                  >
                    +
                  </button>
                </p>
              </div>
              <button
                className="closered"
                onClick={() => handleRemoveItem(index)}
              >
                X
              </button>
            </li>
          ))}
        </ul>
      )}

      {/* <button onClick={closeDrawer}>Close</button> */}

      {cartItems?.length <= 0 ? (
      <button className="checkout53" onClick={closeDrawer}>
        Add Products
      </button>
      ) : (
        <button className="checkout53" onClick={() => handleCheckout()}>
        Checkout
      </button>
      )
    }
      {(isModalOpen || isModalOpenRegister) && (
        <LoginSignup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpenRegister={isModalOpenRegister}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          updatedState={updatedState}
          setUpdatedState={setUpdatedState}
          setFormwadedEmail={setFormwadedEmail}
        />
      )}
      {isOtpModalOpen && (
        <OtpPopup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpenRegister={isModalOpenRegister}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          forwardedemail={forwardedemail}
          setFormwadedEmail={setFormwadedEmail}
        />
      )}
    </div>
  );
};

export default CartSummary;
