import React from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/banner9.png";
import logoImage from "../../../../Images/logo9.png";
import testimonialImage from "../../../../Images/avatar.png";
import "./Theme9.css";
import { useState } from "react";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
const Theme9Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "2",
      image: "null",
    },
  ];

  const galleryArray = [
    {
      id: 50,
      business_id: 10,
      image: "1701327965708Rectangle12.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:06:05.000Z",
      updated_at: "2023-11-30T07:06:05.000Z",
    },
    {
      id: 51,
      business_id: 10,
      image: "1701328023372IMAGE3.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:07:03.000Z",
      updated_at: "2023-11-30T07:07:03.000Z",
    },
  ];

  return (
    <div>
      <div class="main-temaplt" id="temp9">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate p-0">
              {/* <div class="circle"></div>
              <div class="circle2"></div> */}
              <div className="top-section">
                <article className="imgsechere sec">
                  <img
                    src={bannerImage}
                    className="mx-auto d-block img-fluid coverimghere "
                  />
                </article>
                {/* <img
                src={bannerImage}
                className="mx-auto d-block img-fluid logo-profile"
              /> */}
                <article class="personalinfo sec">
                  <div class="innerinfo">
                    <h3 className="username">Jessica Felicio</h3>
                    <p className="titleuser">Makeup Expert, Lakme</p>
                  </div>
                  <div className="logo-parenta">
                    <img
                      src={logoImage}
                      className="mx-auto d-block img-fluid logo-profile"
                    />
                  </div>
                </article>
              </div>
              <article className="makeappitmneets sec">
                <div className="appointment">
                  <h3 class="sectitle">Make Appointment</h3>
                  <div className="contact-form">
                    <div class="col-12 mb-3">
                      <input
                        type="date"
                        class="form-control clear_string"
                        placeholder="Date"
                        name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                      >
                        <option value="Select time">Select time</option>
                        <option>{`2:00 PM - 5:00 PM`}</option>
                      </select>
                    </div>
                    <div class="col-12 mb-3">
                      <button
                        className="btn btnmakeappitmnets"
                        data-bs-toggle="modal"
                        data-bs-target="#appotimnets"
                      >
                        Make An Appointment
                      </button>
                    </div>
                  </div>
                </div>
              </article>
              <article className="servicesName sec" class="Service_name">
                <div className="services">
                  <h3 class="sectitle">Services </h3>
                  <div className="mainservicebox">
                    <ul className="servesbox">
                      <li>Massages</li>
                      <li>Body Scrub</li>
                      <li>Facial</li>
                      <li>Waxing</li>
                      <li>Pedicure</li>
                      <li>Head massage</li>
                    </ul>
                  </div>
                </div>
              </article>
              <article class="gallery sec sec-common">
                <div className="gallery-inner">
                  <h3 class="sectitle">Gallery </h3>
                  <GalleryCarousel slides={galleryArray} />
                </div>
              </article>
              {/* <article className="businessHours_sec">
                <h3 class="sectitle">Working Hours</h3>
                <ul class="timelist">
                  <li>
                    <div className="day">Monday to Sat</div>
                    <div className="time">
                      <span>9:00 AM</span> to <span>9:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Sunday Closed!</div> */}
              {/* <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div> */}
              {/* </li>
                </ul>
              </article> */}
              <article className="Conatctform_sec">
                <div className="contactform-sec-inner">
                  <h3 class="Contact_Form_sectitle">Contact Form</h3>
                  <div class="contact-form">
                    <form>
                      <div class="col-12 mb-3">
                        <input
                          type="text"
                          class="form-control clear_string"
                          placeholder="Full Name"
                          name="last_name"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Email"
                          name="email"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <input
                          type="text"
                          class="form-control clear_string"
                          maxlength="10"
                          minlength="10"
                          placeholder="Mobile Number"
                          name="mobile_number"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <textarea
                          class="form-control clear_string"
                          rows="3"
                          placeholder="Message*"
                          required=""
                          name="message"
                        ></textarea>
                      </div>

                      <div class="col-12 mb-3">
                        <button type="submit" class="btn btn-submitform">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </article>
              <article class="testimoniyalbox sec">
                <div className="testimonial-inner">
                  <h3 class="sectitle testimonial">Testimonial </h3>

                  <div>
                    <TestimonialCarousel
                      testimonials={testimonialArray}
                      currentElem={currentIndex}
                    />
                  </div>
                </div>
              </article>
              <article class="contact sec">
                <div className="contact-inner">
                  <h3 class="sectitle">Contact </h3>
                  <ul class="conlink">
                    <li>
                      <a href="" className="infomain">
                        <span className="icon-bg">
                          <i class="fa-solid fa-phone info_icons"></i>
                        </span>
                        <div className="content">
                          <span className="label">{`Mobile`}</span>
                          <span className="value">{`+91-987654321`}</span>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a href="" className="infomain">
                        <span className="icon-bg">
                          <i class="fa-solid fa-envelope info_icons"></i>
                        </span>
                        <div className="content">
                          <span className="label">{`Website`}</span>
                          <span className="value">{` christian@gmail.com`}</span>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a href="" className="infomain">
                        <span className="icon-bg">
                          <i class="fa fa-solid fa-location-dot info_icons"></i>
                        </span>
                        <div className="content">
                          <span className="label">{`Address`}</span>
                          <span className="value">
                            UAE- Dubai - Al meydan road - KML building - office 121
                          </span>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a href="" className="infomain">
                        <span className="icon-bg">
                          <i class="fa-solid fa-link info_icons"></i>
                        </span>
                        <div className="content">
                          <span className="label">{`Web url`}</span>
                          <span className="value">
                          businessbay.io
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </article>
              <article className="social-links-sec sec">
                <h3 class="sectitle">Social Media</h3>
                <ul class="socil-link">
                  <li>
                    <a>
                      <i class={`fa-brands fa-facebook`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-instagram`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-whatsapp`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-twitter`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-linkedin`}></i>
                    </a>
                  </li>
                </ul>
              </article>

              {/* <article className="abouttemplate sec">
                <h3 class="sectitle">About</h3>
                <p className="aboutus_paragraph">
                  {` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`}
                </p>
              </article> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme9Template;
