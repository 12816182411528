import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import QRCode from "qrcode.react";
import Charttb from "./Charttb";
import Charttc from "./Charttc";
import Chartte from "./Chartte";
import Charttf from "./Charttf";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Updatedcss/UpdatedDashboardstyle.css";
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment/moment";

function InfluencerDash() {
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState(null);
  const [data, setData] = useState([]);
  const [planExpireDays, setPlanExpireDays] = useState(0);
  const [showPlanExpireBanner, setShowPlanExpireBanner] = useState(false);
  const { userData } = useContext(userContext);
  const [open, setOpen] = useState(false);
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const httpProtocol = environmentVariables?.httpProtocol;
  const fronturl = environmentVariables?.frntUrl;
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  let busiObj = localStorage.getItem("businessObj");
  const [appointments, setAppointments] = useState([]);
  // console.log(appointments,"appointments!@#$")
  const [appData, setAppData] = useState([]);
  const [contData, setContData] = useState([]);
  const [chartsRs, setChartsRs] = useState([]);
  const [infBusinessLink, setInfBusinessLink] = useState("");
  const [businessLink, setBusinessLink] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [sevdays, setSevdays] = useState({});
  const [busData, setBusData] = useState({});
  const [totalDeliveredOrders, setTotalDeliveredOrders] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPendingOrders, setTotalPendingOrders] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [totalEarning, setTotalEarning] = useState(0);
  const [withdrawn, setWithdrawn] = useState(0);
  const [totalClaimable, setCotalClaimable] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [claimAmount, setClaimAmount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [influencerData, setInfluencerData] = useState(null);
  const tableExtensions = { export: false, print: false };



  console.log(userData, "userDatauserDatauserData")
  const [graphData, setGraphData] = useState({
    transformedData: [],
    gData2: [],
  });
  const [GreatingMsg, setGreatingMsg] = useState("");
  async function getGreatingMsg() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "Web",
            name: ["Greeting"],
          },
        }
      );
      if (response.status === 200) {
        setGreatingMsg(response?.data?.result?.Greeting);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }
  const baseUrl = environmentVariables?.apiUrl;
  const getGraphData = () => {
    axios
      .get(`${baseUrl}api/v1/business/fetchGraphData`, {
        params: { UserId: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          // console.log(res?.data,"res?.datares?.data")
          setSevdays(res?.data);
          setBusData(res?.data?.totalBusiness?.rs)
          if (res?.data?.resultA?.sum_of_appointments) {
            setAppData(res.data.resultA.sum_of_appointments);
          }
          if (res?.data?.resultC?.sum_of_contacts) {
            setContData(res.data.resultC.sum_of_contacts);
          }
          if (res?.data?.resultViewr) {
            setChartsRs(res.data?.resultViewr);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };
  const getInfluenderDetails = () =>{
    axios.get(`${apiUrl}api/v1/influencer/getInfluencerById?id=${userData?.id}`)
    .then((response)=>{
      setInfluencerData(response?.data?.result);
      const businessData = response?.data?.result?.businesses;
      const slugb = businessData?.slug;
      const slug_subdomain = businessData?.slug_subdomain
      let bsLink = (slug_subdomain != null)? httpProtocol +slug_subdomain:(slugb !=null)? fronturl+ slugb:"/";
      setInfBusinessLink(bsLink);
    }).catch((err)=>{
      console.log(err);
    })
  }
  
  useEffect(()=>{
    getInfluenderDetails();
  },[])
  const getInfluencerDashboardData = () => {
    axios
      .get(`${baseUrl}api/v1/influencer/getInfluencerOrderSummaryByInfluencerId`, {
        params: { id: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          console.log("results=====>>>>", res?.data?.result);
          setTotalDeliveredOrders(res?.data?.result?.totalDeliveredOrders);
          setTotalOrders(res?.data?.result?.totalOrders);
          setTotalPendingOrders(res?.data?.result?.totalPendingOrders);
          setTotalSales(res?.data?.result?.totalSales);
          setTotalEarning(res?.data?.result?.totalEarning);
          setTotalEarning(res?.data?.result?.totalEarning);
          setCotalClaimable(res?.data?.result?.claimableAmount);
          setCurrencySymbol(res?.data?.result?.currencyDataObj?.symbol);
          setPercentage(res?.data?.result?.percentage);
          setWithdrawn(res?.data?.result?.totalPaidAmount);

        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  let data1 = graphData?.transformedData;
  let data2 = [["Language", "Speakers (in millions)"]];
  data2.push(...graphData.gData2);
  const setBusinessDataLink = (buzzData) => {
    const businessData = JSON.parse(buzzData);
    let redirectUrl = (businessData?.slug_subdomain != null) ? httpProtocol + businessData?.slug_subdomain : (businessData?.slug != null) ? fronturl + businessData?.slug : "/";
    setBusinessLink(`${redirectUrl}`);
    setBusinessName(businessData?.title);
  }
  useEffect(() => {
    // getGraphData();
    getInfluencerDashboardData();
    getGreatingMsg();
    if (storedData != "undefined" && storedData != null) {
      setBusinessDataLink(storedData);
    } else {
      setBusinessDataLink(busiObj);
    }
  }, [storedData, busiObj]);

  const handleCopyClick = (infBusinessLink) => {
    navigator.clipboard
      .writeText(infBusinessLink)
      .then((res) => {
        // setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info("Link Copy on Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const apiUrl = environmentVariables?.apiUrl;

  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getDashboardData`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCardsData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status) {
          localStorage.clear();
          window.location.href = "/";
          window.location.reload();
        }
      });
  }, []);

  const fetchApppointent = (page = 1) => {
    // console.log("in fetch appoitment")
    try {
      let obj = {
        business_id: businessObjData?.id,
      };
      axios
        .post(`${baseUrl}api/v1/appointment/FetchAppointments_withpagination?page=${page}&limit=${rowsPerPage}&showcount=${userData?.appointments}`, { ...obj }, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          let arr = response?.data?.data?.records;
          arr = arr.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          // console.log(arr,"@#$arr")
          setAppointments(arr);
        })
        .catch((error) => {
        });
    } catch (err) {
      console.log(err, "erroror");
    }
  };


  useEffect(() => {
    fetchApppointent();
  }, [rowsPerPage]);



  const fetchClaimrequest = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/influencer/getAllClaimRequestByInfluencerId?id=${userData?.id}`)
        .then((response) => {
          console.log(response, "Asdasdaksjdhksajhd");
          setData(response?.data?.result);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) { }
  };

  const handleClickOpen = () => {
    ((totalClaimable - withdrawn) === 0 || !!data && data?.length>0 && data[0]?.payment_status == 'pending' || (totalClaimable - withdrawn) < 2000 )? setOpen(false) :
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchClaimrequest();
  }, [])

  // const CalendarComponent = ({ appointments }) => {
  // Transform appointment data into the format expected by FullCalendar
  const events = appointments.map(appointment => ({
    title: appointment.businessTitle, // Use businessTitle as the event title
    start: appointment.date, // Use date as the event start time
    // You can optionally include other properties such as 'end' here if needed
    extendedProps: {
      // Pass the entire appointment object as extendedProps
      appointment,
    },

  }));

  const eventContent = ({ event }) => {
    const { businessTitle, time, name, } = event.extendedProps.appointment;
    return (
      <div style={{ padding: "5px 0" }}>
        <p style={{ fontSize: "14px", fontWeight: "bold" }}>{businessTitle}</p>
        <p style={{ fontSize: "13px" }}> {time}</p>
        <p style={{ fontSize: "13px" }}>{name}</p>
      </div>
    );
  };

  const downloadQR = () => {
    const canvas = document.getElementById("businessLinkQr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = businessName + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    getExpirationDetails();
  }, []);

  const getExpirationDetails = () => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getPlanExpirationDetails`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.success) {
          setPlanExpireDays(response?.data?.planExpiryDays);
          setShowPlanExpireBanner(response?.data?.allowToShowExpiryBanner);
        }
      })
      .catch((error) => {
        console.log("error====>>>", error.message);

      });
  }


  const ClaimHandler = () => {
    let data = {
      influencer_id: userData.id,
      requested_amount: String(totalClaimable - withdrawn),
      requested_currency:currencySymbol
    }
    axios.post(`${baseUrl}api/v1/influencer/createClaimRequest`, data)
      .then((res) => {
        console.log(res?.data?.result, "asjdhkaksjdhakjsdhdakjsdh")
        setClaimAmount(res?.data?.result);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchClaimrequest();
        handleClose();
      }).catch((error) => {
        toast.error(error.response?.data?.message || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.log(error.response?.data?.message, "asdasdasd")
        handleClose();
      })
  }

  const columns = [
    {
      name: "Payment Request",
      selector: (row) => {        
        const date = new Date(row.created_at);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleString('en-US', options);
      },
      sortable: true,
    },
    {
      name: "Requested Amount",
      selector: (row) => currencySymbol + row.requested_amount || "--",
      sortable: true,
    },
    {
      name: "Received Amount",
      selector: (row) => row.paid_amount ? currencySymbol + row.paid_amount : "--",
      sortable: true,
    },    
    {
      name: "Receive Date",
      selector: (row) => {
        const date = new Date(row.payment_date);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };

        // Check if the date is invalid or if it's 1970
        if (isNaN(date) || date.getFullYear() === 1970) {
          return '--';
        }

        return date.toLocaleString('en-US', options);
      },
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => row.payment_status,
      sortable: true,
    },
    {
      name: "Payment ID",
      selector: (row) => row.payment_id || "--",
      sortable: true,
    },
    {
      name: "Remarks",
      selector: (row) => row.payment_remarks || "--",
      sortable: true,
    },

  ];

  const customStyles = {

    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",

      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize: "15px",

      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };

  const tableData = {
    columns,
    data,
  };
  

  return (
    <>
      <PageName PageName="Influencer Dashboard" />
      <div className="row Greeting_total_wrapper">
        <div className="col-md-5" id="grettingWrapper">
          {!!influencerData &&
          <div className="welcom ">
            <div class="influencersdetails-topdetails">
              <h3><span>Influencer Name :</span> {influencerData?.name}</h3>
              <h3><span>Business Name :</span> {influencerData?.businesses?.title}</h3>
              <h3><span>Business Link :</span> {infBusinessLink}  &nbsp;&nbsp;<span style={{cursor:"pointer"}}  onClick={()=>handleCopyClick(infBusinessLink)}><i className="fa-solid fa-copy"></i></span></h3>
            </div>
          </div>
          }
        </div>
        <div className="col-md-7" id="total_wrapper_container"></div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              <div className="tile bbox Total_Titlebox_container">
                <i className="fa-solid fa-business-time"></i>
                <h3>Total Orders</h3>
                <h4>{totalOrders || 0}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tile bbox Total_Titlebox_container">
                <i className=" fa-regular fa-handshake"></i>
                <h3>Total Pending Orders</h3>
                <h4>{totalPendingOrders || 0}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Delivered Orders</h3>
                <h4>{totalDeliveredOrders || 0}</h4>
              </div>
            </div>

            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Sales</h3>
                <h4>{currencySymbol}{totalSales || 0}</h4>
              </div>
            </div>

            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Earning ({percentage}% of Total Sales)</h3>
                <h4>{currencySymbol}{totalEarning?.toFixed(2) || 0}</h4>
                {/* <p>25% of Total Sales</p> */}
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Claimable Amount</h3>
                <h4>{currencySymbol}{totalClaimable || 0}</h4>
              </div>
            </div>       */}


            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Claimable Amount</h3>
                {/* <h4>{currencySymbol}{influencerData?.totalClaimable || 0}</h4> */}
                <div className="Remainingclaimableamtdiv">
                  <h4 className="Remainingclaimableamt">
                    {currencySymbol}{(!!totalClaimable && totalClaimable>0)?(totalClaimable - withdrawn)?.toFixed(2):0} 
                    <span className={((totalClaimable - withdrawn) === 0 || !!data && data?.length>0 && data[0]?.payment_status == 'pending' ||  (totalClaimable - withdrawn) < 2000 )?"disableclaimbtnspan":"claimbtnspan"} 
                    
                    onClick={handleClickOpen}
                    >
                      <button className="claimbtn"> Claim </button>
                    </span>
                  </h4>
                  {/* {console.log(data[0] , "finddd")} */}
                  {!!data && data?.length>0 && data[0]?.payment_status == 'pending'?<div className="lastrequest">Your last request of <span style={{fontWeight:"700"}}>{currencySymbol} {data[0]?.requested_amount}</span> is under process</div>:""}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Withdrawn Amount</h3>
                <h4>{currencySymbol}{withdrawn?.toFixed(2) || 0}</h4>
              </div>
            </div>
          </div>



          <div className="main inf_dash">
            <h3 className="payin-payout-request-heading">Claim Request List</h3>
            {isLoading ? (
              <CircularLoader size={40} />
            ) : (
              <DataTableExtensions
                {...tableData}
                filterPlaceholder="Search Coupon"
                customStyles={customStyles}
                {...tableExtensions}
                className="extensionscls"
              >
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  sortIcon={<SortIcon />}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  dense
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            )}
          </div>


            
              
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  >
                  <div className="claimablepopbox">
                    <h3>Confirm Payout Request</h3>
                    <div className="requestpopbox"> 
                      <h4>Total Claimable Amount </h4>
                      <h5>{currencySymbol}{(!!totalClaimable && totalClaimable>0)?(totalClaimable - withdrawn).toFixed(2):0}</h5>
                    </div>
                    <div className="requestpopbox"> 
                      <h4>Total Earning ({percentage}% of Total Sales)</h4>
                      <h5>{currencySymbol}{totalEarning?.toFixed(2) || 0}</h5>
                    </div>
                    <div className="requestpayout" onClick={ClaimHandler} autoFocus>
                      Request for Pay-out
                    </div>
                    <div className="requestpayoutcancel" onClick={handleClose}>Cancel
                    </div>
                  </div>
                </Dialog>
            






        </div>
      </div>
    </>
  );
}
export default InfluencerDash;
//1112