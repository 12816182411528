import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <div className="contact-inner">
          <h3 class="sectitle contactinfo">Contact us</h3>

          <div class="conlink">
            <div className="contact-us-grid">
              {ff?.contactInfoPhone && 
              <div className="contact-info-container">
                <div className="contact-info-details">
                  <div className="contact-info-icon">
                    <span className="icon-bg">
                      <i className="fa fa-solid fa-phone"></i>
                    </span>
                  </div>
                  {/* <p className="contact-info-label">Phone</p> */}
                  <div>
                  <a
                    className="contactinfolink content"
                    href={
                      ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""
                    }
                  >
                    {/* <span className="label">{`Phone`}</span> */}
                    <span className="contactinfotext value">
                      {ff?.contactInfoPhone || ``}
                    </span>
                  </a>
                  {ff.optionalContactInfoPhone?.split("-")?.[1]?.length > 0 &&
                <a className="contactinfolink"
                  href={ ff?.optionalContactInfoPhone ? `tel:${ff?.optionalContactInfoPhone}` : ""  }
                >
                  <span className="contactinfotext">
                    {ff?.optionalContactInfoPhone || ``}
                  </span>
                </a>
                }
                </div>
                </div>
              </div>
              }
              <div className="vertical-line"></div>

              <div className="vertical-line"></div>
              {ff?.contactInfoEmail && 
              <div className="contact-info-container">
                <div className="contact-info-details">
                  <div className="contact-info-icon">
                    <span className="icon-bg">
                      <i className="fa fa-solid fa-envelope"></i>
                    </span>
                  </div>
                  {/* <p className="contact-info-label">Email</p> */}
                  <a
                  className="contactinfolink content"
                    href={
                      ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
                    }
                  >
                    {/* <span className="label">{`Website`}</span> */}
                    <span className="contactinfotext mailurllong value">
                      {ff?.contactInfoEmail}
                    </span>
                  </a>
                </div>
              </div>
            }
            {ff?.contactInfoAddress && 
              <div className="contact-info-container">
                <div className="contact-info-details">
                  <div className="contact-info-icon">
                    <span className="icon-bg">
                      <i className="fa fa-solid fa-location-dot"></i>
                    </span>
                  </div>
                  {/* <p className="contact-info-label">Address</p> */}
                  <div>
                  <a className="contactinfolink content">
                  {/* <span className="label">{`Address`}</span> */}
                    <span className="contactinfotext value">
                    {ff?.contactInfoAddress?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
                    </span>
                  </a>
                  {ff?.contactInfoOptAddress &&
                  <a>
              <span style={{
                    textTransform: "uppercase",
                    color: "#f9a0be",
                    fontSize: "12px",
                    fontFamily: 'Jost',
                    fontWeight: 300,
                    textAlign: "start"
              }}>{`Alternative Address`}</span> 

                  <span className="contactinfotext" style={{textAlign: "start"}}>
                  {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
                  </span>
                </a>
}
                </div>
                </div>
              </div>
}

            {ff?.contactInfoWebUrl && 
              <div className="contact-info-container">
                <div className="contact-info-details">
                  <div className="contact-info-icon">
                    <span className="icon-bg">
                      <i className="fa-solid fa-link info_icons"></i>
                    </span>
                  </div>
                  {/* <p className="contact-info-label">Address</p> */}
                  {/* <a  href={ff?.contactInfoWebUrl} target="_blank" > */}
                  {/* <a href={(ff?.contactInfoWebUrl)?`http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}`:''} target="_blank" className="contactinfolink content"> */}
                  {/* <span className="label">{`Address`}</span> */}
                  <a
              href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!ff?.contactInfoWebUrl) {
                  e.preventDefault(); // Prevents the default link behavior
                }
              }}
              target={ff?.contactInfoWebUrl ? '_blank' : undefined}
              className="contactinfolink content"
            >  <span className="contactinfotext mailurllong value">
                      {ff?.contactInfoWebUrl}
                    </span>
                  </a>
                </div>
              </div>
              }
            </div>
            {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope"></i>
                  <span>
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
          </div>
        </div>
      </article>
    </>
  );
}
export default SubContactInfo;
