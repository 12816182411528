import React, { useRef } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/banner1.jpg";
import logoImage from "../../../../Images/temp45-logo.jpg";
import testimonialImage from "../../../../Images/avatar.png";
import "./Theme45.css";
import { useState } from "react";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import CalanderIconn from "../../../../Images/calendarIcondays.png";

const Theme45Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "Incredible teacher! They made complex topics easy to understand and always encouraged us!",

      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:"Thank you for your patience and support! I've learned so much and gained confidence!",

      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:"A fantastic educator! Their passion for teaching inspires us to strive for excellence!",

      formCount: "2",
      image: "null",
    },
  ];

  const galleryArray = [
    {
      id: 50,
      business_id: 10,
      image: "1701327965708Rectangle12.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:06:05.000Z",
      updated_at: "2023-11-30T07:06:05.000Z",
    },
    {
      id: 51,
      business_id: 10,
      image: "1701328023372IMAGE3.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:07:03.000Z",
      updated_at: "2023-11-30T07:07:03.000Z",
    },
  ];

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };

  return (
    <div>
      <div class="main-temaplt" id="temp45">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate p-0">
              <div class="circle"></div>
              <div class="circle2"></div>
              <article class="personalinfo sec">
                <img
                  src={logoImage}
                  className="mx-auto d-block img-fluid logo-profile"
                />

                <div class="innerinfo">
                  <h3 className="username">Jenny Wilson</h3>
                  <p className="titleuser">Designer Head</p>
                </div>
              </article>

              <article className="abouttemplate sec">
                <h3 class="sectitle">About me</h3>
                <p className="aboutus_paragraph">
                  {`we empower students to excel through engaging programs and innovative teaching. Our dedicated educators foster a love for learning, ensuring every student reaches their full potential.`}
                </p>
              </article>
              <article className="social-links-sec sec">
                <h3 class="sectitle">Social Links</h3>
                <ul class="socil-link">
                  <li>
                    <a>
                      <i class={`fa-brands fa-facebook`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-google`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-linkedin`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-instagram`}></i>
                    </a>
                  </li>
                </ul>
              </article>
              <article class="contact sec">
                <h3 class="sectitle">Contact Info</h3>
                <ul class="conlink">
                  <li>
                    <a href="" className="infomain">
                      <i class="fa-solid fa-phone info_icons"></i>
                      <span>{`+91-987654321`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="" className="infomain">
                      <i class="fa-solid fa-envelope info_icons"></i>
                      <span>{` durgesh@gmail.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="" className="infomain">
                      {/* <i class="fa-solid fa-map info_icons"></i> */}
                      <i
                        style={{ padding: "0 5px" }}
                        class="fa-solid fa-link"
                      ></i>
                      <span>www.durgeshclasses.io</span>
                    </a>
                  </li>

                  <li>
                    <a href="" className="infomain">
                      {/* <i class="fa-solid fa-map info_icons"></i> */}
                      <i
                        style={{ padding: "0 5px" }}
                        class="fa-solid fa-location-dot"
                      ></i>
                      <span>UAE- Dubai - Al meydan road</span>
                    </a>
                  </li>
                </ul>
              </article>
              <article className="servicesName sec" class="Service_name">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Career Counseling</li>
                    <li>Test Preparation</li>
                    <li>Online Courses</li>
                    <li>Mentorship Programs</li>
                    <li>Research Opportunities</li>
                  </ul>
                </div>
              </article>
              <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article>
              <article className="Conatctform_sec">
                <h3 class="Contact_Form_sectitle">Contact</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
              <article className="businessHours_sec">
                <h3 class="sectitle">Working Hours</h3>
                <ul class="timelist">
                  <li>
                    <div className="day">Monday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article>
              <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{ position: "relative" }}>
                    <input
                      type="date"
                      ref={calendarInputRef}
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                    <div className="calanderIcon" onClick={openCalendar}>
                      <img src={CalanderIconn} alt="Calendar Icon" />
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article>
              <article class="gallery sec sec-common">
                <h3 class="sectitle">Gallery </h3>
                <GalleryCarousel slides={galleryArray} />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme45Template;
