import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { userContext } from "../../../context/userContext";

import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";

function ThemTemplate({
  selectedTheme,
  setSelectedTheme,
  setSelectThemeColor,
  setThemeColor1,
  setThemeNumber,
  isNewTheme,
  businessName,
  setBusinessName,
  seprationCategory,
  setSeprationCategory,
  isChecking,
  businessTheme,
}) {
  const [activeTab, setActiveTab] = useState({
    tab1: 1,
    tab2: 1,
    tab3: 1,
    tab4: 1,
    tab5: 1,
    tab6: 1,
    tab7: 1,
    tab8: 1,
    tab9: 1,
    tab10: 1,
    tab11: 1,
    tab14: 1,
    tab12: 1,
    tab16: 1,
    tab17: 1,
    tab15: 1,
    tab18: 1,
    tab20: 1,
    tab22: 1,
    tab27: 1,
    tab28: 1,
    tab29: 1,
    tab30: 1,
    tab31: 1,
    tab32: 1,
    tab33: 1,
    tab34: 1,
    tab35: 1,
    tab36: 1,
    tab37: 1,
    tab38: 1,
    tab39: 1,
    tab40: 1,
    tab41: 1,
    tab42: 1,
    tab43: 1,
    tab44: 1,
    tab45: 1,
    tab46: 1,
    tab47: 1,
    tab48: 1,
    tab49: 1,
    tab50: 1,
    tab51: 1,
    tab52: 1,
    tab53: 1,
    tab54: 1,
    tab55: 1,
    tab56: 1,
  });

  const [selectedCategory, setSelectedCategory] = useState("");
  // const [busineeCategory, setBusinessCategory] = useState("")
  const [chooseCategory1, setChooseCategory1] = useState([])
  const { userData, setUserData } = useContext(userContext);
  // const [businessName, setBusinessName] = useState("");
  const [selectedDiv, setSelectedDiv] = useState("div1");
  const [themeColor, setThemeColor] = useState("color1");
  const storedData = localStorage.getItem("businessObj");
  const uatapi = process.env.REACT_APP_API_BACKEND;
  // console.log(uatapi,"uatapiuatapiuatapi")
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  useEffect(() => {
    let themeNumber = 1;
    //if(isChecking==null){
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const theme_color = businessData?.theme_color;
      let thN = parseInt(theme_color?.replace("theme", ""), 10);
      themeNumber = isNaN(thN) || thN === 0 ? 1 : thN;
    }
    //}
   // setSelectedDiv("div" + themeNumber);
    //handleDivClick("div" + themeNumber);
  }, [businessObjData]);

  const handleChangeBusinessName = (e) => {
    const alphanumericWithSpaceRegex = /^[a-zA-Z0-9\s']+$/;
    if (
      e.target.value === "" ||
      alphanumericWithSpaceRegex.test(e.target.value)
    ) {
      setBusinessName(e.target.value);
    }
  };
  const handleTabClick = (item, tab, color) => {
    // setActiveTab(tab)
    setActiveTab({
      ...activeTab,
      [`tab${item}`]: tab,
    });
    // console.log(activeTab, color);
    // console.log("VVVVVV", item, tab, "cole", color, activeTab);
    setThemeColor(color);
    setThemeNumber(`theme${item}`);
    setSelectThemeColor(color);
    setSeprationCategory(seprationCategory)
    // if (!isNewTheme) {
    //   setThemeColor1(color);
    //   setThemeNumber(`theme${item}`);
    // }
  };



  const handleDivClick = (divId) => {
    if(!!seprationCategory){
      setSelectedTheme("theme" + divId?.toString().substring(3));
      setSelectedDiv(divId);
      if (!isNewTheme) {
        setThemeNumber(`theme${divId?.toString().substring(3)}`);
      }
    }
    // else{
    //   toast.error('Please select category', {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    // }
  };

 

  const handleFetchTheme = async () => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}api/v1/business/fetchTheme`,
        {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        }
      );
      // console.log(res, "Res themem ");
      // toast.error("Theme is mandatory", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

useEffect(()=>{
  const fetchbusinesscategories = async () => {
    try {
      const { data } = await axios.get(`${uatapi}api/v1/business/fetch_business_category`);
     
      setChooseCategory1(data.data) // Handle success
    } catch (error) {
      console.log(error); // Log error for debugging
      toast.error("Failed to fetch categories", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  fetchbusinesscategories();
},[])


// console.log(selectedTheme,"datadatadata"); 

  // const data = [2, 3, 5, 4, 6, 1, 7, 9, 12];
  // const data = [2, 3, 5, 4, 6, 1, 7, 9, 12, 14, 17];

  
  // let data = [2, 3, 5, 4, 6, 1, 7, 9, 11,12, 14, 15, 16, 17, 18, 20,22];
  // const data =
  // seprationCategory !== "cab" || seprationCategory !== "restaurant" || seprationCategory !== "mini_marketplace" || seprationCategory !== "resturent"?
  //     [2, 3, 5, 4, 6, 1, 7, 9, 11, 15, 16, 17, 18]
  //   : [2, 3, 5, 4, 6, 1, 7, 9, 11, 12, 14, 15, 16, 17, 18, 20, 22];


  const getAvailableThemes = () => {
    if (seprationCategory === "cab" || seprationCategory === "restaurant" || seprationCategory === "mini_marketplace" || seprationCategory == "Googlereview") {
      // return [2, 3, 5, 4, 6, 1, 7, 9, 11, 12, 14, 15, 16, 17, 18, 20, 22,27, 28, 29, 30, 31, 32,33, 34, 35, 36, 37, 38, 39, 40,41,42, 43, 44, 45, 46,47 ,48, 49,50,51, 52,53, 54, 55, 56];
      return [2, 3, 5, 4, 6, 1, 7, 9, 11, 12, 14, 15, 16, 17, 18, 20, 22,27, 28, 29, 30, 31, 32,33, 34, 35, 36, 37, 38, 39, 40,41,42, 43, 44, 45, 46,47 ,48, 49,50,51, 52,53];
    }
    // return [2, 3, 5, 4, 6, 1, 7, 9, 11, 15, 16, 17, 18, 27, 28, 29, 30, 31, 32,33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,47,48,49,50,51,52,53, 54, 55, 56];
    return [2, 3, 5, 4, 6, 1, 7, 9, 11, 15, 16, 17, 18, 27, 28, 29, 30, 31, 32,33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,47,48,49,50,51,52,53];
  };

  const data = getAvailableThemes();
  const filteredData = data.filter((item) => {
    if (userData?.user_type !== "super_admin" && item === 53) {
      return false; // Exclude item with ID 53
    }
    if (!seprationCategory) {   
      // If no category is selected, include all items
      return true;
    } else if (
      seprationCategory === "salon" &&
      (item === 7 || item === 8 || item === 9)
    ) {
      return true;
    } else if (seprationCategory === "construction" && (item === 1 || item === 47)) {
      return true;
    } else if (seprationCategory === "doctor" && ( item === 18 || item == 4)) { // item ==4 
      return true;
    } else if (seprationCategory === "art" && (item === 3 || item === 46)) {
      return true;
    } else if (
      seprationCategory === "influencer" &&
      (item === 5 || item === 2 || (userData?.user_type == "super_admin" && item === 53 ))
    ) {
      return true;
    } else if (seprationCategory === "Googlereview" && item === 12 
    ) {
      return true;
    } else if (
      seprationCategory === "restaurant" && (item === 14)
    ) {
      return true;
    } else if (seprationCategory === "attorney" && (item === 17 || item === 38)){
      return true;
    } else if (seprationCategory === "journalist" && (item === 16 || item === 35)
      // (|| item === 27 || item === 35)
    ){
      return true;
    }else if (seprationCategory === "mini_marketplace" && (item === 20)
    ){
      return true;
    } else if (seprationCategory === "chef" && (item === 15 || item === 41)
    ){
      return true;
    } else if (seprationCategory === "cab" && (item === 22)
    ){
      return true;
    }  else if (seprationCategory === "gym" && (item === 6 || item === 43)
    ){
      return true;
    }  else if (seprationCategory === "event_organizer" && (item === 11 || item === 44)
    ){
      return true;
    }  else if (seprationCategory === "repair" && (item === 37  || item === 40 ) 
      // || item === 53
    ){
      return true;
    }  else if (seprationCategory === "house_keeping" && (item === 28 || item === 39)
    ){
      return true;
    } else if (seprationCategory === "home_decor" && (item === 29 || item === 42)
    ){
      return true;
    }  else if (seprationCategory === "education" && (item === 36 || item === 45) // item === 2 
    ){
      return true;
    } else if (seprationCategory === "contractors" && (item === 31 || item === 48)
    ){
      return true;
    } else if (seprationCategory === "driving_schools" && (item === 32 || item === 50)
    ){
      return true;
    } else if (seprationCategory === "it" && (item === 30 || item === 51)
    ){
      return true;
    }  else if (seprationCategory === "printing" && (item === 33 || item === 52)
    ){
      return true;
    }  else if (seprationCategory === "pet_shop" && (item === 34 || item === 49) 
    ){
      return true;
    }  else if (seprationCategory === "tour" && (item === 54) 
    ){
      return true;
    }  else if (seprationCategory === "photography" && (item === 55) 
    ){
      return true;
    }  else if (seprationCategory === "loan_finance" && (item === 56) 
    ){
      return true;
    } 
    return false;
  });
// "printing"
  // console.log(selectedCategory,"1111111selectedCategory")
  const isCategoryDisabled = (category) => {
    return category === "cab" || category === "restaurant" || category === "mini_marketplace" || category === "Googlereview";
  };
useEffect(() => {
  if (isCategoryDisabled(seprationCategory)) {
    setSelectedCategory(seprationCategory); // Set selected category when disabled
  }else {
    setSelectedCategory();
  }
}, [seprationCategory]);

useEffect(() => {
  if (filteredData && filteredData.length > 0) { 
    const firstTheme = filteredData[0];
    handleDivClick(`div${firstTheme}`); 
  }
}, [seprationCategory]);

  return (
    <>
      {isChecking == null ? (
        <div class="modal-body">
          <div 
          style={{
            fontSize: "10px",
            padding: "0px 15px",
            color: "red",
            height:'40px'
          }}
          >This name will be used as a subdomain, so please enter the correct business name. A maximum of 63 characters is allowed.*</div>
          
          <div className="inputwithfilter">
            <input
              type="text"
              className="form-control mb-2 businessinputname businessinputnameres"
              value={businessName}
              placeholder="Enter your Business Name*"
              onChange={(e) => handleChangeBusinessName(e)}
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                margin: "5px 15px 0 15px",
                marginBottom:"0px"

              }}
            ></input>
            {isChecking == null ? (
            <div class="category-model editcategoryresp">
              <select
                className="form-select category_item"
                onChange={(e) => setSeprationCategory(e.target.value)}
                // onChange={(e) => {
                //   if (isCategoryDisabled(seprationCategory) == "true") {
                //     setSeprationCategory(e.target.value);
                //     setSelectedCategory(e.target.value)
                //   } else {
                //     setSeprationCategory(e.target.value);
                
                //   }
                // }}
                
              >
                <option value="">Select Category*</option>
                {chooseCategory1?.map((catname)=>(
                   <option  key={catname.id} value={catname.cat_slug}>{catname?.cat_name} </option>

                ))}
                 {/* <option value="mini_marketplace"> Mini Marketplace</option> */}
                {/* <option value="Painting_Contractors"> Painting Contractors</option>
                <option value="Jewellery_Showrooms"> Jewellery Showrooms</option>
                <option value="Furniture_Repair_Services"> Furniture Repair Services</option>
                <option value="Printing_Publishing_Services"> Printing & Publishing Services</option>
                <option value="Wedding_Requisites"> Wedding Requisites</option>
                <option value="Courier_Service">Courier Service</option>
                <option value="Event_Organisers">Event Organisers</option> */}
              </select>
              {/* {console.log(isCategoryDisabled(seprationCategory),"asdasdasdasd")} */}
              {/* <select
                className="form-select category_item CategoriesFilter"
                onChange={(e) => setSelectedCategory(e.target.value)}
                disabled={isCategoryDisabled(seprationCategory)}
              >
                <option value="">Choose Theme</option>
              
                <option value="Influncer"> Influencer</option>
                <option value="Contructor">Real Estate & Construction</option>
                <option value="Salon">Spa & salon</option>
                <option value="Doctor">Medical</option>
                <option value="Art"> Art & Craft</option>
                <option value="journalist"> Journalist</option>
                <option value="chef"> Chef</option>
                <option value="attorney"> Attorney</option>
                <option value="gym"> Gym</option>
                <option value="it"> information technology </option>
                <option value="event_organizer">Event Organizer</option>
                <option value="repair">Repair & Services</option>
                <option value="house_keeping">House keeping</option>
                <option value="home_decor">Home Decor</option>
                <option value="education">Education</option>
                <option value="contractors">Contractors</option>
                <option value= "driving_schools"> Driving Schools</option>
                <option value="printing">Printing</option>
                <option value="pet_shop">Pet Shop</option>
              </select> */}
            </div>
            ) : (
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <select
                className="form-select category_item"
                onChange={(e) => setSeprationCategory(e.target.value)}
                style={{ width: "250px" }}
              >
                <option value="">Select Category* </option>
                {chooseCategory1?.map((catname)=>(
                <option  key={catname.id} value={catname.cat_slug}>{catname?.cat_name} </option>
                ))}
                {/* <option value="Painting_Contractors"> Painting Contractors</option>
                <option value="Jewellery_Showrooms"> Jewellery Showrooms</option>
                <option value="Furniture_Repair_Services"> Furniture Repair Services</option>
                <option value="Printing_Publishing_Services"> Printing & Publishing Services</option>
                <option value="Wedding_Requisites"> Wedding Requisites</option>
                <option value="Courier_Service">Courier Service</option>
                <option value="Event_Organisers">Event Organisers</option>
           */}
              </select>
              {/* <select
                className="form-select category_item CategoriesFilter"
                onChange={(e) => setSelectedCategory(e.target.value)}
            
              >
                <option value="">Choose Theme </option>
                <option value="mini_marketplace"> Mini Marketplace</option>
                <option value="restaurant"> Restaurant</option>
                <option value="cab"> Cab</option>
                <option value="Influncer"> Influencer</option>
                <option value="Googlereview"> Google review</option>
                <option value="Contructor">Real Estate & Construction</option>
                <option value="Salon">Spa & salon</option>
                <option value="Doctor">Medical</option>
                <option value="Art"> Art & Craft</option>
                <option value="journalist"> Journalist</option>
                <option value="chef"> Chef</option>
                <option value="attorney"> Attorney</option>
          

              </select> */}
                </div>
            )}
          </div>
        </div>
      ) : (
        <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
    {seprationCategory == "cab" || seprationCategory === "restaurant" || seprationCategory === "mini_marketplace" || seprationCategory == "Googlereview" ? (
          <select
          className="form-select category_item"
          onChange={(e) => setSeprationCategory(e.target.value)}
          value={seprationCategory} 
          style={{ visibility: "hidden" }}
        >
          <option value="">Select Category* </option>
          {chooseCategory1?.map((catname)=>(
                <option  key={catname.id} value={catname.cat_slug}>{catname?.cat_name} </option>
                ))}
                {/* <option value="Jewellery_Showrooms"> Tyagiiiiiiiii</option> */}

          {/* <option value="Painting_Contractors"> Painting Contractors</option>
                <option value="Jewellery_Showrooms"> Jewellery Showrooms</option>
                <option value="Furniture_Repair_Services"> Furniture Repair Services</option>
                <option value="Printing_Publishing_Services"> Printing & Publishing Services</option>
                <option value="Wedding_Requisites"> Wedding Requisites</option>
                <option value="Courier_Service">Courier Service</option>
                <option value="Event_Organisers">Event Organisers</option>
     */}

        </select>
    ): (















      <select
      className="form-select category_item"
      onChange={(e) => setSeprationCategory(e.target.value)}
      value={seprationCategory} 
    >
   
      <option value="">Select Category* </option>
      {chooseCategory1?.filter((catname1) => {
      if (["cab", "restaurant", "Googlereview", "mini_marketplace"].includes(seprationCategory)) {
        return catname1.cat_name === seprationCategory; // Match the specific category
      } else {
        return !["Cab", "Restaurant", "Google Review", "Mini Marketplace"].includes(catname1.cat_name);
      }
    }).map((catname) => (
      <option key={catname.id} value={catname.cat_slug}>
        {catname?.cat_name}</option>
    ))}
      {/* {chooseCategory1?.map((catname)=>(
            <option  key={catname.id} value={catname.cat_slug}>{catname?.cat_name} </option>
            ))} */}
       
    </select>











    
    )}
        {/* <select
          className="form-select category_item CategoriesFilter"
          onChange={(e) => setSelectedCategory(e.target.value)}
          disabled={isCategoryDisabled(seprationCategory)}
        
        >
          <option value="">Choose Theme </option>
         
          <option value="Influncer"> Influencer</option>
          <option value="Contructor">Real Estate & Construction</option>
          <option value="Salon">Spa & salon</option>
          <option value="Doctor">Medical</option>
          <option value="Art"> Art & Craft</option>
          <option value="journalist"> Journalist</option>
          <option value="chef"> Chef</option>
          <option value="attorney"> Attorney</option>
    

        </select> */}
          </div>
      )}

      {/* {isChecking == null ? (
        <div class="category-model">
          <select
            className="form-select category_item"
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            <option value="mini_marketplace"> Mini Marketplace</option>
            <option value="restaurant"> Restaurant</option>
            <option value="cab"> Cab</option>
            <option value="Influncer"> Influencer</option>
            <option value="Googlereview"> Google review</option>
            <option value="Contructor">Real Estate & Construction</option>
            <option value="Salon">Spa & salon</option>
            <option value="Doctor">Medical</option>
            <option value="Art"> Art & Craft</option>
            <option value="journalist"> Journalist</option>
            <option value="chef"> Chef</option>
            <option value="attorney"> Attorney</option>
          </select>
        </div>
      ) : (
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <select
            className="form-select category_item"
            onChange={(e) => setSelectedCategory(e.target.value)}
            style={{ width: "250px" }}
          >
            <option value="">Select Category </option>
            <option value="mini_marketplace"> Mini Marketplace</option>
            <option value="restaurant"> Restaurant</option>
            <option value="cab"> Cab</option>
            <option value="Influncer"> Influencer</option>
            <option value="Googlereview"> Google review</option>
            <option value="Contructor">Real Estate & Construction</option>
            <option value="Salon">Spa & salon</option>
            <option value="Doctor">Medical</option>
            <option value="Art"> Art & Craft</option>
            <option value="journalist"> Journalist</option>
            <option value="chef"> Chef</option>
            <option value="attorney"> Attorney</option>
      

          </select>
        </div>
      )} */}
      {filteredData &&
        filteredData.length > 0 &&
        filteredData.map((item, index) => (
          <div className="col-12 col-lg-4 mb-4" key={index}>
            <div
              id={`div${item}`}
              className={`themboxinner L502 k${item}  L${selectedDiv} ${
                selectedDiv === `div${item}` ? "selected-active" : "ddd"
              }`}
              onClick={() => handleDivClick(`div${item}`)}
            >
              <div className={`themimg ${item}`}>
                <img
                  src={`../theme/theme${item}/color${
                    activeTab[`tab${item}`]
                  }.png`}
                  className="mx-auto d-block img-fluid"
                  alt={`../theme/theme${item}/color${
                    activeTab[`tab${item}`]
                  }.png`}
                />
              </div>

              {/* <ul className="colorthem">
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color1`
                    ? { background: "#F9D254", border: "1px solid black" }
                    : { background: "#F9D254" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab1" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "1", "color1")}
              ></li>
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color2`
                    ? { background: "#8ECAE6", border: "1px solid black" }
                    : { background: "#8ECAE6" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab2" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "2", "color2")}
              ></li>
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color3`
                    ? { background: "#F9D254", border: "1px solid black" }
                    : { background: "#F9D254" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab3" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "3", "color3")}
              ></li>
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color4`
                    ? { background: "#FDF0D5", border: "1px solid black" }
                    : { background: "#FDF0D5" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab4" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "4", "color4")}
              ></li>
            </ul> */}
            </div>
          </div>
        ))}
    </>
  );
}
export default ThemTemplate;