import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

function PlansAllocate({
  isOpen,
  handleClosePopup
}) {
  const [show, setShow] = useState(false);
  const [countryForPlan, setCountryForPlan] = useState('');
  const [countriwisePlans, setCountriwisePlans] = useState([]);
  useEffect(() => {
    setShow(isOpen)
  }, [isOpen])
  const handleClose = () => {
    setShow(false);
    handleClosePopup();
  }
  const handleShow = () => setShow(true);
  // frontend validation
  const validateSchema = Yup.object().shape({
    country: Yup.string().required("Select country"),
    plan: Yup.number().required("Select plan"),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      setIsSubmit(true);
      handleChanePassword(values);
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Allocate Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="row mb-4 UpdatePasswordWrapper">
                <div className="col-md-6">                  
                  <select onChange={formik.handleChange} name="country" value={formik.values.country}>
                    <option value="">Select Country</option>
                    <option value="INDIA">India</option>
                    <option value="UAE">UAE</option>
                  </select>
                </div>
                <div className="col-md-6"> 
                  {console.log("countriwisePlans====>>>>", countriwisePlans)}
                  {!!countriwisePlans && countriwisePlans.length>0 &&                 
                    <select onChange={formik.handleChange} name="plan" value={formik.values.plan}>
                      <option value={0}>Select Plan</option>
                    </select>
                  }
                </div>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default PlansAllocate;