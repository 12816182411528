import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Contact Info</h3>
        <ul class="conlink">
          <li style={{display:"flex"}}>
              <i class="fa-solid fa-phone"></i>
              <div>
            <a href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}>
              <span>{ff?.contactInfoPhone}</span>
            </a>
            {ff?.optionalContactInfoPhone?.split("-")?.[1]?.length > 0 &&
              <a 
                href={ff?.optionalContactInfoPhone ? `tel:${ff?.optionalContactInfoPhone}` : ""} >
                <span style={{paddingBottom: "20px"}}> {ff?.optionalContactInfoPhone || ``} </span>
              </a>
          }
          </div>
          </li>
         

          <li>
            <a
              href={
                ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
              }
            >
              <i class="fa-solid fa-envelope"></i>
              <span className="mailurllong">{ff?.contactInfoEmail}</span>
            </a>
          </li>
          {ff?.contactInfoWebUrl && 
          <li>
            <a
              href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!ff?.contactInfoWebUrl) {
                  e.preventDefault(); // Prevents the default link behavior
                }
              }}
              target={ff?.contactInfoWebUrl ? '_blank' : undefined}
              className="contactinfolink content"
            >
              <i class="fa fa-globe"></i>
              <span className="mailurllong"> {ff?.contactInfoWebUrl} </span>
            </a>
          </li>
        }
             {ff?.contactInfoAddress && 
          <li style={{display:"flex"}}>
              <i className="fa-solid fa-location-dot"></i>
              <div>
            <a >
              <span>
                {ff?.contactInfoAddress?.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </a>
            {ff?.contactInfoOptAddress && 
<>
            <p style={{color:"#000", fontSize:"12px"}}>Alternative Address</p>
            <a>
              <span>
              {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
              </span>
            </a>
            </>
}
            </div>
          </li>
}
          
        </ul>
      </article>
    </>
  );
}
export default SubContactInfo;
