import React, { useState, useEffect, useCallback } from "react";
import product1 from "../../../../Images/theme-53-p1.png";
import { environmentVariables } from "../../../../config/env.config";
import Drawer from "react-bottom-drawer";
import CustomerDetails from "./CustomerDetails";
import axios from "axios";
import CartSummary from "./CartSummary";
import { toast } from "react-toastify";
import { getShortDescription } from "../../../../utils/utility";

export const Products = ({ data, active, toggleProduct, currencySymbol, updatedState,setUpdatedState }) => {
  const items = Array.from({ length: 10 }, (_, index) => index + 1);
  const [closing, setClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isCartSummaryVisible, setIsCartSummaryVisible] = useState(false);
  const [selectedProData, setSelectedProData] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [loader, setLoader] = useState(false);
  let customer_data = localStorage.getItem("Customer");
  let cusData = (!!customer_data)?JSON.parse(customer_data):"";
  
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const openCartSummaryDrawer = useCallback(
    () => setIsCartSummaryVisible(true),
    []
  );
  const closeCartSummaryDrawer = useCallback(
    () => setIsCartSummaryVisible(false),
    []
  );

  const fronturl = environmentVariables?.frntUrl;
  const httpProtocol = environmentVariables?.httpProtocol;

  const handleAddToOrder = (menuItem) => {
    // Check if item is already in cart
    setCartItems((prevCartItems) => {
      const isAdded = prevCartItems.some((item) => item.id === menuItem.id);
      
      if (isAdded) {
        // If already added, remove from cart
        return prevCartItems.filter((item) => item.id !== menuItem.id);
      } else {
        if(menuItem?.infproduts?.product_price?.toLowerCase() != "not available"){
          // Add item to cart with selected properties
          const newItem = {
            id: menuItem.id,
            product_id: menuItem?.product_id,
            name: menuItem.infproduts.product_name,
            price: menuItem.infproduts.product_price,
            image: `${environmentVariables.apiUrl}uploads/${menuItem.infproduts.image}`,
            quantity: 1,
            currencySymbol: currencySymbol,
          };
          return [...prevCartItems, newItem];
        }else{
          return prevCartItems;
        }        
      }
    });
  };

  const removeItem = (index) => {
    setCartItems((prevItems) => prevItems.filter((item, i) => i !== index));
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const createOrderRazorPay = async (orderId, dataToSend) => {
    try {
      const res = await loadRazorpayScript();
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      let dataForRazorPay = {
        amount: dataToSend?.total,
        currency: "INR",
        orderId: orderId?.toString(),
        phone: dataToSend?.user_mobile,
      };
      
      let config = {
        method: "post",
        url: `${environmentVariables?.apiUrl}api/v1/influencer/create_order_razorpay`,
        headers: {
          "Content-Type": "application/json",
        },
        data: dataForRazorPay,
      };
      const response = await axios(config);
      const {
        id: order_id,
        amount: order_amount,
        currency: order_currency,
      } = response?.data?.data;
      
      const options = {
        key: process.env.RAZORPAY_ID, // Enter the Key ID generated from the Dashboard
        amount: parseFloat(
          Number(response?.data?.data?.amount) / 100
        ).toFixed(2),
        currency: response?.data?.data?.currency,
        name: "Businessbay",
        description: "Influencer Transactions",
        order_id: response?.data?.data?.id,
        handler: (responseData) => {
          let amountTobeShown = parseFloat(
            !!order_amount ? order_amount / 100 : 0
          );
          window.location.href = `/productpaynowredirect?status=success&payment_id=${responseData.razorpay_payment_id}&orderid=${responseData?.razorpay_order_id}&amount=${amountTobeShown}&currency=${order_currency}`;
          // Optionally, handle the response here (e.g., store the payment info in your backend)
        },
        prefill: {
          name: dataToSend?.user_name,
          email: dataToSend?.user_email,
          contact: dataToSend?.user_mobile,
        },       
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: async() => {
            console.log("failure");
            // setIsSubmit(false);

            // Redirect to failure page
            // window.location.href = "/redirect?status=failure";
            let dataForRazorPayFailed = {
              orderId: orderId?.toString(),
            };
            let config = {
              method: "post",
              url: `${environmentVariables?.apiUrl}api/v1/influencer/update_order_razorpay_failed`,
              headers: {
                "Content-Type": "application/json",
              },
              data: dataForRazorPayFailed,
            };
      
            await axios(config);
          },
        },
        notes: {
          product_purchase: "product_purchase",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.on("payment.failed", function (response) {
        // alert(`Payment Failed! Error: ${response.error.description}`);
        // Redirect to failure page
        // setIsSubmit(false);

        window.location.href = `/productpaynowredirect?status=failure&payment_id=${response.error.metadata.payment_id}&orderid=${response?.error?.metadata?.order_id}`;
      });
      rzp.open();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message, {
        autoClose: 2000,
      });
      console.log(err);
    }
  };

  const createOrder = async () => {
    setLoader(true);
    console.log("cartItems====>>>>", cartItems);
    
    const transformedProducts = cartItems.map((product) => ({
      influencer_product_id: product.id,
      product_id: product.product_id,
      quantity: product.quantity,
      name:product?.name,
      single_price:product?.price,
      price_x_qty: (!!product?.price)?parseFloat(product?.price) * Number(product.quantity):0
    }));

    // set current business slug in localstorage
    const slugb = data?.slug;
    const slug_subdomain = data?.slug_subdomain;
    let businessUrl =
      slug_subdomain != null
        ? httpProtocol + slug_subdomain
        : slugb != null
        ? fronturl + slugb
        : "/";
    
    localStorage.setItem("businessUrl", businessUrl);
    localStorage.setItem("orderCurrencySymbol", currencySymbol);
    // Calculate subtotal based on product price and quantity from the cart
    const subTotal = cartItems.reduce((acc, product) => {
      return acc + parseFloat(product.price) * product.quantity;
    }, 0);

    const deliveryCharge = 0; // Set this based on your delivery charge logic
    const discount = 0; // Set this based on your discount logic
    const total = subTotal + deliveryCharge - discount;
    let productsInfo = data?.influencerProducts;
    let singleProData = productsInfo.length>0?productsInfo[0]:"";
    
    // Set form data dynamically using productData and userDetails
    const dataTobeSend = {
      products: transformedProducts,
      coupon_id: 0,
      business_id: data?.id,
      product_business_id: !!singleProData?singleProData?.product_business_id:null,
      influencer_id: data?.influencer_id,
      sub_total: subTotal,
      delivery_charge: deliveryCharge,
      discount: discount,
      total: total,
      payment_method: "Razorpay",
      customer_id:cusData?.id,
      currency:currencySymbol,
      user_name: selectedUserData?.name,
      user_email: selectedUserData?.email,
      user_mobile: selectedUserData?.phone,
      user_country_code: selectedUserData?.countryCode,
      user_address: selectedUserData?.address,
    };
    try {
      const createRes = await axios.post(
        `${environmentVariables?.apiUrl}api/v1/influencer/create_order`,
        dataTobeSend,
        { "Content-Type": "application/json" }
      );
      await createOrderRazorPay(createRes?.data?.data?.order_id, dataTobeSend);
      setLoader(false);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };

  const handleProceedDPO = async () => {
    try {
      setLoader(true);
      const transformedProducts = cartItems.map((product) => ({
        influencer_product_id: product.id,
        product_id: product.product_id,
        quantity: product.quantity,
        name:product?.name,
        single_price:product?.price,
        price_x_qty: (!!product?.price)?parseFloat(product?.price) * Number(product.quantity):0
      }));

      // set current business slug in localstorage
      const slugb = data?.slug;
      const slug_subdomain = data?.slug_subdomain;
      let businessUrl =
        slug_subdomain != null
          ? httpProtocol + slug_subdomain
          : slugb != null
          ? fronturl + slugb
          : "/";
      localStorage.setItem("businessUrl", businessUrl);
      localStorage.setItem("orderCurrencySymbol", currencySymbol);

      // Calculate subtotal based on product price and quantity from the cart
      const subTotal = cartItems.reduce((acc, product) => {
        return acc + parseFloat(product.price) * product.quantity;
      }, 0);

      const deliveryCharge = 0; // Set this based on your delivery charge logic
      const discount = 0; // Set this based on your discount logic
      const total = subTotal + deliveryCharge - discount;
      let productsInfo = data?.influencerProducts;
      let singleProData = productsInfo.length>0?productsInfo[0]:"";

      const dataToBeSave = {
        products: transformedProducts,
        coupon_id: 0,
        business_id: data?.id,
        product_business_id: !!singleProData?singleProData?.product_business_id:null,
        influencer_id: data?.influencer_id,
        sub_total: subTotal,
        delivery_charge: deliveryCharge,
        discount: discount,
        total: total,
        currencyName: "AED",
        currency:currencySymbol,
        payment_method: "DPO",
        customer_id:cusData?.id,
        user_name: selectedUserData?.name,
        user_email: selectedUserData?.email,
        user_mobile: selectedUserData?.phone,
        user_country_code: selectedUserData?.countryCode,
        user_address: selectedUserData?.address,
        cURL:businessUrl
      };
      
      const response = await fetch(
        `${environmentVariables?.apiUrl}api/v1/payment/getTransTokenForProduct`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            // {
            //   // Provide any necessary data for the transaction
            //   amount: cardAmountAfterDiscount || cardTotalAmount,
            //   currency: currencyObjData?.name,
            //   DeliveryDate: DeliveryDate,
            //   deliveryInstructions: deliveryInstructions,
            //   business_id: Selectedid,
            //   print_business_name: SelectedTitle || selectBusinessTitle,
            //   selectBusinessTitle,
            //   cardType: cardTypeStr,
            //   cardId: cardId,
            //   logourl: logoimg,
            // }
            dataToBeSave
          ),
        }
      );

      const dataResponse = await response.json();

      // Handle the response from the backend
      if (response.ok) {
        // Payment initiated successfully
        if (
          dataResponse.success &&
          dataResponse.result &&
          dataResponse.result.API3G &&
          dataResponse.result.API3G.TransToken &&
          dataResponse.result.API3G.TransRef
        ) {

          // Redirect to the payment URL with the received token
          // window.location.href = `https://secure.3gdirectpay.com/payv3.php?ID=${data.result.API3G.TransToken._text}`;
          const paymentPlatformUrl = `https://secure.3gdirectpay.com/payv3.php?ID=${dataResponse.result.API3G.TransToken._text}`;
          // Add more data as needed

          // Navigate to the payment platform
          window.location.href = paymentPlatformUrl;
          setLoader(false);

          // setIsPaymentBtnClicked(false);
        } else {
          console.error("Missing data for redirect");
          setLoader(false);

          // setIsPaymentBtnClicked(false);
        }
      } else {
        // Payment initiation failed
        console.error("Error initiating payment:", dataResponse.message);
        setLoader(false);

        // setIsPaymentBtnClicked(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoader(false);

      // setIsPaymentBtnClicked(false);
    }

    // setIsPaymentBtnClicked(false);
  };

  // const link = `${environmentVariables.apiUrl}uploads/gallery/${item.image}`;
  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      toggleProduct();
      setClosing(false);
    }, 600);
  };
  let customerData = localStorage.getItem("Customer");
  useEffect(() => {
    if (!!selectedUserData) {
      if (customerData == null) {
      }
      callPG();
    }
  }, [selectedUserData]);

  const callPG = () => {
    if (!!data?.shipCountry && data?.shipCountry == "IN") {
      createOrder();
    } else {
      handleProceedDPO();
    }
  };

  const handleFormSubmit = (formData) => {
    setLoader(true);
    setSelectedUserData(formData);
  };

  const handleBuyNow = (selectedProductData) => {
    openDrawer();
    setSelectedProData(selectedProductData);
  };

  return (
    <section
      style={{ bottom: closing ? "-100%" : active ? "50px" : "-100%" }}
      className={`${active ? "product-popup" : ""}`}
    >
      <div className="product-popup-inner">
        <article className="products sec">
          <h3 className="sectitle">Products</h3>
          <p className="cross">
            {active && <i onClick={handleClose} className="fa-solid fa-x"></i>}
          </p>
          <div className="product-inner">
            {!!data &&
              data?.influencerProducts?.length > 0 &&
              data?.influencerProducts?.map((item) => {
                const infProduct = item.infproduts;
                const link = `${environmentVariables.apiUrl}uploads/${infProduct?.image}`;
                const isAdded = cartItems.some(
                  (cartItem) => cartItem.id === item.id
                );

                return (
                  <div className="item" key={item}>
                    <div className="image">
                      <img src={link} alt={infProduct.product_name} />
                    </div>
                    <h6>{infProduct.product_name}</h6>
                    {/* <p>{`${currencySymbol}${infProduct?.product_price !== "Not available" ? infProduct.product_price : "N/A"}/-`}</p> */}
                    {!!infProduct.product_price &&
                    infProduct?.product_price !== "Not available" ? (
                      <p>{`${currencySymbol}${infProduct.product_price}`}</p>
                    ) : (
                      <>
                        <p>{`N/A`} </p>
                      </>
                    )}

                    <button onClick={() => handleAddToOrder(item)}>
                      {isAdded ? (
                        <div className="addeditem">Added</div>
                      ) : (
                        <div className="addtocartitem">Add To Cart</div>
                      )}
                    </button>
                  </div>
                );
              })}
          </div>
          {cartItems.length > 0 && (
            <div className="continue-popup" onClick={openCartSummaryDrawer}>
              <button
                className="continue-popupbutton"
                style={{ color: "white" }}
              >
                <span>{`${cartItems.length} item selected`}</span>
                {`Continue`}
              </button>
            </div>
          )}
        </article>
        <Drawer
          duration={150}
          hideScrollbars={true}
          onClose={closeDrawer}
          isVisible={isVisible}
          className="drawer"
        >
          <CustomerDetails
            closeDrawer={closeDrawer}
            onSubmit={handleFormSubmit}
            loader={loader}
            customerData={customerData}
          />
        </Drawer>
        <Drawer
          duration={150}
          hideScrollbars={false}
          onClose={closeCartSummaryDrawer}
          isVisible={isCartSummaryVisible}
          className="cartdrawer"
        >
          <CartSummary
            cartItems={cartItems}
            closeDrawer={closeCartSummaryDrawer}
            removeItem={removeItem}
            openDrawer={openDrawer}
            closeCartSummaryDrawer={closeCartSummaryDrawer}
            updatedState={updatedState}
            setUpdatedState={setUpdatedState}
          />
        </Drawer>
      </div>
    </section>
  );
};
