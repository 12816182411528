import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import { environmentVariables } from "../../config/env.config";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import "./UpdateRequestcardstyle.css";
import { Modal } from "react-bootstrap";
import { isValidEmail, isValidName, isValidPassword, isValidPhoneNumber } from "../../utils/utility";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function CreateUser(props) {
  const { userData } = useContext(userContext);
  const [show, setShow] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState(null);
  const [errorMsgEmail, setErrorMsgEmail] = useState(null);
  const [errorMsgPassword, setErrorMsgPassword] = useState(null);
  const [errorMsgRole, setErrorMsgRole] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [userCreateData, setUserCreateData] = useState({
    name: "",
    email: "",
    password: "",
    roleId: 0,
    contact: "",
  });

  const handleClose = () => setShow(false);

  useEffect(()=>{
    setShow(props.showModal);
    setUserCreateData(props.userCreateData);
    setRoleData(props.roleData);
  },[props])

  const handleChangeUserData = (e, type) => {
    setErrorMsgName("");
    setUserCreateData({ ...userCreateData, [`${type}`]: e.target.value });
  };

  const handleSubmit = () => {
    setErrorMsgName("");
    setErrorMsgEmail("");
    setErrorMsgPassword("");
    setErrorMsgRole("");

    if (userCreateData["name"] === "" || userCreateData["name"]?.trim()?.length == 0) {
      setErrorMsgName("Name is mandatory");
      return;
    }
    else if (!isValidName(userCreateData["name"])) {
      if (userCreateData["name"].length < 3) {
        setErrorMsgName("Name should be at least 3 characters long");
        return;
      } else {
        setErrorMsgName("Name can only contain alphabets, hyphens, and spaces");
        return;
      }
    } else if (userCreateData["email"] === "") {
      setErrorMsgEmail("Email is mandatory");
      return;
    } else if (!isValidEmail(userCreateData["email"])) {
      setErrorMsgEmail("Invalid email format");
      return;
    } else if (!isValidPassword(userCreateData["password"])) {
      setErrorMsgPassword(
        "Password should be at least 8 characters long and contain at least one letter and one digit"
      );
      return;
    } else if (userCreateData["roleId"] === 0) {
      setErrorMsgRole("Role is mandatory");
      return;
    }
    if (
      userCreateData["name"] !== "" &&
      userCreateData["email"] !== "" &&
      userCreateData["password"] !== "" &&
      userCreateData["roleId"] !== 0 &&
      userCreateData["contact"] !== ""
    ) {
      let data = JSON.stringify(userCreateData);
      setIsSubmit(true);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/user/createUser`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          handleClose();
          props.handleCreateUser(true);
          setIsSubmit(false);
          toast.success("User created", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          setIsSubmit(false);
          toast.error(error?.response?.data?.message || error?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          // setErrorMsg(null);
        });
    } else {
      setIsSubmit(false);
      // setErrorMsg("Please fill all the data");
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = null;
    if(!!contactNumber){
      let val = contactNumber.split(" ");
      // let valArray = (val.length>0)?val[1]:"";
      let valArray = (val.length > 0) ? val.slice(1).join('') : "";
      let valueData = (valArray)?valArray.replace("-",""):"";
      if(valueData==''){
        setInvalidPhoneErr(`Please enter phone number.`);
      }else if (!isValidPhoneNumber(valueData, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr('');
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> 
            <h5 class="modal-title" id="exampleModalLabel">
              Create New User
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-body">
            <form class="row">
              <div class="mb-2 col-md-12">
                <input
                  onChange={(e) => {
                    handleChangeUserData(e, "name");
                  }}
                  value={userCreateData["name"]}
                  class="form-control"
                  type="text"
                  placeholder="Name"
                />
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    bottom: "70px",
                  }}
                >
                  {errorMsgName}
                </div>
              </div>
              <div class="mb-2 col-md-12">
                <input
                  value={userCreateData["email"]}
                  onChange={(e) => handleChangeUserData(e, "email")}
                  class="form-control"
                  type="text"
                  placeholder="Email"
                />
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",

                    bottom: "70px",
                  }}
                >
                  {errorMsgEmail}
                </div>
              </div>
              <div className="mb-2 col-md-12">
              <PhoneInput
                    name="contact"
                    country={"in"}
                    enableSearch={true}
                    countryCodeEditable={false}
                    value={userCreateData.contact} // Set current contact value
                    onChange={(phone, country,e, formattedValue) => {
                      console.log(formattedValue,"formattedValue")
                      setUserCreateData((prev) => ({
                        ...prev,
                        contact: formattedValue,
                      }));
                      setInvalidPhoneErr(""); // Clear any previous error
                    }}
                    onBlur={(e, country) => {
                      handleBlur(e, country);
                    }}
                    isValid={(inputNumber, country) =>
                      isValidPhoneNumber(inputNumber, country)
                    }
                    required={true}
                    inputProps={{
                      required: "required",
                      className:
                        "form-control register_inputfield country_mobile registerCountarycodestyle",
                      placeholder: "Mobile Number*",
                    }}
                  />
                  {invalidPhoneErr && (
                    <div id="error_msg">{invalidPhoneErr}</div>
                  )}

                      </div>
              <div class="mb-2 col-md-12" style={{position:"relative"}}>
                <input
                  class="form-control"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={userCreateData["password"]}
                  onChange={(e) => handleChangeUserData(e, "password")}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "25px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? (
                    <i className="fas fa-eye Eeye"></i>
                  ) : (
                    <i className="fas fa-eye-slash Eeye"></i>
                  )}
                </span>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",

                    bottom: "70px",
                  }}
                >
                  {errorMsgPassword}
                </div>
              </div>
              <div class="mb-2 col-md-12">
                <select
                  value={userCreateData["roleId"]}
                  onChange={(e) => handleChangeUserData(e, "roleId")}
                  class="form-select"
                >
                  <option value={""}>User Role</option>
                  {roleData &&
                    roleData.map((val, inde) => (
                      <option key={inde} value={val?.role_id}>{val?.role_name} </option>
                    ))}
                </select>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",

                    bottom: "70px",
                  }}
                >
                  {errorMsgRole}
                </div>
              </div>
              <div class="mb-2 flex-center col-md-12">
                <button
                  onClick={handleSubmit}
                  class="btn btn-primary me-3 save-cta"
                  type="button"
                >
                  {isSubmit ? <CircularLoader size={20} /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateUser;
