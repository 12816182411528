import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../config/env.config";
import { userContext } from "../context/userContext";
import Cookies from 'js-cookie';
import { useFormik } from "formik";
import * as Yup from "yup";
import CircularLoader from "../Backend/Components/CircularLoader/CircularLoader";

function VerifyEmailOTP() {
  const { userData, setUserData } = useContext(userContext);
  const location = new useLocation();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(null);
  const [otp, setOtp] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  // frontend validation
  const validateSchema = Yup.object().shape({
    otp: Yup.string()
      .required("Please enter OTP.")
      .min(6, "OTP must have at least 6 characters")
      .matches(/[0-9]/, `Your password must have at digits`)
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      setIsSubmit(true);
      verifyLogin(values);
    },
  });

  const verifyLogin = (dataValue) => {
    let getcUsrEmail = localStorage.getItem("getcUsrEmail");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/verifyOtp`,
      data:{
        otp:dataValue?.otp,
        email:getcUsrEmail,
      }
    };
    axios
      .request(config)
      .then((res) => {
        setIsSubmit(false);
        toast.success("Your email has been verified successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setTimeout(() => {
          navigate("/customer_login");
        }, 3000);
      })
      .catch((error) => {
        setIsSubmit(false);
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  }

  const handleOtp = (e) => {
    setOtp(e.target.value);
  }

  return (
    <section className="py-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-4">
            <div className="loginform">
              {/* <h3>Verified Email Successfully</h3> */}
              {/* <h3>Verif Email</h3> */}
              <form onSubmit={formik.handleSubmit}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <h3>Please enter the otp below to verify your email:</h3>
                  <input
                    id="otp"
                    name="otp"
                    className="form-control"
                    placeholder="Enter OTP"
                    type={"text"}
                    onChange={formik.handleChange}
                    value={formik.values.otp}
                  />
                  {formik.touched.otp && formik.errors.otp && (
                    <p className="text-danger">{formik.errors.otp}</p>
                  )}
                  <button
                    style={{
                      display: "block",
                      width: "100%",
                      textAlign: "center",
                      color: "#fff",
                      marginBottom: "13px",  // Corrected from margin-bottom to marginBottom
                      padding: "15px 40px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderRadius: "50px",
                      background: "linear-gradient(to right, #01c3cc, #7d2ae8)",  // Corrected from background: linear-gradient(...) to "linear-gradient(...)"
                      border: "0",  // Corrected from border: 0 to "border: 0"
                      boxShadow: "0px 8px 24px rgba(191, 173, 223, 0.61)",  // Corrected from boxShadow: rgb(...) to "boxShadow: ..."
                      textDecoration: "none !important",
                    }}
                  >
                     {isSubmit ? <CircularLoader size={25} /> : "Click here to verify"}
                  </button>
                  <p>
                    <Link to="/customer_login" style={{ color: '#0D0D0E', textDecoration: 'none', display: 'block', marginTop: '10px', fontWeight: "400" }} >
                      Go Back to Login Page
                    </Link>
                  </p>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyEmailOTP;
