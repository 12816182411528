import styled from "styled-components";

export const ConfirmOrderWrapper = styled.div`
background-color: #101010;
margin: 20px 25px 20px 25px;
padding: 16px 15px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
border-radius: 0;
`;

export const CustomerDetailText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 600;
font-size: 24px;
color: #101010;
text-align:justify;
${(p)=>p.Custoomer && `
@media(max-width: 480px){
    font-size: 26px;
}
`}
`;

export const CustomerDetailsWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
margin: 10px 0;


`;

export const DrawerCloseButton = styled.div`
color:red;
cursor: pointer;
font-size: 40px;
transform: rotate(45deg);
`;

export const DrawerCloseButtonWrapper= styled.div`
position: absolute;
top: -10px;
right: 10px;
`;


export const InputTextField = styled.input`
background-color: #F6F6F6;
width: 100%;
padding: 16px 20px;
font-size:18px;
border: 1px solid #8080809c;
border-radius: 0;
margin: 5px 0;
&:focus {
    outline: none; 
}
`;
export const InputTextFieldtextarea = styled.textarea`
background-color: #F6F6F6;
width: 100%;
padding: 16px 20px;
font-size:16px;
border: 1px solid #8080809c;
border-radius: 0;
margin: 5px 0;
&:focus {
    outline: none; 
}
`;

export const InputTextFieldWrapper = styled.div`
/* width: 100%; */
margin: 10px 25px;
`;

export const TabCustomerDetailsWrapper = styled.div`
background: #fff;
padding: 10px 0px;
border-radius: 15px;
margin: 10px 20px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
position: relative;
width: 100%;
z-index: 999;
transform: translateY(-50%) !important;
  @media (max-width: 1260px) {
    transform: translateY(-50%) !important;
  }
      @media (max-width: 800px) {
    transform: translateY(-30%) !important;
  }

  @media (max-height: 800px) {
    transform: translateY(-30%) !important;
  }
 @media (max-width: 1260px) and (max-height: 800px) {
    transform: translateY(-16%) !important;
  }

`;