// Captcha.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { environmentVariables } from '../config/env.config';
import RefreshIcon from "../Images/refreshcaptcha.png";

const baseUrl = environmentVariables?.apiUrl;
function Captcha({ onCaptchaSolved }) {
  const [captcha, setCaptcha] = useState({ question: '', answer: 0 });
  const [userAnswer, setUserAnswer] = useState('');

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    const response = await axios.get(`${baseUrl}api/v1/user/fetchCaptcha`);
    setCaptcha(response.data);
    setUserAnswer('');
    onCaptchaSolved("");
  };

  const handleChange = (e) => {
    setUserAnswer(e.target.value);
    onCaptchaSolved(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Allow only numeric input
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  
  return (
    <div style={{marginTop:"0px"}}>
        <p className='heading' style={{paddingBottom:"5px"}}>What is: {captcha.question}</p>
        <div className='inputbuttoncontainer' style={{paddingBottom:"10px"}}>
            <input
                type="text"
                value={userAnswer}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder="Input your answer"
                className='captchainput'
                maxLength={2}
            />
            <button type='button' className='refresscaptchabtn' onClick={fetchCaptcha}>
                <img className='refreshicon' src={RefreshIcon} alt="Refresh CAPTCHA" /> Refresh
            </button>
        </div>
    </div>
  );
}

export default Captcha;
