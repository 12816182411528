import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Register.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, useFormik } from "formik";
import { registerValidationUser } from "../common/registerValidationUser";
import { registerValidationCompany } from "../common/registerValidationCompany";
import { environmentVariables } from "../config/env.config";
import CircularLoader from "../Backend/Components/CircularLoader/CircularLoader";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Mainlogo from "../Images/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "../utils/utility";


function Register() {
  const baseUrl = environmentVariables?.apiUrl;
  const homelocation = environmentVariables?.homeLoc;
  const [selectedOption, setSelectedOption] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowconPassword] = useState(false);
  const [resetRecaptcha, setResetRecaptcha] = useState(false); // Track reset condition
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");


  // console.log(homelocation,"homelocation")


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConVisibility = () => {
    setShowconPassword(!showconPassword);
  };


  function onChangeRecaptcha(value) {
    setCaptchaVerified(value !== null);
    formik.setFieldValue("captchaValue", value);
  }
  function onChangeRecaptchSEtDefaultValue() {
    setCaptchaVerified(false);
    formik.setFieldValue("captchaValue", "");

  }
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData) {
      navigate("/dashboard");
      return;
    }
  }, []);
  const initialValuesUserFormik = {
    type: "",
    name: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
    captchaValue: "",
  };
  const initialValuesCompanyFormik = {
    type: "",
    name: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
    company_name: "",
    company_employee: 0,
    department: "",
    captchaValue: "",
  };
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value == "option1") {
      formik.setFieldValue("type", "individual");
    } else if (event.target.value == "option2") {
      formik.setFieldValue("type", "company");
    } else {
      formik.setFieldValue("type", null);
    }
  };
  let formik = useFormik({
    initialValues:
      selectedOption == "option1"
        ? initialValuesUserFormik
        : initialValuesCompanyFormik,
    validationSchema:
      selectedOption == "option1"
        ? registerValidationUser
        : registerValidationCompany,
    onSubmit: async (values, action) => {
      setIsSubmit(true);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailPattern.test(values?.email);

      const searchParams = new URLSearchParams(window.location.search);
      const utmSource = searchParams.get('utm_source');
      const utmMedium = searchParams.get('utm_medium');
      const utmCampaign = searchParams.get('utm_campaign');
      const utmContent = searchParams.get('utm_content');


      values = {
        ...values,
        email: values?.email?.toLowerCase()?.trim(),
        name: values?.name?.trim(),
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
      };

      if(utmCampaign){
        values.campaign_name = "campaign1"
      }
      if (isValid && values?.name != '' && values?.name?.trim().length >= 3) {
        if (!isCaptchaVerified || Object.keys(formik.errors).length > 0) {
          toast.error("Captcha is required", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
        axios
          .post(`${baseUrl}api/v1/user/register_withCaptcha`, values)
          // .post(`${baseUrl}api/v1/user/register`, values)
          .then((res) => {
            setIsSubmit(true);
            toast.success("Verification email sent", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setTimeout(() => {
              navigate("/login");
            }, 1500)
          })
          .catch((err) => {
            const errorMessage =
              err?.response?.data?.message?.message ||
              err?.response?.data?.message;
            if (errorMessage) {
              toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            } else {
              toast.error("An error occurred", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
            setIsSubmit(false); 
            setResetRecaptcha(true);
          }).finally(()=>{
            onChangeRecaptchSEtDefaultValue()
          });
      } else {
        if (!isValid) {
          toast.error("Email is not valid", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error("Name is not valid", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
        setIsSubmit(false);
        setResetRecaptcha(true);
        onChangeRecaptchSEtDefaultValue();
      }
    }
  });

  const { values, errors, handleSubmit } = formik;
  useEffect(() => {
    setTimeout(() => {
      setIsSubmit(false)
    }, 1000)
  }, [formik])

  useEffect(() => {
    if (resetRecaptcha) {
      setResetRecaptcha(false); 
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  }, [resetRecaptcha]);
  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.contact;
    if(!!contactNumber){
      let val = contactNumber.split(" ");
      // let valArray = (val.length>0)?val[1]:"";
      let valArray = (val.length > 0) ? val.slice(1).join('') : "";
      let valueData = (valArray)?valArray.replace("-",""):"";
      if(valueData==''){
        setInvalidPhoneErr(`Please enter phone number.`);
      }else if (!isValidPhoneNumber(valueData, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr('');
      }
    }
  };

  return (
    <>
      <div className="login-23">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 col-md-12 bg-color-23"
              style={{ backgroundSize: "contain" }}
            >
              <div className="form-section">
                <h3>Create An Account</h3>
                <div className="login-inner-form">
                  <form className="row g-3">
                    <>
                      <div className="col-md-12 mb-3-h">
                        <input
                          type="text"
                          className="form-control register_inputfield"
                          placeholder="Name*"
                          name="name"
                          value={values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required=""
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div id="error_msg">{errors.name}</div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-3-h">
                        <input
                          type="text"
                          className="form-control register_inputfield"
                          placeholder="Email*"
                          name="email"
                          value={values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required=""
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div id="error_msg">{errors.email}</div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-3-h register_countryinput">
                        <PhoneInput
                          name="contact"
                          country={"in"}
                          enableSearch={true}
                          countryCodeEditable={false}
                          value={values?.contact}
                          onChange={(phone, country, e, formattedValue) => {
                            formik.setFieldValue("contact", formattedValue);
                            setInvalidPhoneErr("");
                          }}
                          // onBlur={formik.handleBlur}
                          onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contact")}
                          isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                          required={true}
                          inputProps={{
                            required: "required",
                            className: "form-control register_inputfield country_mobile registerCountarycodestyle",
                            placeholder: "Mobile Number*",
                          }}
                        />
                        {formik.touched.contact && formik.errors.contact ? (
                          <div id="error_msg">{errors.contact}</div>
                        ) : null}
                        {invalidPhoneErr && <div id="error_msg">
                                    {invalidPhoneErr}
                                  </div>}
                      </div>
                      <div className="col-md-12 mb-3-h" style={{ display: 'flex', position: 'relative', flexDirection: 'column' }}>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="form-control register_inputfield"
                          placeholder="Password*"
                          name="password"
                          value={values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required=""
                          style={{ flex: '1' }}
                        />
                        <div
                          onClick={togglePasswordVisibility}
                          style={{
                            cursor: 'pointer',
                            alignSelf: 'center',
                            position: 'absolute',
                            top: '22px',
                            right: '30px',
                          }}
                        >
                          {showPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div id="error_msg">{errors.password}</div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-3-h" style={{ display: 'flex', position: 'relative', flexDirection: 'column' }}>
                        <input
                          type={showconPassword ? 'text' : 'password'}
                          className="form-control register_inputfield"
                          name="confirmPassword"
                          placeholder="Confirm Password*"
                          value={values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{ flex: 1 }}
                        />
                        <span
                          onClick={togglePasswordConVisibility}
                          style={{
                            cursor: 'pointer',
                            alignSelf: 'center',
                            position: 'absolute',
                            top: '22px',
                            right: '30px',
                          }}
                        >
                          {showconPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                        </span>
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                          <div id="error_msg">{formik.errors.confirmPassword}</div>
                        ) : null}
                      </div>
                    </>
                    <div className="col-md-12 mb-3-h">
                      <select
                        name="type"
                        className="form-select register_inputfield"
                        value={selectedOption}
                        onChange={handleDropdownChange}
                      >
                        <option value="">Select Register Type</option>
                        <option value="option1">Individual </option>
                        <option value="option2">Corporate</option>
                      </select>
                      {formik.touched.type && formik.errors.type ? (
                        <div id="error_msg">{errors.type}</div>
                      ) : null}
                    </div>
                    {selectedOption === "option2" && (
                      <>
                        <div className="col-md-12 mb-3-h">
                          <input
                            type="text"
                            className="form-control register_inputfield"
                            placeholder="Company Name*"
                            name="company_name"
                            value={values.company_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required=""
                          />
                          {formik.touched.company_name &&
                            formik.errors.company_name ? (
                            <div id="error_msg">{errors.company_name}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3-h">
                          <input
                            type="number"
                            className="form-control register_inputfield"
                            placeholder="Number of Employees"
                            name="company_employee"
                            value={values.company_employee}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required=""
                          />
                          {formik.touched.company_employee &&
                            formik.errors.company_employee ? (
                            <div id="error_msg">{errors?.company_employee}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3-h">
                          <input
                            type="text"
                            className="form-control register_inputfield"
                            placeholder="Department"
                            name="department"
                            value={values.department}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required=""
                          />
                          {formik.touched.department &&
                            formik.errors.department ? (
                            <div id="error_msg">{errors.department}</div>
                          ) : null}
                        </div>
                      </>
                    )}
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                      onChange={onChangeRecaptcha}
                      className="recaptch_styled"
                    />
                    <div className="col-md-12" style={{marginTop:"0px"}}>
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-primary btn-theme login_button"
                        // disabled={isSubmit}
                        style={{margintTop:"0px"}}
                      >
                        {isSubmit ? <CircularLoader size={25} /> : "Register"}
                      </button>
                    </div>
                    <ToastContainer />
                  </form>
                </div>
                <p className="text-center">
                  Already a member?
                  <Link to="/login">
                    <b style={{paddingLeft:"5px"}}> Login here </b>
                  </Link>
                </p>
                <h3 style={{ marginTop: "30px", marginBottom: "0" }} className="mainlogoalignloginreg">
                  <Link to={`${homelocation}`}>
                    <img style={{ width: "120px" }} src={Mainlogo} />
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 bg-img login-slides">
              <div className="info">
                <div className="waviy">
                  {/* Welcome To <span>Businessbay</span> */}
                </div>
                <p>
                  {/* Experience a 15-day FREE Trial that helps you connect with
                  people easily and build important relationships. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
//314 10:15
