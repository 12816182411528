import React, { useEffect, useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useLocation } from "react-router";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import successright from '../../Images/check.png'
import { json, useNavigate } from "react-router";
import axios from "axios";
import { currencyContext } from "../../context/currencyContext";


const ProductPayNow = () => {
  const [paymentData, setPaymentData] = useState(null);
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const createOrderRazorPay = async (orderId) => {
    try {
      const res = await loadRazorpayScript();
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      let data = {
        amount: "100",
        currency: "INR",
        orderId: orderId?.toString(),
        phone: "8193945207",
      };

      let config = {
        method: "post",
        url: `${environmentVariables?.apiUrl}api/v1/influencer/create_order_razorpay`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      const {
        id: order_id,
        amount: order_amount,
        currency: order_currency,
      } = response?.data?.data;

      console.log("trtrtrtrtr", response?.data?.data);
      const options = {
        key: process.env.RAZORPAY_ID, // Enter the Key ID generated from the Dashboard
        amount: order_amount,
        currency: order_currency,
        name: "Shelux Product",
        description: "This is a unique product.",
        order_id: order_id,
        handler: (response) => {
          // alert(
          //   `Payment Successful! Payment ID: ${response.razorpay_payment_id}`
          // );
          console.log("111111111111", response);
          let amountTobeShown = parseFloat((!!order_amount)?order_amount/100:0);
          window.location.href = `/productpaynowredirect?status=success&payment_id=${response.razorpay_payment_id}&orderid=${response?.razorpay_order_id}&amount=${amountTobeShown}&currency=${order_currency}`;
          // Optionally, handle the response here (e.g., store the payment info in your backend)
        },
        prefill: {
          name: "Devendra",
          email: "devendra@bastionex.net",
          contact: "81939425207",
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: () => {
            console.log("failure");
            // setIsSubmit(false);
            console.log("22222222222");

            // Redirect to failure page
            // window.location.href = "/redirect?status=failure";
          },
        },
        notes: {
          product_purchase: "product_purchase"
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.on("payment.failed", function (response) {
        // alert(`Payment Failed! Error: ${response.error.description}`);
        // Redirect to failure page
        // setIsSubmit(false);
        console.log("3333333333333", response?.error?.metadata?.order_id);

        window.location.href = `/productpaynowredirect?status=failure&payment_id=${response.error.metadata.payment_id}&orderid=${response?.error?.metadata?.order_id}`;
      });
      rzp.open();
    } catch (err) {
      // setIsSubmit(false);
      // toast.error(err?.response?.data?.message || err?.message, {
      //   autoClose: 2000,
      // });

      console.log(err);
    }
  };

  const createOrder = async () => {
    const data = {
      products: [
        {
          product_id: 1,
          quantity: 3,
        },
        {
          product_id: 2,
          quantity: 3,
        },
      ],
      coupon_id: 1,
      business_id: 255731,
      sub_total: 200,
      delivery_charge: 0,
      discount: 10,
      total: 200,
      payment_method: "Razorpay",
      user_name: "Devendra Kumar Nai",
      user_email: "devendra@bastionex.net",
      user_mobile: "8193945207",
      user_country_code: "+91",
      user_address: "B2, Sector 4, Noida, Uttar Pradesh.",
    };
    try {
      const createRes = await axios.post(
        `${environmentVariables?.apiUrl}api/v1/influencer/create_order`,
        data,
        { "Content-Type": "application/json" }
      );
      console.log("createRes", createRes?.data?.data?.order_id);
      createOrderRazorPay(createRes?.data?.data?.order_id);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };

  const handleProceedDPO = async () => {
    try {
      // setIsPaymentBtnClicked(true);
      const dataToBeSave = {
        products: [
          {
            product_id: 1,
            quantity: 3,
          },
          {
            product_id: 2,
            quantity: 3,
          },
        ],
        coupon_id: 1,
        business_id: 255731,
        sub_total: 200,
        delivery_charge: 0,
        discount: 10,
        total: 200,
        currency: "AED",
        payment_method: "DPO",
        user_name: "Devendra Kumar Nai",
        user_email: "devendra@bastionex.net",
        user_mobile: "8193945207",
        user_country_code: "+91",
        user_address: "B2, Sector 4, Noida, Uttar Pradesh.",
      };
      const response = await fetch(
        `${environmentVariables?.apiUrl}api/v1/payment/getTransTokenForProduct`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
              // {
              //   // Provide any necessary data for the transaction
              //   amount: cardAmountAfterDiscount || cardTotalAmount,
              //   currency: currencyObjData?.name,
              //   DeliveryDate: DeliveryDate,
              //   deliveryInstructions: deliveryInstructions,
              //   business_id: Selectedid,
              //   print_business_name: SelectedTitle || selectBusinessTitle,
              //   selectBusinessTitle,
              //   cardType: cardTypeStr,
              //   cardId: cardId,
              //   logourl: logoimg,
              // }
              dataToBeSave
          ),
        }
      );

      const data = await response.json();

      // Handle the response from the backend
      if (response.ok) {
        // Payment initiated successfully
        console.log("Payment initiated:", data);
        if (
          data.success &&
          data.result &&
          data.result.API3G &&
          data.result.API3G.TransToken &&
          data.result.API3G.TransRef
        ) {
          // Redirect to the payment URL with the received token
          // window.location.href = `https://secure.3gdirectpay.com/payv3.php?ID=${data.result.API3G.TransToken._text}`;
          const paymentPlatformUrl = `https://secure.3gdirectpay.com/payv3.php?ID=${data.result.API3G.TransToken._text}`;
          // Add more data as needed

          // Navigate to the payment platform
          window.location.href = paymentPlatformUrl;
          // setIsPaymentBtnClicked(false);
        } else {
          console.error("Missing data for redirect");
          // setIsPaymentBtnClicked(false);
        }
      } else {
        // Payment initiation failed
        console.error("Error initiating payment:", data.message);
        // setIsPaymentBtnClicked(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      // setIsPaymentBtnClicked(false);
    }

    // setIsPaymentBtnClicked(false);
  };

  return (
    <>
      <button className="btn btn-primary" onClick={() => createOrder()}>Buy Now</button>
      <button className="btn btn-primary" onClick={() => handleProceedDPO()}>Buy Now DPO</button>
    </>
  );
};

export default ProductPayNow;