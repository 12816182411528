import React, { useEffect, useState } from "react";
import "./Theme53.css";
import axios from "axios";
import { environmentVariables } from "../../../../config/env.config";
import { OrderHistoryDetailPage } from './OrderHistoryDetailPage';
import { getOrderStatus } from "../../../../utils/utility";
export const OrderHistoryPage = ({ onBack, data }) => {
  const [orderDetails, OrderDetails] = useState("");
  const baseUrl = environmentVariables?.apiUrl;
  const [isRightArrow , setIsRightArrow]=useState(false);
  const[isOrderHistoryDetailPage,setIsOrderHistoryDetailPage]=useState(false)
  const [detail, setDetail] = useState();
  const [activeProduct, setActiveProduct] = useState();
  let currentUsrDatafromstorage = localStorage.getItem("Customer");
  let currentUserData =
    !!currentUsrDatafromstorage && currentUsrDatafromstorage != null
      ? JSON.parse(currentUsrDatafromstorage)
      : "";
  const OrdersHandlers = () => {
    axios.get(`${baseUrl}api/v1/influencer/getCustomerOrder?cusid=${currentUserData?.id}&email=${currentUserData?.email}&business_id=${data?.id}`)
    // axios.get(`${baseUrl}api/v1/influencer/getCustomerOrder?email=gagan@yopmail.com&business_id=2764`)
      .then((res) => {
        OrderDetails(res?.data?.result);
      
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    OrdersHandlers();
  }, []);

  const handleClickOnArrow = (item,productItem) =>{
    setIsRightArrow(!isRightArrow)
    setIsOrderHistoryDetailPage(true)
    setDetail(item)
    setActiveProduct(productItem)
  }
  const handleBackToOrderDetails =()=>{
    console.log("isOrderHistoryDetailPage====>>>>", isOrderHistoryDetailPage);
    
    setIsOrderHistoryDetailPage(!isOrderHistoryDetailPage)    
  }



  return (
    // <div>
    <section className="order-history-page">
      <div className="order-history-page-inner">
        <article>
          <h3 className="sectitle">My Orders</h3>
          <p className="backArrowHistory">
            <span>
              <i onClick={onBack} className="fa-solid fa-arrow-left"></i>
            </span>
          </p>

          <div className="orderhistorylist">
          <ul>
            {orderDetails &&
              orderDetails.map((item, index) => {
                // Get the first product only
                const firstProduct = item?.product_data?.[0];                
                return (
                  firstProduct && (
                    <li key={index}>
                      <div style={{ display: "flex", gap: "10px" }}>
                        {/* Image of the first product */}
                        <div className="orderhistoryimg">
                          <img
                            src={`${baseUrl}uploads/${firstProduct?.productInfo?.image}`}
                            alt={firstProduct?.productInfo?.product_name || "Product Image"}
                          />
                        </div>

                        {/* Order details */}
                        <div>
                          <h2>
                          {item?.order_status === "new" ? "Order Placed" : getOrderStatus(item?.order_status)}{" "}
                            On {" "}
                            {new Intl.DateTimeFormat("en-US", {
                              weekday: "short",
                              month: "short",
                              day: "numeric",
                            }).format(new Date(item?.updated_at))}
                          </h2>
                          <h3>Order ID: {item?.order_id}</h3>
                          <h4>
                            Total Paid: {item?.currency}{item?.total} ( {item?.product_data?.length == 1? '1  item':item?.product_data?.length+' items'} )
                          </h4>
                        </div>
                      </div>

                      {/* View Details Button */}
                      <p
                        onClick={() => handleClickOnArrow(item)}
                        style={{
                          cursor: "pointer",
                          marginTop: "10px",
                          whiteSpace:"nowrap"
                        }}
                      >
                        View Details
                      </p>
                    </li>
                  )
                );
              })}
          </ul>
          </div>
        </article>
      </div>
      {
  isOrderHistoryDetailPage && (
    <OrderHistoryDetailPage
      onBack={handleBackToOrderDetails}
      orderDetails={detail}
      activeProduct={activeProduct}
      style={{
        transition: 'transform 2s ease-out',
        transform: isOrderHistoryDetailPage ? 'translateX(0)' : 'translateX(100%)',
      }}
    ></OrderHistoryDetailPage>
  )
}
    </section>
    // </div>
  );
};

