import React from 'react'
import { AboutParagraph, AboutTabWrapper, AboutText, AboutTextWrapper, OpeningHoursContainer, OrderCardWrapperContainer, OrderCardWrapperContainerABOUT } from './Theme14ExtStyleComponent'

const TabAbout = (props) => {
  return (
    <OrderCardWrapperContainerABOUT>
    <OpeningHoursContainer>
    <AboutTextWrapper>
      {/* <AboutText>About</AboutText> */}
      <AboutParagraph dangerouslySetInnerHTML={{ 
        __html: props?.rs?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>') 
      }} >
      </AboutParagraph>
    </AboutTextWrapper>
  
    </OpeningHoursContainer>
     </OrderCardWrapperContainerABOUT>
  )
}

export default TabAbout