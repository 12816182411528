import React, { useState,useEffect } from 'react';
import AllCollection from './AllCollection';
import ExtAllCollection from './ExtAllCollection';
import "./Theme20.css";
import { getServicesArray } from '../../../../utils/utility';

const ExtHomeComponent = ({currencySymbol,prodtdata,sendDatafromParent,prc, currentPage, totalPages, onNextPage, onPrevPage, sendDatafromhomecomponet}) => {
    const rs = prodtdata;
  const [activeTabb, setActiveTabb] = useState("All");

    const minimarketArray1 = prodtdata?.minimktproductArr || []
    const minimarketArray = prc?.data?.data || []
    
    useEffect(() => {
        sendDatafromhomecomponet(activeTabb);
    }, [activeTabb]);

    const prodcutenquiry = (formData) => {
        // butonsubmit(formData)
      };


    const renderContent = () => {
        let filteredProducts;

        if (activeTabb === "All") {
            filteredProducts = minimarketArray;
        } else {
            filteredProducts = minimarketArray.filter(
                product => product.product_service == activeTabb
            );
        }
        
        return <ExtAllCollection currencySymbol={currencySymbol} products={filteredProducts} rs={rs} butonsubmit={prodcutenquiry} sendDatafromParent={sendDatafromParent} currentPage={currentPage}
        totalPages={totalPages}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}/>;
    };

    const getButtonClass = (tabName) => {
        return tabName === activeTabb
            ? 'bg-coffee border-none px-4 py-1 text-base rounded-full text-white'
            : 'px-4 py-1 border border-darkUnactive rounded-full text-base  text-b45';
    };

    return (
        prodtdata?.is_mini_mktproduct == 1 ? (
        <div>
            <div className='flex overflow-x-auto'>
                <div>
                    <button
                        className={getButtonClass("All")}
                        onClick={() => setActiveTabb("All")}
                        style={{ margin: '0 10px' }}
                    >
                        All
                    </button>
                </div>
                {  rs?.serviceObj?.is_enabled === 1 && getServicesArray(rs?.serviceObj?.content).map((service, index) => (
                    <div key={index}>
                        <button
                            className={getButtonClass(service.id)}
                            onClick={() => setActiveTabb(service.id)}
                            style={{ margin: '0 10px', width:"max-content" }}
                        >
                            {service.name}
                        </button>
                    </div>
                ))}
                
              
            </div>
            <div className="px-2 py-2 pb-5">{renderContent()}</div>

            {/* <div className=' bg-white py-4 fixed bottom-0 spacing w-[460px] text-center border border-white ContactSupplier'>
                  <button className='bg-coffee text-xl rounded text-white w-full font-normal font-Tajawal pt-3 pb-2 p-2"'
                  > 
                    Go to Cart  <span className='font-Tajawal text-sm'>(1 items)</span>
                  </button>
        </div> */}

     
        </div>
         ) : null
    );
};

export default ExtHomeComponent;
