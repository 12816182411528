import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Contact Info</h3>
        <ul class="conlink">
          <li>
          <div className="" style={{display:"flex"}}>
              <i class="fa-solid fa-phone"></i>
              <div style={{display:"flex", flexDirection:"column"}}>
                <a href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}>
                  <span>{ff?.contactInfoPhone}</span>
                </a>
                <a
                  href={ ff?.optionalContactInfoPhone ? `tel:${ff?.optionalContactInfoPhone}` : ""  } style={{paddingBottom:"20px", paddingTop:"8px"}}>
                  <span> {ff?.optionalContactInfoPhone || ``} </span>
                </a>
              </div>
            </div>
          </li>

          <li>
            <a
              href={
                ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
              }
            >
              <i class="fa-solid fa-envelope"></i>
              <span className="mailurllong">{ff?.contactInfoEmail}</span>
            </a>
          </li>
              {ff?.contactInfoWebUrl && 

              <li>
              <a
                href={
                  ff?.contactInfoWebUrl ? `mailto:${ff?.contactInfoWebUrl}` : ""
                }
              >
                <i class="fa-solid fa-link"></i>
                <span>{ff?.contactInfoWebUrl}</span>
              </a>
              </li>
              }
         
          {/* <li>
                      <a href="">
                        <span>
                         
                          {ff?.contactInfoWebUrl 
                            "Website Url"}
                        </span>
                      </a>
                    </li> */}
          <li>
            <a>
              <i class="fa-solid fa-map"></i>
              <div>
              <span>
                {ff?.contactInfoAddress?.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
                  ))}
                  </span>
                  {ff?.contactInfoOptAddress && (
                <div style={{ paddingTop: "5px" }}>
                  <p style={{ color: "#919191", fontSize: "14px", margin:"0" }}>Alternative Address</p>
                  <span>
                    {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              )}

              </div>
            </a>
          </li>
         
        </ul>
      </article>
    </>
  );
}
export default SubContactInfo;
