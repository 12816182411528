import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../config/env.config";
import { userContext } from "../context/userContext";
import Cookies from 'js-cookie';
import { useFormik } from "formik";
import * as Yup from "yup";

function CustomerProfile() {
  const { userData, setUserData } = useContext(userContext);
  const location = new useLocation();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(null);
  const [otp, setOtp] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  // frontend validation
  const validateSchema = Yup.object().shape({
    otp: Yup.string()
      .required("Please enter OTP.")
      .min(6, "OTP must have at least 6 characters")
      .matches(/[0-9]/, `Your password must have at digits`)
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      setIsSubmit(true);
      verifyLogin(values);
    },
  });

  const verifyLogin = (dataValue) => {
    let getcUsrEmail = localStorage.getItem("getcUsrEmail");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/verifyOtp`,
      data:{
        otp:dataValue?.otp,
        email:getcUsrEmail,
      }
    };
    axios
      .request(config)
      .then((res) => {
        setIsSubmit(false);
        toast.success("Your email has been verified successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setTimeout(() => {
          navigate("/customer_login");
        }, 3000);
      })
      .catch((error) => {
        setIsSubmit(false);
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  }

  const handleOtp = (e) => {
    setOtp(e.target.value);
  }

  return (
    <section className="py-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-4">
            <div className="loginform">
              <h2>Customer Profile</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomerProfile;
