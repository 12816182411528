import React from "react";
import { environmentVariables } from "../../../../config/env.config";
import theme6strip from "../../../../Images/theme6strip.png";
import { getServicesArray } from "../../../../utils/utility";

function SubService(props) {
  let rs = props.rs;

  // console.log(props, "rssubservice");
  return (
    <>
      <article className="servicesName sec">
        <div className="services">
          <h3 class="sectitle service">Services </h3>
          <div className="mainservicebox" style={{height:"260px", overflow:"scroll", marginLeft:"0", marginRight:"0"}}>
            {/* <ul className="servesbox">
            {JSON.parse(rs?.serviceObj?.content).map((val) => (
              <li className="service-list">{val?.name}</li>
            ))}{" "}
          </ul> */}
            {rs?.serviceObj?.content && (
              <ul className="servesbox" >
                    <h3 style={{fontFamily:"jost", color:"white", fontSize:"20px", fontWeight:"600", textAlign:"center", marginBottom:"10px"}}>MEN</h3>
                {getServicesArray(rs?.serviceObj?.content).map((val, index) => (
                  val?.name && (val?.description === "male" || val?.description === "all" || val?.description === "undefined") &&  (
                    <li key={index} className="service-list">
                      {val.name}
                    </li>
                  )
                ))}
              </ul>
            )}
               {rs?.serviceObj?.content && (
              <ul className="servesbox" >
                    <h3 style={{fontFamily:"jost", color:"white", fontSize:"20px", fontWeight:"600", textAlign:"center", marginBottom:"10px"}}>WOMEN</h3>
                {getServicesArray(rs?.serviceObj?.content).map((val, index) => (
                  val?.name && (val?.description === "female" || val?.description === "all" || val?.description === "undefined") &&  (
                    <li key={index} className="service-list">
                      {val.name}
                    </li>
                  )
                ))}
              </ul>
            )}
          </div>
        </div>
      </article>
      {/* <img src={theme6strip} className="stripservice" /> */}
    </>
  );
}
export default SubService;
