import React, { useContext, useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import Swal from "sweetalert2";
import avatar from "../../Images/blackpic2.jpg";

function CreatePayLink() {
  const [data, setData] = useState([]);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const [isSubmit, setIsSubmit] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [isCoupon, setIsCoupon] = useState(false);
  const [couponName, setCouponName] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [couponTypes, setCouponTypes] = useState("");
  const [position, setposition] = useState("");
  const [link, setlink] = useState("");
  const [activate, setActivate] = useState(false);
  const createCouponModalRef = useRef(null);
  const tableExtensions = { export: false, print: false };
  const [isUpdate, setIsUpdate] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const fileInputRefLogo = useRef(null);
  const [logoData, setLogoData] = useState("");
  const [mktCategory, setMktCategory] = useState([]);

  const handleClearState = () => {
    try {
      // e.preventDefault();
      setCouponName("");
      setCouponTypes("");
      setposition("");
      setlink("");
      setActivate(false);
      setIsUpdate(false);
      setCouponId("");
      setLogoData("");
      setLogoImageUrl("");
      setIsCoupon(false);
      setSelectedFile(null);
      if (fileInputRefLogo.current) {
        fileInputRefLogo.current.value = "";
      }
    } catch (err) {
      console.log(err, "erororo");
    }
  };
  const handleImageClickLogo = () => {
    fileInputRefLogo.current.click();
  };
  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (file && acceptedImageTypes.includes(file.type)) {
      setLogoData(file);
      const reader = new FileReader();
      reader.onload = () => {
        setLogoImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Please select a valid image file (JPEG, PNG, JPG).", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const handleEdit = (el) => {
    try {
      setIsUpdate(true);
      setCouponId(el?.id);
      setCouponName(el?.name);
      setCouponTypes(el?.types);
      setposition(el?.position);
      setlink(el?.link);
      setSelectedFile(el?.file_upload);
      setActivate(el?.is_active == 0 ? false : true);
      setIsCoupon(true);
      setIsStatus(false);
      $(createCouponModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "Errorro");
    }
  };

  const handleDelete = async (d) => {
    try {
      // Display confirmation dialog using Swal.fire
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      // Check if the user confirmed the deletion
      if (result.isConfirmed) {
        // Proceed with the deletion API call
        let { data: res } = await axios.delete(
          `${baseUrl}api/v1/menubanner/deletemenubanner/${d?.id}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );

        // Check the response from the API call
        if (res && res?.success) {
          fetchCoupon();
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Types",
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Link",
      selector: (row) => row.link,
      sortable: true,
    },
    // {
    //   name: "USED",
    //   selector: (row) => row.used,
    //   sortable: true,
    // },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            // onClick={() => handleDelete(d)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}
          <i
            key={`edit-${d.id}`}
            className="fas fa-edit"
            onClick={() => {
              handleEdit(d);
              setLogoImageUrl(d?.file_upload);
            }}
            style={{ cursor: "pointer", fontSize: "22px", fontWeight: "100" }}
          ></i>
        </>,
      ],
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize: "15px",
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };

  const tableData = {
    columns,
    data,
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let data = JSON.stringify({
  //       email: email,
  //       amount: amount,
  //       currency: currency,
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `${baseUrl}api/v1/payment/createDynamicPaymentLink`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         console.log("response===>>>", response.data);
  //         if (!!response.data) {
  //           setPaymentLink(response.data.paylink);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (err) {
  //     const errorMessage = err?.response?.data?.message || "An error occurred";
  //     toast.error(errorMessage, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //   }
  // };

  const initialValues = {
    email: "",
    amount: "",
    currency: "",
  };

  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),

      currency: Yup.string().required("Please select Currency"),

      amount: Yup.string().required("Amount is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmit(true);

      let data = JSON.stringify({
        email: values?.email,
        amount: values?.amount,
        currency: values?.currency,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/payment/createDynamicPaymentLink`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setIsSubmit(false);
          toast.success(response?.data?.message);

          console.log("response===>>>", response.data);
          if (!!response.data) {
            setPaymentLink(response.data.paylink);
          }
        })
        .catch((error) => {
          setIsSubmit(false);
          toast.error(error?.response?.data?.message || error?.message);

          console.log(error);
        });
    },
  });

  const { values, errors, handleSubmit } = formik;

  const handleAmountChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^0-9]/g, "");
    formik.setFieldValue("amount", numericValue);
  };

  const handleClickCopy = (data) => {
    const resumePreviewLink = data;

    switch ("copy_to_clipboard") {
      case "copy_to_clipboard":
        navigator.clipboard
          .writeText(resumePreviewLink)
          .then(() => {
            console.log("Link copied to clipboard!", resumePreviewLink);

            setTimeout(() => {
              toast.success("Copied");
            }, 2000);
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <PageName PageName="Manage banner" />
      <h1 style={{ marginBottom: "50px" }}>Create Payment Link</h1>
      <article>
        <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
          <div className="modal-body" style={{ padding: "0" }}>
            <div className="mb-2" id="Coupons_admin_createform_container">
              {/* Name */}
              <input
                placeholder="Email"
                type="email"
                className="form-control"
                value={formik.values.email}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div style={{ color: "red" }}>{formik.errors.email}</div>
              ) : null}
              <input
                type="text"
                className="form-control"
                placeholder="Amount"
                onChange={handleAmountChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                name="amount"
                onWheel={(e) => e.target.blur()}
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div style={{ color: "red" }}>{formik.errors.amount}</div>
              ) : null}

              <select
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currency}
                name="currency"
              >
                <option value="">Select Currency*</option>
                <option value="AED">AED</option>
                <option value="USD">USD</option>
              </select>
              {formik.touched.currency && formik.errors.currency ? (
                <div style={{ color: "red" }}>{formik.errors.currency}</div>
              ) : null}
            </div>

            {/* Activate */}
          </div>

          {/* Submit button */}
          <div className="modal-footer" id="Coupanadmin_Button_Wrapper">
            <button
              id="Coupan_edit_Button"
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleSubmit(e)}
            >
              {isSubmit ? <CircularLoader /> : "Submit"}
            </button>
          </div>
        </form>

        {/* nichec ka modal */}
        {paymentLink ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="createpaylinkcon">
              <p>{paymentLink}</p>
              <p
                onClick={() => handleClickCopy(paymentLink)}
                style={{ cursor: "pointer" }}
              >
                copy payment link <i class="fa-solid fa-copy"></i>
              </p>
            </div>
            <p>Payment link will expire in 5 hours.</p>
          </div>
        ) : (
          ""
        )}
      </article>
    </>
  );
}

export default CreatePayLink;
