import React, { useState } from "react";
import Drawer from "react-bottom-drawer";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "../../../../utils/utility";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import Captcha from "../../../../utils/Captcha";

export default function BottomDrawer({ closeDrawer, onSubmit, selectedItem }) {
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [invalidCaptchaErr, setInvalidCaptchaErr] = useState("");
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(10, "Minimum 10 digits are required")
      .required('Phone number is required'),
      description: Yup.string()
      .matches(/^[a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r]*$/, "Description contains invalid characters.")    
  });
  const Errormsg = styled.div`
    color: red;
    margin: 0 10px;
    font-size: 12px;
`;
  const formik = useFormik({
    initialValues: {
      phone: '',
      description: ''
    },
    validationSchema: validationSchema,
    validateOnChange: true, // Apply validation on each change
    onSubmit: (values) => {
      if (invalidPhoneErr) {
        // Prevent form submission if there's an invalid phone error
        return;
      }
      let cloneSubmittedValue = JSON.parse(JSON.stringify(values));
      let seperateCountryCodeFromPhone = cloneSubmittedValue?.phone?.split("-");
      if (isCaptchaSolved) {
        if (seperateCountryCodeFromPhone.length > 0) {
          cloneSubmittedValue.phone = seperateCountryCodeFromPhone[1];
          cloneSubmittedValue.countryCode = seperateCountryCodeFromPhone[0];
          cloneSubmittedValue.item = selectedItem;
          cloneSubmittedValue.captchaAns = isCaptchaSolved;
          onSubmit(cloneSubmittedValue);
          closeDrawer();
        } 
      } else {
        setInvalidCaptchaErr("Please resolve captcha.");
      }
      
    },
  });

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.phone;
    let val = contactNumber?.split("-");
    let valArray = (val?.length > 0) ? val[1] : "";
    if(valArray==''){
      setInvalidPhoneErr(`Please enter phone number.`);
    }else  if (!isValidPhoneNumber(valArray, phoneData)) {
      setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
    } else {
      setInvalidPhoneErr('');
    }
  };
  const handleCaptchaSolved = (solved) => {
    setIsCaptchaSolved(solved);
    setInvalidCaptchaErr("");
  };
  return (

    <div className="" >
      <div className="pt-4 pl-4 pr-4" style={{paddingBottom:"90px"}}>
        <div className="flex justify-center items-center pb-2">
          <div className="font-Tajawal text-101010 font-semibold text-2xl">Enter Mobile Number</div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-center items-center w-hundred" >
            <PhoneInput
              country={"in"}
              enableSearch={true}
              countryCodeEditable={false}
              value={formik.values.phone}
              onChange={(value, country) => {
                if (country.dialCode) {
                  formik.setFieldValue(
                    "phone",
                    `+${country.dialCode}-${value.substring(
                      country.dialCode.length
                    )}`
                  );
                  setInvalidPhoneErr('');
                } else {
                  formik.setFieldValue(
                    "phone",
                    value
                  );
                  setInvalidPhoneErr('');
                }
              }}

              className="customer-info-phone"
              onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("phone")}
              isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
            />
          </div>
          <p style={{ fontSize: "12px", paddingTop: "3px", paddingLeft: "5px" }}>
            Enter whatsapp no. only
          </p>
          <Errormsg>   {formik.errors.phone && formik.touched.phone && (
            <div>{formik.errors.phone}</div>
          )}</Errormsg>
          {invalidPhoneErr && <Errormsg>
            {invalidPhoneErr}
          </Errormsg>}

          <div className="mt-4">
            <textarea style={{ border: "1px solid #d3d3d3", padding:"5px 10px", borderRadius:"8px", outline:"none" }}
              id="description"
              name="description"
              placeholder="Enter your enquiry details(optional)"
              className=" mt-2 w-100"
              rows="3"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

            />
          </div>
          <Errormsg>   {formik.errors.description && formik.touched.description && (
            <div>{formik.errors.description}</div>
          )}</Errormsg>
          <div className="captcha-container">
            <Captcha onCaptchaSolved={handleCaptchaSolved} />
          </div>
          {!!invalidCaptchaErr && <p className="text-danger" style={{fontSize:"14px", marginTop:"-10px"}}>{invalidCaptchaErr}</p>}
          <div className="mt-4">
            <button type="submit"
              className="bg-coffee text-xl rounded text-white w-hundred font-bold font-Tajawal" style={{padding:"18px"}}
            >
              Submit
            </button>
          </div>
          <button className="bg-blue-500 text-white p-2 rounded" onClick={closeDrawer}>Close</button>
        </form>
      </div>
    </div>

  );
}
