import parsePhoneNumberFromString from "libphonenumber-js";
import * as Yup from "yup";

const isValidPhoneNumber = (value, country) => {
    try {
      const phoneNumber = parsePhoneNumberFromString(value, country?.toUpperCase());
      console.log(phoneNumber,"phoneNumber",value, country)
      return phoneNumber?.isValid() || false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

export const contactFormSchema = Yup.object({
  name: Yup.string()
  .matches(
    /^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])?\s*$/,
    "Only alphabets are allowed"
  ).required("Name is required")
  .min(2, "Minimum 2 characters required")
  .max(25, "Maximum 25 characters are allowed in name."),
  contactNumber: Yup.string()
    .required("Please enter your phone number.")
    .test(
      "isValidPhoneNumber",
      "Invalid phone number for the selected country.",
      (value, context) =>
        isValidPhoneNumber(value, context?.parent?.countryCode || "IN")
    ),
  textareaValue: Yup.string().required("message is required").min(3, "Message must be at least 3 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});