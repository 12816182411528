import React, { useRef } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/banner1.jpg";
import logoImage from "../../../../Images/logo6.png";
import testimonialImage from "../../../../Images/avatar.png";

import theme31strip from "../../../../Images/theme31strip.png";
import CalanderIconn from "../../../../Images/DarkcalendarIcondays.png";

import "./Theme31.css";
import TestimonialCarousel from "./TestimonialCarousel";
import { useState } from "react";
import GalleryCarousel from "./GalleryCarousel";
const Theme31Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 46,
      business_id: 10,
      image: "1701327138414Rectangle34.jpg",
      created_by: 72,
      created_at: "2023-11-30T06:52:18.000Z",
      updated_at: "2023-11-30T06:52:18.000Z",
    },
    {
      id: 47,
      business_id: 10,
      image: "1701327142539Rectangle36.jpg",
      created_by: 72,
      created_at: "2023-11-30T06:52:22.000Z",
      updated_at: "2023-11-30T06:52:22.000Z",
    },
    {
      id: 48,
      business_id: 10,
      image: "1701327146720Rectangle38.jpg",
      created_by: 72,
      created_at: "2023-11-30T06:52:26.000Z",
      updated_at: "2023-11-30T06:52:26.000Z",
    },
    {
      id: 49,
      business_id: 10,
      image: "1701327151333Rectangle39.jpg",
      created_by: 72,
      created_at: "2023-11-30T06:52:31.000Z",
      updated_at: "2023-11-30T06:52:31.000Z",
    },
  ];

  const openCalendar = () => {
   
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
     
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp31">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
              <article class="personalinfo sec">
                <img
                  src={logoImage}
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>
              <img src={theme31strip} className="strip" />
              <div class="innerinfo">
                <h3 className="username">Vasken Mouradian</h3>
                <p className="titleuser">Constructor</p>
              </div>

              <article className="abouttemplate sec">
                <h3 class="sectitle about">About me</h3>
                <p className="sectitle desc">
                  {`Building dreams, one brick at a time! I'm a passionate constructor turning blueprints into reality. From skyscrapers to cozy homes, I create spaces that inspire and bring joy. Let's build something amazing together!`}
                </p>
              </article>

              <article class="contact sec">
                <h3 class="sectitle contactinfo">Contact Info</h3>

                <div class="conlink">
                  <div className="contact-us-grid">
                    <div className="contact-info-container">
                      <div className="contact-info-details">
                        <div className="contact-info-icon">
                          <i className="fa fa-solid fa-phone-volume"></i>
                        </div>
                        <p className="contact-info-label">Phone</p>
                        <a className="contactinfolink" href="">
                          <span className="contactinfotext">{`+91-987654321`}</span>
                        </a>
                      </div>
                    </div>
                    <div className="vertical-line"></div>

                    <div className="contact-info-container">
                      <div className="contact-info-details">
                        <div className="contact-info-icon">
                          <i className="fa fa-solid fa-envelope"></i>
                        </div>
                        <p className="contact-info-label">Email</p>
                        <a href="">
                          <span className="contactinfotext">
                            johndoe@gmail.com
                          </span>
                        </a>
                      </div>
                    </div>

                    <div className="vertical-line"></div>
                    <div className="contact-info-container">
                      <div className="contact-info-details">
                        <div className="contact-info-icon">
                          <i className="fa fa-solid fa-location-dot"></i>
                        </div>
                        <p className="contact-info-label">Address</p>
                        <a href="">
                          <span className="contactinfotext">
                            UAE- Dubai - Al meydan road - KML building - office
                            121
                          </span>
                        </a>
                      </div>
                    </div>

                    <div className="vertical-line"></div>
                    <div className="contact-info-container">
                      <div className="contact-info-details">
                        <div className="contact-info-icon">
                          <i className="fa-solid fa-link"></i>
                        </div>
                        <p className="contact-info-label">web Url</p>
                        <a href="">
                          <span className="contactinfotext">
                           Businessbay.io
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article className="servicesName sec">
                <h3 class="sectitle service">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li className="service-list">Smart Contract Development</li>
                    <li className="service-list">Blockchain Integration</li>
                  </ul>
                </div>
              </article>
              <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonials </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article>
              <article className="conatctform sec">
                <h3 class="sectitle contact">Contact</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="button" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
              <article className="businesshour sec">
                <h3 class="sectitle business">Working Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday</div>
                    <div className="colon">:</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday</div>
                    <div className="colon">:</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday</div>
                    <div className="colon">:</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday</div>
                    <div className="colon">:</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday</div>
                    <div className="colon">:</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday</div>
                    <div className="colon">:</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article>
              <article className="makeappitmneets sec">
                <h3 class="sectitle makeappoint">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{position:"relative"}}>
                    <input
                      type="date"
                      ref={calendarInputRef}
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                 <div className="calanderIcon" onClick={openCalendar}><img src={CalanderIconn} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article>
              <article class="gallery sec sec-common">
                <h3 class="sectitle">Gallery </h3>
                <GalleryCarousel slides={galleryArray} />
              </article>
              <article className="social-links-sec sec">
                <h3 class="sectitle social">Social Links</h3>
                <ul class="socil-link">
                  <li>
                    <a>
                      <i class={`fa-brands fa-facebook`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-google`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-twitter`}></i>
                    </a>
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme31Template;
