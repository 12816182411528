import React, { useRef, useState } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/theme-42-banner.jpg";
import logoImage from "../../../../Images/theme-42-logo.jpg";
import testimonialImage from "../../../../Images/avatar.png";
import "./Theme42.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
import CalanderIconn from "../../../../Images/DarkcalendarIcondays.png";
const Theme42Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:"Absolutely loved working with our designer! They transformed our space beautifully and listened to our needs!",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "Professional and creative! They exceeded our expectations and made the process enjoyable!"

,
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "Our home feels like a dream! Their attention to detail and style truly made a difference!",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 60,
      business_id: 10,
      image: "1701329881279Rectangle6.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:01.000Z",
      updated_at: "2023-11-30T07:38:01.000Z",
    },
    {
      id: 61,
      business_id: 10,
      image: "1701329883848Rectangle7.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:03.000Z",
      updated_at: "2023-11-30T07:38:03.000Z",
    },
    {
      id: 62,
      business_id: 10,
      image: "1701329887518Rectangle597.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:07.000Z",
      updated_at: "2023-11-30T07:38:07.000Z",
    },
    {
      id: 63,
      business_id: 10,
      image: "1701329891477Rectangle598.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:11.000Z",
      updated_at: "2023-11-30T07:38:11.000Z",
    },
    {
      id: 64,
      business_id: 10,
      image: "1701329899378Rectangle599.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:19.000Z",
      updated_at: "2023-11-30T07:38:19.000Z",
    },
    {
      id: 65,
      business_id: 10,
      image: "1701329902425Rectangle600.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:22.000Z",
      updated_at: "2023-11-30T07:38:22.000Z",
    },
  ];

  const openCalendar = () => {
   
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
     
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp42">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
              <img
                src={logoImage}
                className="mx-auto d-block img-fluid logo-profile"
              />
              <article className="imgsechere sec">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                />
              </article>
              <article className="personalinfo sec">
                <h3 className="username">Isabella</h3>
                <p className="titleuser">Interior Designer</p>
              </article>
              <article className="abouttemplate sec">
                <h3 class="sectitle">About me</h3>
                <p className="para">
                  {` Interior Designing emerges as a beacon of creativity and innovation. Bani Interior Designing is not just a testament to passion and expertise but also leading a continuous path to excellence in the realm of interior design.
 `}
                </p>
              </article>
              <article class="gallery sec sec-common">
                <h3 class="sectitle">Gallery </h3>
                <GalleryCarousel slides={galleryArray} />
              </article>
              <article className="social-links-sec sec">
                <h3 class="sectitle">Social Links</h3>
                <ul class="socil-link socil-link-inner">
                  <li>
                    <a>
                      <i class={`fa-brands fa-facebook`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-google`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-twitter`}></i>
                    </a>
                  </li>
                </ul>
              </article>
              <article className="businessHours sec">
                <h3 class="sectitle">Business Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article>
              <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{position:"relative"}}>
                    <input
                      type="date"
                      ref={calendarInputRef}
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                        <div className="calanderIcon" onClick={openCalendar}><img src={CalanderIconn} alt="Calendar Icon" /></div>
             
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article>

              <article className="conatctform sec">
                <h3 class="sectitle">Contact </h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
              <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Space Planning</li>
                    <li>Concept Development</li>
                    <li>Lighting Design</li>
                  </ul>
                </div>
              </article>
              <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article>
              <article class="contact sec">
                <h3 class="sectitle">Contact Info</h3>
                <ul class="conlink">
                  <li>
                    <a href="">
                      <i class="fa-solid fa-phone"></i>
                      <span>{`987654321`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-envelope"></i>
                      <span>{` isabella@gmail.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-location-dot"></i>
                      <span>{`B-2, Block B, Sector 4, Noida,`}</span>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i class="fa-solid fa-link"></i>
                      <span>{`www.Businessbay.io`}</span>
                    </a>
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme42Template;
