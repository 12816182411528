import React, { useRef, useState } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/theme-16-banner.jpg";
import logoImage from "../../../../Images/theme_16_logo.png";
import testimonialImage from "../../../../Images/avatar.png";
import FacebookIcon from "../../../../Images/theme16-social1.jpg";
import InstagramIcon from "../../../../Images/theme16-social2.jpg";
import TweeterIcon from "../../../../Images/theme16-social3.jpg";
import LinedinIcon from "../../../../Images/theme16-social4.png";
import WhatsappIcon from "../../../../Images/theme16-social5.png";
import CalanderIconn from "../../../../Images/calendarIcondays.png";

import "./Theme16.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";

import g1 from "../../../../Images/theme16-gallery1.jpg";
import g2 from "../../../../Images/theme16-gallery2.jpg";
import g3 from "../../../../Images/theme16-gallery3.jpg";
import g4 from "../../../../Images/theme16-gallery4.png";
import g5 from "../../../../Images/theme16-gallery5.jpg";
import g6 from "../../../../Images/theme16-gallery6.jpg";

const Theme16Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 60,
      business_id: 10,
      image: "1701329881279Rectangle6.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:01.000Z",
      updated_at: "2023-11-30T07:38:01.000Z",
    },
    {
      id: 61,
      business_id: 10,
      image: "1701329883848Rectangle7.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:03.000Z",
      updated_at: "2023-11-30T07:38:03.000Z",
    },
    {
      id: 62,
      business_id: 10,
      image: "1701329887518Rectangle597.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:07.000Z",
      updated_at: "2023-11-30T07:38:07.000Z",
    },
    {
      id: 63,
      business_id: 10,
      image: "1701329891477Rectangle598.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:11.000Z",
      updated_at: "2023-11-30T07:38:11.000Z",
    },
    {
      id: 64,
      business_id: 10,
      image: "1701329899378Rectangle599.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:19.000Z",
      updated_at: "2023-11-30T07:38:19.000Z",
    },
    {
      id: 65,
      business_id: 10,
      image: "1701329902425Rectangle600.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:22.000Z",
      updated_at: "2023-11-30T07:38:22.000Z",
    },
  ];

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp16">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
            <div className="logoTextContainer">
                  <img src={logoImage} className="logo-profile" />
                </div>
              <article className="imgsechere sec">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                  style={{ width: "100%" }}
                />
                
              </article>

              <article className="personalinfo">
                <h3 className="username">Anderson Cooper</h3>
                <p className="titleuser">journalist</p>
              </article>

              {/* <article className="makeappitmneets sec">
                 <h3 class="sectitle">Make Appointment</h3> 
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{ position: "relative" }}>
                    <input
                      ref={calendarInputRef}
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                    // value={selectedDate}
                    // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                    <div className="calanderIcon" onClick={openCalendar}>
                      <img src={CalanderIconn} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM to 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}

              <article className="abouttemplate sec">
                <h3 class="sectitle">About US</h3>
                <p className="para">
                  {` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`}
                </p>
              </article>
              <article class="gallery sec sec-common">
                <h3 class="sectitle">
                  Gallery
                  {/* <span> View all</span> */}
                </h3>
                <div className="newgallery">
                  <ul>
                    <li>
                      {" "}
                      <img src={g1} alt="gallery1" />{" "}
                    </li>
                    <li>
                      {" "}
                      <img src={g2} alt="gallery2" />{" "}
                    </li>
                    <li>
                      {" "}
                      <img src={g3} alt="gallery3" />{" "}
                    </li>
                    <li>
                      {" "}
                      <img src={g4} alt="gallery4" />{" "}
                    </li>
                    <li>
                      {" "}
                      <img src={g5} alt="gallery5" />{" "}
                    </li>
                    <li>
                      {" "}
                      <img src={g6} alt="gallery6" />{" "}
                    </li>
                  </ul>
                </div>
                {/* <GalleryCarousel slides={galleryArray} />*/}
              </article>
              <article className="social-links-sec sec">
                {/*<h3 class="sectitle">Social Media</h3>*/}
                <ul class="socil-link socil-link-inner">
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-facebook`}></i> */}
                      <img src={FacebookIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-google`}></i> */}
                      <img src={InstagramIcon} />
                    </a>
                  </li>

                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-twitter`}></i> */}
                      <img src={TweeterIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-twitter`}></i> */}
                      <img src={WhatsappIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-twitter`}></i> */}
                      <img src={LinedinIcon} />
                    </a>
                  </li>
                </ul>
              </article>
              <article className="businessHours sec">
                <h3 class="sectitle">Working Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article>
              <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3">
                    <input
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article>

              {/* <article className="conatctform sec">
                <h3 class="sectitle">Contact Form</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article> */}
              {}
              <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li> PressConnect </li>
                    <li>MediaHub</li>
                    <li>JournalismPro </li>
                    <li>Headline Heroes </li>
                  </ul>
                </div>
              </article>
              {/* <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article> */}
              <article class="contact sec">
                <h3 class="sectitle">get in touch</h3>
                <ul class="conlink">
                  <li>
                    <a href="">
                      {/*<i class="fa-solid fa-phone"></i>*/}
                      <span>Phone:</span>
                      {`987654321`}
                    </a>
                  </li>

                  <li>
                    <a href="">
                      {/*<i class="fa-solid fa-envelope"></i>*/}
                      <span>Email:</span>
                      {` christian@gmail.com`}
                    </a>
                  </li>

                  <li>
                    <a href="">
                      {/*<i class="fa-solid fa-map"></i>*/}
                      <span>Address:</span>
                      {`UAE- Dubai - Al Meydan Road`}
                    </a>
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme16Template;
