import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle contactinfo">Contact Info</h3>

        <div class="conlink">
          <div className="contact-us-grid">
            {ff?.contactInfoPhone && 
            <div className="contact-info-container">
              <div className="contact-info-details">
                <div className="contact-info-icon">
                  <i className="fa fa-solid fa-phone-volume"></i>
                </div>
                {/* <p className="contact-info-label">Phone</p> */}
                <a
                  className="contactinfolink"
                  href={
                    ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""
                  }
                >
                  <span className="contactinfotext">
                    {ff?.contactInfoPhone || ``}
                  </span>
                </a>
                {ff.optionalContactInfoPhone &&
                  <a className="contactinfolink"
                    href={ff?.optionalContactInfoPhone ? `tel:${ff?.optionalContactInfoPhone}` : ""}
                  >
                    <span className="contactinfotext">
                      {ff?.optionalContactInfoPhone || ``}
                    </span>
                  </a>
                }
              </div>
            </div>
           }
            <div className="vertical-line"></div>
            {ff?.contactInfoEmail && 
             <div className="contact-info-container">
              <div className="contact-info-details">
                <div className="contact-info-icon">
                  <i className="fa fa-solid fa-envelope"></i>
                </div>
                {/* <p className="contact-info-label">Email</p> */}
                <a
                  href={
                    ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
                  }
                >
                  <span className="contactinfotext mailurllong">
                    {ff?.contactInfoEmail}
                  </span>
                </a>
              </div>
            </div>
            }
          </div>
          <div className="vertical-line"></div>
          {ff?.contactInfoAddress &&
          <div className="contact-info-container">
            <div className="contact-info-details">
              <div className="contact-info-icon">
                <i className="fa fa-solid fa-location-dot"></i>
              </div>
              {/* <p className="contact-info-label">Address</p> */}
              <a>
                <span className="contactinfotext" style={{paddingBottom:"10px"}}>
                  {ff?.contactInfoAddress?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              </a>
              {ff?.contactInfoOptAddress &&
              <a>
                <span className="contactinfotext">
                  <p style={{color:"#fff", fontSize:"14px"}}>Alternative Address</p>
                  {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              </a>
              }
            </div>
            </div>
          }
            <div className="vertical-line"></div>
            {ff?.contactInfoWebUrl && 
              <div className="contact-info-container">
              <div className="contact-info-details">
                <div className="contact-info-icon">
                  <i className="fa-solid fa-link"></i>
                </div>
               
                <a
                href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
                onClick={(e) => {
                  if (!ff?.contactInfoWebUrl) {
                    e.preventDefault(); // Prevents the default link behavior
                  }
                }}
                target={ff?.contactInfoWebUrl ? '_blank' : undefined}
                className="contactinfolink content"
              >  <span className="contactinfotext mailurllong">
                  {ff?.contactInfoWebUrl}
                </span>
              </a>
              </div>
              {/* <p className="contact-info-label">Address</p> */}
              {/* <a href={(ff?.contactInfoWebUrl)?`http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}`:''} target="_blank"> */}
             
            </div>
            }
          {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope"></i>
                  <span>
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
        </div>
      </article>
    </>
  );
}
export default SubContactInfo;
